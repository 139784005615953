import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  TableColumnResizing
} from "@devexpress/dx-react-grid";
import GridSearchBox from "Components/GridSearchBox";
import TableComponent, { PagingContainer } from "Components/TableComponent";
import { removeLastColumn } from "../../Commons";

// current bookings listing section in current bookings screen and user details screen
// listing is done using @devexpress/dx-react-grid library
class CurrentBookings extends Component {
  state = {
    customColumnWidth: [
      { columnName: "index", width: 90 },
      { columnName: "bookingId", width: "auto" },
      { columnName: "booking_date", width: "auto" },
      { columnName: "pickup_date", width: "auto" },
      { columnName: "pickup_time", width: 150 },
      { columnName: "startPoint", width: "auto" },
      { columnName: "dropPoint", width: "auto" },
      { columnName: "attended_on", width: "auto" },
      { columnName: "status", width: "auto" },
    ]
  };

  // to enable table row click action
  TableRowClickable = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        window.location.assign(`/dashboard/booking/${tableRow.row.id}`)
      }}
      className={"clickable"}
    />
  );

  componentDidMount() {
    // remove last column of the table which comes along with TableColumnResizing as a bug. 
    removeLastColumn();
  }

  render() {
    return (
      <Grid
        rows={this.props.data}
        columns={[
          { name: "index", title: "S.No" },
          { name: "bookingId", title: "Booking ID" },
          { name: "booking_date", title: "Booking Timestamp" },
          { name: "pickup_date", title: "Pickup Date" },
          { name: "pickup_time", title: "Pickup Time" },
          // { name: "trailer", title: "Assigned Trailer" },
          { name: "startPoint", title: "Start Point" },
          { name: "dropPoint", title: "Drop Point" },
          { name: "attended_on", title: "Attended On" },
          { name: "status", title: "Delivery Status" },
          // { name: "verified", title: "Verified" },
          // { name: "action", title: "Action" }
        ]}
      >
        {/* <SelectionState /> */}
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <IntegratedPaging />
        <Table
          tableComponent={
            this.props.tableComponent
              ? this.props.tableComponent
              : TableComponent
          }
          rowComponent={this.TableRowClickable}
          messages={{ noData: "..." }}
        />
        <TableColumnResizing resizingMode="nextColumn" columnWidths={this.state.customColumnWidth} onColumnWidthsChange={(width) => this.setState({customColumnWidth: width})}/>
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel
          inputComponent={
            this.props.searchComponent
              ? this.props.searchComponent
              : GridSearchBox
          }
        />
        {/* <IntegratedSelection /> */}
        {/* <TableSelection showSelectAll={true} highlightRow={true} /> */}
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}

export default CurrentBookings;
