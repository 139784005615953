import React, { Component } from "react";
import { Link } from "react-router-dom";
import { dateTimeformatter } from "../../Commons";
import { Colors } from "../../Constants";
import "./notificationListPopup.css";
// props: {trigger, triggerStyle, index, triggerName, show}
class NotificationListPopup extends Component {
  state = { show: false };

  render () {
    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          zIndex: 3,
          float: "right",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "30px",
            right: "-10px",    
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
            zIndex: 2,
            minWidth: "160px",
            width: "390px",
            backgroundColor: "#fff",
            cursor: "default",
            ...this.props.style
          }}
          className="custom-scrollbar noti-items-wpr"
        >
          {/* { true && ( */}
          {this.props.show && (
            <>
              <Link
                to={"/dashboard/notifications"}
                onClick={(e) => {
                  window.location.assign("/dashboard/notifications");
                }}
                className="noti-see_all"
              >See All</Link>
              <ul 
                style={{maxHeight: "75vh",
                overflow: "auto",}}
                className={"custom-scrollbar"}
              >
                {this.props.data.map(item => {
                  return (
                    <Link
                      style={{padding: "0"}}
                      to={`/dashboard/${item.payload.data.type}/${item.payload.data.id}`}
                      onClick={(e) => {
                        window.location.assign(`/dashboard/${item.payload.data.type}/${item.payload.data.id}`);
                      }}
                    >
                      <li
                        className="noti-item"
                        key={item.id}
                      >
                        <h6 className="noti-title">{item.payload.notification.title}</h6>
                        {item.payload.notification.body.split("\n").map((data, index) => (
                          <p className="noti-body" key={index}>{data}</p>
                        ))}
                        <p className="noti-date">{item.updatedAt? dateTimeformatter(item.updatedAt) : dateTimeformatter(item.createdAt)}</p>
                      </li>
                    </Link>
                  )
                })}
              </ul>
            </>
          )}
        </div>
      </div>

    )
  }
}
export default NotificationListPopup;
