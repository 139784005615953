import React, { Component } from "react";
import "./marker.css";
// marker for google map location
class Marker extends Component {
  state = {
    popup: false
  };
  render() {
    let data = this.props.data;
    console.log("Marker Data", data);
    return (
      <div
        className="MarkerWrapper"
        onMouseLeave={e => {
          this.setState({ popup: false });
        }}
        onClick={() => {
          let center = { lat: this.props.lat, lng: this.props.lng };
          let zoom = 12;
          this.props.setFocus(center, zoom);
        }}
      >
        <div
          className="MapMarkerIcon"
          onMouseEnter={e => {
            this.setState({ popup: true });
          }}
        >
          <img
            alt="trailer_icon "
            src={
              this.props.src
                ? this.props.src
                : require("Assets/Icons/marker.svg")
            }
            style={{ height: "50px", width: "50px" }}
          />
        </div>
        <div className="MapMarkerLabel">{`${data.trailer.reg_no}`}</div>
        {this.state.popup && (
          <div className="MapMarkerOverlay">
            <div>
              {/* <div className="MarkerOverlayListItem">
                <p>Route Name :</p>
                <p>{data.route.route.name || "-"}</p>
              </div> */}
              <div className="MarkerOverlayListItem">
                <p>Origin :</p>
                <p>{data.bookingDetails.pickup_location_name || "-"}</p>
              </div>
              <div className="MarkerOverlayListItem">
                <p>Destination :</p>
                <p>{data.bookingDetails.drop_location_name || "-"}</p>
              </div>
              <div className="MarkerOverlayListItem">
                <p>Start Date :</p>
                <p>{new Date(data.start_date).toDateString() || "-"}</p>
              </div>
              {/* <div className="MarkerOverlayListItem">
                <p>Journey Type :</p>
                <p>{data.type ? data.type.toUpperCase() : "-"}</p>
              </div> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Marker;
