import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { ActiveJourneysList, JourneyHistory, NewJourneyDialog } from "Sections";
import Tabs, { ThemedTab as Tab } from "../../Components/ThemedTabs";
import { TableActionButton } from "Components/ActionButtons";
import { GetJourneysList } from "logic";
import { Colors } from "Constants";
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

class Journeys extends Component {
  state = {
    tab: this.props.match.params.tab ? Number(this.props.match.params.tab) : 0,
    showNewJourneyModal: false,
    active_journeys: [],
    journey_history: []
  };

  // to handle tab switching
  handleChange = (event, value) => {
    this.setState({ tab: value }, () => {
      if (this.state.tab == 0) {
        // window.location.reload();
      }
    });
    // window.location.reload();
  };

  // handle open and close of create new journey popup
  openCreateNewJourneyDialog = () => {
    this.setState({ showNewJourneyModal: true });
  };
  closeCreateNewJourneyDialog = () => {
    this.setState({ showNewJourneyModal: false });
  };

  // function which make api call to get active journey data and setup active journey table
  fetchActiveJourneys = async () => {
    let response = await GetJourneysList();
    let journeys = [];
    for (var item of response) {
      // console.log(item);
      if (item.status != "complete" && item.status != "cancelled") {
        journeys.push(item);
      }
    }
    let MappedRows = journeys.map((item, index) => {
      return {
        index: index + 1,
        trailer: item.trailer ? item.trailer.reg_no : "-",
        id: item.id,
        bookingId: item.bookingDetails.bookingId,
        // type: item.type,
        // route: item.route.name,
        driver: item.driver
          ? `${item.driver.first_name || ""} ${item.driver.last_name || ""}`
          : "Not Available",
        start_date: item.start_date
          ? new Date(item.start_date).toDateString()
          : "-",
        end_date: item.end_date ? new Date(item.end_date).toDateString() : "-",
        status: item.status.replace("_", " ").toUpperCase()
      };
    });
    this.setState({ active_journeys: MappedRows });
  };

  // function which make api call to get journey history data and setup journey history table
  fetchJourneyHistory = async () => {
    let response = await GetJourneysList();
    let journeys = [];
    for (var item of response) {
      if (item.status == "complete" || item.status == "cancelled") {
        journeys.push(item);
      }
    }

    let MappedRows = journeys.map((item, index) => {
      return {
        index: index + 1,
        trailer: item.trailer ? item.trailer.reg_no : "-",
        id: item.id,
        bookingId: item.bookingDetails.bookingId,
        driver: item.driver
          ? `${item.driver.first_name || ""} ${item.driver.last_name || ""}`
          : "Not Available",
        // type: item.type,
        // route: item.route.route.name,
        start_date: item.start_date
          ? new Date(item.start_date).toDateString()
          : "-",
        end_date: item.end_date ? new Date(item.end_date).toDateString() : "-",
        status: item.status.replace("_", " ").toUpperCase()
      };
    });
    this.setState({ journey_history: MappedRows });
  };

  // executes before component render
  componentDidMount() {
    this.fetchActiveJourneys();
    this.fetchJourneyHistory();
  }

  // to render journeys component
  render() {
    const { tab } = this.state;
    return (
      <div style={{ backgroundColor: Colors.body, minHeight: "100vh" }}>
        <div>
          {" "}
          {/* <div
            style={{
              border: "2px solid rgb(0, 137, 255)",
              color: "rgb(0, 137, 255)",
              padding: "10px",
              width: "200px",
              margin: "10px 10px",
              borderRadius: "40px",
              cursor: "pointer"
            }}
            onClick={() => {
              this.openCreateNewJourneyDialog();
            }}
          >
            New Listing
          </div> */}
        </div>
        <Tabs
          value={tab}
          onChange={this.handleChange}
          centered
          style={{ width: "100%", padding: "20px 18px" }}
        >
          <Tab label="Active Journeys" className="MainTab" />
          <Tab label="Journey History" className="MainTab" />
        </Tabs>
        {/* render either of active or history tab according to tab value */}
        {tab === 0 && (
          <TabContainer>
            <ActiveJourneysList data={this.state.active_journeys} />
          </TabContainer>
        )}
        {tab === 1 && (
          <TabContainer>
            <JourneyHistory data={this.state.journey_history} />
          </TabContainer>
        )}
        {/* <NewJourneyDialog
          open={this.state.showNewJourneyModal}
          handleClose={this.closeCreateNewJourneyDialog}
        /> */}
      </div>
    );
  }
}
export default Journeys;
