import React, { Component } from "react";
import "./style.css";
// input field along with button to send message in message thread (messages screen)
class MessageInputBox extends Component {
  state = {
    message: null
  };
  render() {
    return (
      <div className="MessageInputBoxWrapper">
        <div className="MessageInputFieldWrapper">
          <textarea
            className="DataTextarea"
            placeholder="Type Something......"
            onChange={e => {
              this.setState({ message: e.target.value });
            }}
            value={this.state.message}
            onKeyDown={e => {
              if (e.key == "Enter") {
                if (e.metaKey) {
                  if (this.state.message) {
                    this.props.sendMessage(this.state.message);
                    this.setState({ message: "" });
                  } else {
                    alert("Message Cannot Be Blank");
                  }
                }
              }
            }}
          />
          <div
            onClick={() => {
              if (this.state.message) {
                this.props.sendMessage(this.state.message);
                this.setState({ message: "" });
              } else {
                alert("Message Cannot Be Blank");
              }
            }}
            onKeyDown={e => {
              console.log(e.key);
            }}
          >
            <img
              src={require("Assets/Actions/send.svg")}
              style={{ height: "30px", width: "30px" }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default MessageInputBox;
