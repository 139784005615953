import React, { Component } from "react";
import DocumentPreview from "./DocumentPreview";
import { DocumentListing } from "Sections";

// driver document managemet component in driver details screen
class DriverDocumentManagement extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          padding: "25px",
          position: "relative",
          paddingBottom: "100px"
        }}
      >
        <DocumentListing type={"driver"} uid={this.props.uid} fetchProfile={this.props.fetchDriverOverview}/>

        <div
          style={{
            position: "absolute",
            right: "20px",
            // top: "0px",
            bottom: "10px",
            height: "75px",
            width: "75px",
            cursor: "pointer",
            backgroundColor: "#f95138",
            borderRadius: "50%",
            boxShadow: "0px 0px 6px 1px #aaa"
          }}
          className="ColumnCenter"
          onClick={() => {
            // console.log("Open Upload Document Dialog");
            this.props.openUploadDocumentDialog();
          }}
        >
          <img
            src={require("Assets/Actions/upload.svg")}
            style={{ height: "40px", widht: "40px" }}
          />
        </div>
      </div>
    );
  }
}
export default DriverDocumentManagement;
