import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
// import "./style.css";
import { NewCity, SetIntracityFare, UpdateCity } from "logic";
import { TableActionButton } from "Components/ActionButtons";
import { SelectStyle } from "componentstyle";
const initialState = {
  name: null,
  lat: null,
  lng: null,
  radius: null,
  extension_radius: null,
  min_cost: null,
  free_kms: null,
  rate_per_km: null,
  ollys_cut: null,
  drivers_cut: null,
  owners_cut: null,
  tax: null,
  insurance: null
};
// popup appears on click "Add City"/"Edit Details"/"Edit Fare" button in cities screen
class NewCityDialog extends Component {
  constructor(props) {
    super(props)
    this.state = initialState;
  }
  // state = {
  //   name: null,
  //   lat: null,
  //   lng: null,
  //   radius: null,
  //   extension_radius: null,
  //   min_cost: null,
  //   free_kms: null,
  //   rate_per_km: null,
  //   ollys_cut: null,
  //   drivers_cut: null,
  //   owners_cut: null,
  //   tax: null,
  //   insurance: null
  // };
  // execute on click create button for create new city
  // make api call to create new city
  CreateCity = async () => {
    let response = await NewCity({
      name: this.state.name,
      lat: this.state.lat,
      lng: this.state.lng,
      radius: this.state.radius,
      extension_radius: this.state.extension_radius,
      fare: {
        min_cost: this.state.min_cost,
        free_kms: this.state.free_kms,
        rate_per_km: this.state.rate_per_km,
        ollys_cut: this.state.ollys_cut,
        drivers_cut: this.state.drivers_cut,
        owners_cut: this.state.owners_cut,
        tax: this.state.tax,
        insurance: this.state.insurance
      }
    });
    if (response) {
      window.location.reload();
    }
  };
  componentWillReceiveProps = props => {
    if (props.data)
      this.setState({
        ...props.data,
        lat: props.data.center.coordinates[0],
        lng: props.data.center.coordinates[1]
      });
  };
  // execute on click save button for fare edit
  // make api call to save the changes made
  SaveFareEdit = async () => {
    let response = await SetIntracityFare(this.props.uid, {
      min_cost: this.state.min_cost,
      free_kms: this.state.free_kms,
      rate_per_km: this.state.rate_per_km,
      ollys_cut: this.state.ollys_cut,
      drivers_cut: this.state.drivers_cut,
      owners_cut: this.state.owners_cut,
      tax: this.state.tax,
      insurance: this.state.insurance
    });
    if (response) {
      window.location.reload();
    }
  };
  // execute on click save button for details edit
  // make api call to save the changes made
  SaveDetailsEdit = async () => {
    let response = await UpdateCity(this.props.uid, {
      name: this.state.name,
      lat: this.state.lat,
      lng: this.state.lng,
      radius: this.state.radius,
      extension_radius: this.state.extension_radius
    });
    if (response) {
      window.location.reload();
    }
  };

  // reset state to initial value
  resetState = () => {
    this.setState(initialState);
  }

  // reset state if modal opened is to add city
  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate:::::",this.props, prevProps);
    if(this.props.edit !== prevProps.edit && !this.props.edit) {
      console.log("states reseted");
      this.resetState()
    }
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              {this.props.edit
                ? this.props.edit === "fare"
                  ? "Edit Fare"
                  : this.props.edit === "details"
                  ? "Edit Details"
                  : "Error"
                : "New City"}
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            <div style={{ padding: "10px", boxSizing: "border-box" }}>
              {this.props.edit !== "fare" && (
                <React.Fragment>
                  <p
                    style={{
                      marginBottom: "0px",
                      marginLeft: "0px",
                      color: "#999"
                    }}
                  >
                    City Name:
                  </p>
                  <input
                    className="DataInput"
                    type="text"
                    style={{
                      width: "200px",
                      border: "1px solid #ddd",
                      //   marginLeft: "5px",
                      padding: "0px 10px",
                      width: "100%",
                      boxSizing: "border-box",
                      margin: "5px"
                    }}
                    placeholder="City Name"
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    value={this.state.name}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "100%", marginRight: "1px" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "0px",
                          marginTop: "5px",
                          color: "#999"
                        }}
                      >
                        Latitude:
                      </p>
                      <input
                        className="DataInput"
                        type="text"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Lat"
                        onChange={e => {
                          this.setState({ lat: e.target.value });
                        }}
                        value={this.state.lat}
                      />
                    </div>
                    <div style={{ width: "100%", marginLeft: "1px" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "0px",
                          marginTop: "5px",
                          color: "#999"
                        }}
                      >
                        Longitude:
                      </p>
                      <input
                        className="DataInput"
                        type="text"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Lng"
                        onChange={e => {
                          this.setState({ lng: e.target.value });
                        }}
                        value={this.state.lng}
                      />
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "100%", marginRight: "1px" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "0px",
                          marginTop: "5px",
                          color: "#999"
                        }}
                      >
                        Radius (KMs):
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Radius"
                        onChange={e => {
                          this.setState({ radius: e.target.value });
                        }}
                        value={this.state.radius}
                      />
                    </div>
                    <div style={{ width: "100%", marginRight: "1px" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "0px",
                          marginTop: "5px",
                          color: "#999"
                        }}
                      >
                        Extended Radius (KMs):
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Extended Radius"
                        onChange={e => {
                          this.setState({ extension_radius: e.target.value });
                        }}
                        value={this.state.extension_radius}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {this.props.edit !== "details" && (
                <React.Fragment>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between"
                    }}
                  >
                    <div style={{ marginRight: "2px" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "5px",
                          color: "#999"
                        }}
                      >
                        Minimum Fare:
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Minimum Fare"
                        onChange={e => {
                          this.setState({ min_cost: e.target.value });
                        }}
                        value={this.state.min_cost}
                      />
                    </div>
                    <div>
                      {" "}
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "5px",
                          color: "#999"
                        }}
                      >
                        Free KMs:
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Free KMs"
                        onChange={e => {
                          this.setState({ free_kms: e.target.value });
                        }}
                        value={this.state.free_kms}
                      />
                    </div>
                    <div style={{ marginLeft: "2px" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "5px",
                          color: "#999"
                        }}
                      >
                        Rate Per KM:
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Rate Per KM"
                        onChange={e => {
                          this.setState({ rate_per_km: e.target.value });
                        }}
                        value={this.state.rate_per_km}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between"
                    }}
                  >
                    <div style={{ marginRight: "2px" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "5px",
                          color: "#999"
                        }}
                      >
                        Ollys %:
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Olly's %"
                        onChange={e => {
                          this.setState({ ollys_cut: e.target.value });
                        }}
                        value={this.state.ollys_cut}
                      />
                    </div>
                    <div>
                      {" "}
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "5px",
                          color: "#999"
                        }}
                      >
                        Owners's %:
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Owner's %"
                        onChange={e => {
                          this.setState({ owners_cut: e.target.value });
                        }}
                        value={this.state.owners_cut}
                      />
                    </div>
                    <div style={{ marginLeft: "2px" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "5px",
                          color: "#999"
                        }}
                      >
                        Drivers's %:
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Driver's %"
                        onChange={e => {
                          this.setState({ drivers_cut: e.target.value });
                        }}
                        value={this.state.drivers_cut}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between"
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "5px",
                          color: "#999"
                        }}
                      >
                        Tax %
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Tax %"
                        onChange={e => {
                          this.setState({ tax: e.target.value });
                        }}
                        value={this.state.tax}
                      />
                    </div>

                    <div style={{ marginLeft: "2px", width: "100%" }}>
                      <p
                        style={{
                          marginBottom: "0px",
                          marginLeft: "5px",
                          color: "#999"
                        }}
                      >
                        Insurance Cost:
                      </p>
                      <input
                        className="DataInput"
                        type="number"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                          margin: "5px"
                        }}
                        placeholder="Insurance"
                        onChange={e => {
                          this.setState({ insurance: e.target.value });
                        }}
                        value={this.state.insurance}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                onClick={this.props.handleClose}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={e => {
                  if (!this.props.edit) {
                    this.CreateCity();
                  } else if (this.props.edit === "details") {
                    this.SaveDetailsEdit();
                  } else if (this.props.edit === "fare") {
                    this.SaveFareEdit();
                  } else {
                    alert("Error");
                  }
                }}
              >
                {this.props.edit ? "Save" : "Create"}
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default NewCityDialog;
