import React from "react";
// popup used in to show estimate refund amount on cancel booking from booking details screen
// props: [title, refund, confirmButtonText, cancelButtonText, onClose, onCancel]
const CancellationEstimateDialog = (props) => {
  return (
    <div
      style={{
        height: "300px",
        width: "300px",
        border: "3px solid #262c40",
        // borderRadius: "20px",
        // padding: "10px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          height: "50px",
          backgroundColor: "#353b50",
          textAlign: "center",
          // textTransform: "uppercase",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   borderRadius: "20px 20px 0px 0px",
          color: "#fff",
        }}
      >
        {props.title ? props.title : ""}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "60px",
        }}
      >
        <div>Refund Amount</div>
        <div style={{ fontSize: "28px", fontWeight: "bold" }}>
          {props.refund.toFixed(2)}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          right: "0px",
          justifyContent: "flex-end",
        }}
      >
        {" "}
        <div
          onClick={() => {
            props.onCancel && props.onCancel();
            props.onClose && props.onClose();
          }}
          style={{
            height: "45px",
            width: "100%",
            backgroundColor: "#262c40",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // margin: "5px",
            color: "#fff",
            // borderRadius: "40px",
            cursor: "pointer",
          }}
        >
          {props.cancelButtonText ? props.cancelButtonText : "Close"}
        </div>
        <div
          onClick={() => {
            props.onConfirm && props.onConfirm();
            props.onClose && props.onClose();
          }}
          style={{
            height: "45px",
            width: "100%",
            backgroundColor: "#f95138",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // margin: "5px",
            color: "#fff",
            // borderRadius: "40px",
            cursor: "pointer",
          }}
        >
          {props.confirmButtonText ? props.confirmButtonText : "Confirm"}
        </div>
      </div>
    </div>
  );
};

export default CancellationEstimateDialog;
