import React from "react";
import { TableActionButton } from "Components/ActionButtons";
// action confirmation popup, used in multiple screens
// props : {title, message, confirmButtonText, cancelButtonText, onCancel, onClose, onConfirm}
const ConfirmationDialog = props => {
  return (
    <div
      style={{
        // height: "220px",
        minWidth: "430px",
        maxWidth: "540px",
        border: "1px solid #353b50",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        // position: "relative",

        overflow: "hidden",
      }}
    >
      <div 
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          height: "50px",
          backgroundColor: "#262c40",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        {props.title ? props.title : ""}
      </div>
      <div style={{padding: "45px 20px", textAlign: "center"}}>
        {props.message ? props.message : ""}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end"
        }}
      >
        {" "}
        <TableActionButton
          onClick={() => {
            props.onCancel && props.onCancel();
            props.onClose && props.onClose();
          }}
          type="three"
          rootStyle={{ margin: "0px 0px", width: "50%", height: "45px" }}
        >
          {props.cancelButtonText ? props.cancelButtonText : "Cancel"}
        </TableActionButton>
        <TableActionButton
          onClick={() => {
            props.onConfirm && props.onConfirm();
            props.onClose && props.onClose();
          }}
          rootStyle={{ margin: "0px 0px", width: "50%", height: "45px" }}
        >
          {props.confirmButtonText ? props.confirmButtonText : "Confirm"}
        </TableActionButton>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
