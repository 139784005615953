import React from "react";
import { ClipLoader } from "react-spinners";

const Loader = props => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: props.height ? props.height : "80vh",
        alignItems: "center"
      }}
    >
      <ClipLoader
        //   css={override}
        sizeUnit={"px"}
        size={50}
        color={props.color ? props.color : "#262c40"}
        loading={true}
      />
    </div>
  );
};

export default Loader;
