import React, { Component } from "react";
import "./style.css";
// to display sent and received text messages in message thread (messages screen)
// props: {rootStyle, data}
class MessageBox extends Component {
  render() {
    let chatTime = new Date(this.props.data.createdAt);
    let [month, date, year] = chatTime.toLocaleDateString().split("/");
    let [hour, minute, second, a] = chatTime.toLocaleTimeString().split(/:| /);
    return (
      <div>
        {" "}
        <div className="MessageBoxWrapper" style={{ ...this.props.rootStyle }}>
          <div className="MessageBoxMessage">
            <pre>{this.props.data.message}</pre>
          </div>
        </div>
        <div className="MessageBoxTime">{`${date}/${month}/${year}  ${hour}:${minute}:${second} ${a}`}</div>
        {/* <div className="MessageBoxStats">
          <div>{this.props.data.time}</div>
          {this.props.data.sender_type == "admin" ? (
            <div>{this.props.data.status}</div>
          ) : (
            ""
          )}
        </div> */}
      </div>
    );
  }
}
export default MessageBox;
