import React, { Component } from "react";
class NotFound extends Component {
  render() {
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            left: "0px",
            top: "0px",
            bottom: "0px",
            right: "0px"
          }}
        >
          {" "}
          <img
            src={require("Assets/Icons/breakdown.svg")}
            style={{
              height: "400px",
              width: "400px",

              opacity: 0.05
            }}
          />
        </div>
        <p style={{ marginTop: "150px" }}>
          <span style={{ fontSize: "162px", opacity: 0.1 }}>404</span>
          <br />
          <span style={{ fontSize: "28px", opacity: 0.1 }}>Not Found</span>
          <br />
          <span style={{ fontSize: "18px", opacity: 0.1 }}>
            Please Check The URL You Have Entered
          </span>
        </p>
      </div>
    );
  }
}
export default NotFound;
