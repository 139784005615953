import React, { Component } from "react";
import { Link } from "react-router-dom";
import { dateTimeformatter } from "../../Commons";
import { Colors } from "../../Constants";
import "./style.css";

class NotificationItem extends Component {
  render() {
    const item = this.props.notification;
    return (
      <Link
        className="NotificationItem"
        style={{
          color: Colors.table_text,
          display: "block"
        }}
        to={`/dashboard/${item.payload.data.type}/${item.payload.data.id}`}
      >
        <h6 className="title">{item.payload.notification.title}</h6>
        <div className="body">
          {item.payload.notification.body.split("\n").map((data, index) => (
            <p key={index}>{data}</p>
          ))}
        </div>
        <p className="footer">{item.updatedAt? dateTimeformatter(item.updatedAt) : dateTimeformatter(item.createdAt)}</p>
      </Link>
    );
  }
}
export default NotificationItem;
