import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  TableColumnResizing 
} from "@devexpress/dx-react-grid";
import GridSearchBox from "Components/GridSearchBox";
import TableComponent, { PagingContainer, TableCellAccountStatus } from "Components/TableComponent";
import { GetOwnerList } from "logic";
import { getUrlParamsValue, handlePageChange, removeLastColumn, resetPageNo, setPageNoParams } from "../../Commons";

// Owner data listing section in Owners screen, listing is done using @devexpress/dx-react-grid library
class OwnerData extends Component {
  constructor(props) {
    super(props);
    this.initialRender = React.createRef();
    this.initialRender.current = true;
    this.OWNER_PAGE_NUMBER = "ownerPageNo"
  }
  state = { rows: [], selection: [], ownerPageNo: 0,
    customColumnWidth: [
      { columnName: "index", width: 110 },
      // { columnName: "firstName", width: "auto" },
      // { columnName: "middleName", width: "auto" },
      // { columnName: "lastName", width: "auto" },
      { columnName: "name", width: "auto" },
      { columnName: "address", width: "auto" },
      { columnName: "phone", width: "auto" },
      { columnName: "registeredOn", width: "auto" },
      { columnName: "accountStatus", width: "auto" },
    ]
  }
  componentDidMount() {
    GetOwnerList(this);
    if (getUrlParamsValue(this.OWNER_PAGE_NUMBER) !== 0) {
      setPageNoParams(this, this.OWNER_PAGE_NUMBER)
    }
    // remove last column of the table which comes along with TableColumnResizing as a bug. 
    removeLastColumn();
  }
  changeSelection = selection => {
    this.setState({ selection: selection });
  };
  // to enable table row click action
  TableRowClickable = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        window.location.assign(`/dashboard/owner/${tableRow.row.id}`)
      }}
      className={"clickable"}
    />
  );
  // to make "Not verified" cell color different
  Cell = (props) => {
    const { row, column } = props;
    if ( column.name === "accountStatus" && row.accountStatus === "Not Verified") {
      return <TableCellAccountStatus {...props} />;
    }
    return <Table.Cell {...props} />;
  };
  
  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          return {
            ...item,
            index: index + 1
          };
        })}
        columns={[
          { name: "index", title: "S.No" },
          // { name: "firstName", title: "First Name" },
          // { name: "middleName", title: "Middle Name" },
          // { name: "lastName", title: "Last Name" },
          { name: "name", title: "Name" },
          { name: "address", title: "Address" },
          { name: "phone", title: "Phone Number" },
          { name: "registeredOn", title: "Registered On" },
          { name: "accountStatus", title: "Account Status" }
        ]}
      >
        <SelectionState
          selection={this.state.selection}
          onSelectionChange={selection => {
            // console.log(selection);
            let ids = [];
            for (var i of selection) {
              ids.push(this.state.rows[i].id);
            }
            this.props.setOwnerSelection(ids);
            this.changeSelection(selection);
          }}
        />
        <SearchState defaultValue="" onValueChange={() => resetPageNo(this, this.OWNER_PAGE_NUMBER)}/>
        <IntegratedFiltering />
        <PagingState 
          currentPage={this.state.ownerPageNo} 
          pageSize={10} 
          onCurrentPageChange={(page) => {
            const ownerPageNoParams = getUrlParamsValue(this.OWNER_PAGE_NUMBER)
            if (ownerPageNoParams === null || (ownerPageNoParams !== 0 && !this.initialRender.current)) {
              handlePageChange(page, this, this.OWNER_PAGE_NUMBER)
            }
            if (this.initialRender.current) this.initialRender.current = false
          }} 
        />
        <IntegratedPaging />
        <Table tableComponent={TableComponent} cellComponent={this.Cell} messages={{ noData: "..." }} />
        <TableColumnResizing resizingMode="nextColumn" columnWidths={this.state.customColumnWidth} onColumnWidthsChange={(width) => this.setState({customColumnWidth: width})}/>
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel
          inputComponent={
            this.props.searchComponent
              ? this.props.searchComponent
              : GridSearchBox
          }
        />
        <IntegratedSelection />
        <TableSelection rowComponent={this.TableRowClickable} showSelectAll={true} highlightRow={true} />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}

export default OwnerData;
