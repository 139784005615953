import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import GridSearchBox from "Components/GridSearchBox";
import TableComponent, { PagingContainer } from "Components/TableComponent";
// journey history listing section in journeys screen, listing is done using @devexpress/dx-react-grid library
class JourneyHistory extends Component {
  // to enable table row click action
  TableRowClickable = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        window.location.assign(`/dashboard/journey/${tableRow.row.id}`)
      }}
      className={"clickable"}
    />
  );

  render() {
    return (
      <Grid
        rows={this.props.data}
        columns={[
          { name: "index", title: "S.No" },
          { name: "trailer", title: "Trailer" },
          // { name: "type", title: "Type" },
          { name: "bookingId", title: "Booking ID" },
          { name: "driver", title: "Driver" },
          { name: "start_date", title: "Start Date" },
          // { name: "end_date", title: "Listing Ends" },
          { name: "status", title: "Status" },
        ]}
      >
        {/* <SelectionState /> */}
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <IntegratedPaging />
        <Table
          tableComponent={
            this.props.tableComponent
              ? this.props.tableComponent
              : TableComponent
          }
          rowComponent={this.TableRowClickable}
          messages={{ noData: "..." }}
        />
        <TableHeaderRow />
        {/* <IntegratedSelection /> */}
        {/* <TableSelection showSelectAll={true} highlightRow={true} /> */}
        <Toolbar />
        <SearchPanel
          inputComponent={
            this.props.searchComponent
              ? this.props.searchComponent
              : GridSearchBox
          }
        />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}

export default JourneyHistory;
