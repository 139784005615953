import React, { Component } from "react";
import "./dropdown.css";
// props: {trigger, triggerStyle, index, triggerName, show}
class Dropdown extends Component {
  state = { show: false };
  render() {
    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          zIndex: 3,
          float: "right",
          height: "100%"
        }}
        onMouseLeave={() => {
          // this.setState({ show: !this.state.show });
          this.props.trigger(null);
        }}
      >
        <div
          style={{
            cursor: "pointer",
            margin: "0px 8px",
            ...this.props.triggerStyle
          }}
          onMouseEnter={() => {
            this.props.trigger(this.props.index);
          }}
        >
          {this.props.triggerName}
          <i className="material-icons right" style={{ margin: "2px" }}>
            arrow_drop_down
          </i>
        </div>
        {this.props.show ? (
          <div
            style={{
              position: "absolute",
              boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
              zIndex: 2,
              minWidth: "160px",
              width: "200px",
              float: "right",
              backgroundColor: "#fff",
              ...this.props.style
            }}
          >
            {" "}
            {this.props.children}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default Dropdown;
