import React, { Component } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import "./style.css";
// currently not used
class RouteListItem extends Component {
  state = {
    activeStep: 0
  };
  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };
  render() {
    const { data } = this.props;
    const { activeStep } = this.state;
    return (
      <div className="RouteListItemContainer">
        <div
          className="RouteListItemWrapper"
          onClick={e => {
            this.props.setExpansionIndex(this.props.index);
          }}
          style={{ cursor: "pointer" }}
        >
          <div className="RouteListItemRouteName">
            <span style={{ fontSize: "12px", color: "#777" }}>
              Route Name: &nbsp;
            </span>
            {data.name}
          </div>
          <div className="RouteListItemFromToPreview">
            <img
              src={require("Assets/Icons/route.svg")}
              style={{ height: "25px", width: "25px" }}
            />
            <div>{data.origin}</div>
            <div className="RouteListItemToFromIcon">
              <img
                src={require("Assets/Icons/fromto.svg")}
                //   style={{ height: "25px", width: "25px" }}
              />
            </div>
            <div>{data.destination}</div>
          </div>
          <div>
            {`${data.stopCount && data.stopCount} `}
            <span style={{ fontSize: "12px", color: "#777" }}>Stops</span>
          </div>
        </div>
        {this.props.expanded && (
          <div className="RouteListItemExpansionWrapper">
            <Stepper activeStep={activeStep} orientation="vertical">
              {data.stops.map((item, index) => (
                <Step key={index}>
                  {/* <StepLabel
                  onClick={e => {
                    this.setState({ activeStep: index });
                  }}
                >
                  {item.name}
                </StepLabel> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      cursor: "pointer"
                    }}
                    onClick={e => {
                      this.setState({ activeStep: index });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "4px",
                        backgroundColor: "#275bb0",
                        height: "30px",
                        width: "30px",
                        borderRadius: "100%",
                        color: "#fff"
                      }}
                      onClick={e => {
                        this.setState({ activeStep: index });
                      }}
                    >
                      {index + 1}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "4px"
                      }}
                      onClick={e => {
                        this.setState({ activeStep: index });
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                  <StepContent>
                    <div>
                      <div style={{ textAlign: "left" }}>
                        <span style={{ fontSize: "12px", color: "#777" }}>
                          Name: &nbsp;
                        </span>
                        {item.name}
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <span style={{ fontSize: "12px", color: "#777" }}>
                          Type: &nbsp;
                        </span>
                        {item.type}
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            <hr />
          </div>
        )}
      </div>
    );
  }
}
export default RouteListItem;
