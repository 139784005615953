import React, { Component } from "react";
import { DocumentListing } from "Sections";
import "./style.css";
import { TrailerRouteMap } from "Sections";
import EditButton from "Components/EditButton";
// trailer overview section in trailer details screen
class TrailerOverview extends Component {
  constructor(props) {
    super();
    this.QRContainer = React.createRef();
  }

  render() {
    let svg = this.props.qr;
    let blob = new Blob([svg], { type: "image/svg+xml" });
    let url = URL.createObjectURL(blob);
    //CODE ABOVE HANDLES SVG
    let data = this.props.data;
    console.log("RenderLog", data);
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div>
            {" "}
            <div
              className="TrailerProfileWrapper"
              style={{
                flex: "3",
                marginRight: "5px",
                minWidth: "600px",
                maxWidth: "800px",
              }}
            >
              <div
                className="TrailerOverviewStatsTitle"
                style={{ position: "relative " }}
              >
                Trailer Details{" "}
                <EditButton
                  style={{ position: "absolute", top: "0px", right: "0px" }}
                  onClick={this.props.openEditDialog}
                />
              </div>
              <div className="TrailerProfileItemsWrapper">
                <div className="TrailerProfileItem">
                  <p>Registration Number: </p>
                  <p>{data.reg_no || "-"}</p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Type: </p>
                  <p>{data.trailer_type ? data.trailer_type.title : "-"}</p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Tare Weight: </p>
                  <p>{data.tare_weight ? data.tare_weight : "-"}</p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Current Status: </p>
                  <p>
                    {data.status
                      ? data.status.replace("_", " ").toUpperCase()
                      : "-"}
                  </p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Current Booking : </p>
                  <p>
                    {(this.props.booking && this.props.booking.bookingId) ||
                      "_"}
                  </p>
                  {this.props.booking && (
                    <a
                      href={`/dashboard/booking/${this.props.booking.id}`}
                      style={{ position: "absolute", top: "0px", right: "0px" }}
                    >
                      <div
                        style={{
                          height: "30px",
                          width: "80px",
                          borderRadius: "0px",
                          backgroundColor: "#4caf50",
                          marginLeft: "25px",
                          color: "#fff",
                          display: "flex",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Montserrat",
                        }}
                      >
                        See Details
                      </div>
                    </a>
                  )}
                </div>
                <div className="TrailerProfileItem">
                  <p>Owner: </p>
                  <p>
                    {data.ownerDetails
                      ? `${data.ownerDetails.first_name} ${data.ownerDetails.last_name}`
                      : ""}
                  </p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Driver: </p>
                  <p>
                    {data.driverDetails
                      ? `${data.driverDetails.first_name || ""} ${
                          data.driverDetails.middle_name || ""
                        } ${data.driverDetails.last_name || ""}`
                      : "-"}
                    {/* {this.props.driver
                      ? `${this.props.driver.first_name || ""} ${
                          this.props.driver.middle_name || ""
                        } ${this.props.driver.last_name || ""}`
                      : "-"} */}
                  </p>
                  {data.driverDetails && (
                    <a
                      href={`/dashboard/driver/${data.driverDetails.id}`}
                      style={{ position: "absolute", top: "0px", right: "0px" }}
                    >
                      <div
                        style={{
                          height: "30px",
                          width: "80px",
                          borderRadius: "0px",
                          backgroundColor: "#4caf50",
                          marginLeft: "25px",
                          color: "#fff",
                          display: "flex",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Visit Profile
                      </div>
                    </a>
                  )}
                </div>
                <div className="TrailerProfileItem">
                  <p>Tonnage: </p>
                  <p>{data.tonnage || "-"}</p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Make: </p>
                  <p>{data.make || "-"}</p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Year Of Purchase: </p>
                  <p>{data.yop || "-"}</p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Last Health Check: </p>
                  <p>
                    {new Date(data.last_health_check).toDateString() || "-"}
                  </p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Last Known Location: </p>
                  <p>
                    {data.last_location_name ? data.last_location_name : "-"}
                  </p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Verified: </p>
                  <p>{data.verified ? "Verified" : "Not Verified" || "-"}</p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Maintenance </p>
                  <p>
                    {data.maintenance
                      ? "In For Maintenance"
                      : "Not In For Maintenance" || "-"}
                  </p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Booking Availibility </p>
                  <p>{data.booking_visibility ? "Marked Available" : "No"}</p>
                </div>
                <div className="TrailerProfileItem">
                  <p>Marked Location</p>
                  <p>
                    {data.booking_visibility && data.marked_location
                      ? `${data.marked_location.coordinates[0].toFixed(
                          5
                        )}, ${data.marked_location.coordinates[1].toFixed(5)}`
                      : "Not Available"}
                  </p>
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      cursor: "pointer",
                    }}
                    onClick={this.props.openBookingAvailabilityEdit}
                  >
                    <div
                      style={{
                        height: "30px",
                        width: "80px",
                        borderRadius: "0px",
                        backgroundColor: "#4caf50",
                        marginLeft: "25px",
                        color: "#fff",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Update
                    </div>
                  </div>
                </div>
              </div>
              { /** Display QR code to scan trailer by driver */
                /* <div
                  style={{
                    height: "300px",
                    width: "300px",
                    margin: "10px auto",
                    backgroundColor: "#f3f3f3",
                    borderRadius: "20px",
                    padding: "10px",
                    boxSizing: "border-box",
                  }}
                >
                  <img src={url} style={{ height: "280px", width: "280px" }} />
                </div> */
              }
              <div className="RowCenter" style={{ width: "100%" }}>
                <div
                  className="TrailerOverviewActionButton"
                  style={{
                    backgroundColor: "rgb(77, 200, 149)",
                    whiteSpace: "nowrap",
                    padding: "5px",
                    //   borderRadius: "10px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  onClick={() => {
                    window.location.assign(`/dashboard/owner/${data.owner}`);
                  }}
                >
                  {" "}
                  View Owner Details
                </div>
                {this.props.driver && (
                  <div
                    className="TrailerOverviewActionButton"
                    style={{
                      backgroundColor: "#ffc107",
                      whiteSpace: "nowrap",
                      padding: "5px",
                      //   borderRadius: "10px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#222",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    onClick={() => {
                      window.location.assign(
                        `/dashboard/driver/${this.props.driver.id}`
                      );
                    }}
                  >
                    {" "}
                    View Driver Details
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="TrailerProfileWrapper"
            style={{ flex: "4", marginLeft: "0px" }}
          >
            <div className="TrailerOverviewStatsTitle">
              Current Trailer Route
            </div>

            <TrailerRouteMap
              data={{
                pickup: this.props.booking
                  ? this.props.booking.pickup_location
                  : null,
                drop: this.props.booking
                  ? this.props.booking.drop_location
                  : null,
              }}
              marker_location={data.last_known_location}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div className="TrailerProfileWrapper" style={{ flex: "4" }}>
            <div
              className="TrailerOverviewStatsTitle"
              style={{ position: "relative" }}
            >
              Trailer Documents{" "}
              <div
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "0px",
                  bottom: "0px",
                  height: "100%",
                  cursor: "pointer",
                }}
                className="ColumnCenter"
                onClick={() => {
                  // console.log("Open Upload Document Dialog");
                  this.props.openUploadDocumentDialog();
                }}
              >
                <img
                  src={require("Assets/Actions/upload.svg")}
                  style={{ height: "25px", widht: "25px" }}
                />
              </div>
            </div>

            <DocumentListing type={"trailer"} uid={this.props.uid} fetchProfile={this.props.fetchTrailerOverview}/>
          </div>
        </div>
      </div>
    );
  }
}
export default TrailerOverview;
