import React, { Component } from "react";
import { BookingOverview, CargoList } from "Sections";
import {
  GetSingleBooking,
  VerifyBooking,
  GetBookingCancellationEstimate,
  ConfirmBookingCancellation,
  CreateOwnerDropdownOptions,
  AllotBookingToOwner
} from "logic";
import Loader from "Components/Loader";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import AllotOwnerConfirmationDialog from "../../Components/AllotOwnerConfirmationDialog";
import { confirmAlert } from "react-confirm-alert";
import CancellationEstimateDialog from "../../Components/CancellationEstimateDialog";
import { AssignTrailerDialog, InitiateRefundDialog, AllotOwnerDialog } from "Sections";
import { ProfileActionButton } from "Components/ActionButtons";
import CreatePricingDialog from "../../Sections/CreatePricingDialog";

class Booking extends Component {
  state = { 
    showAssignTrailerModal: false, 
    showInitiateRefundModal: false,
    showAllotOwnerModal: false,
    showAllotOwnerConfirmationModal: false,
    ownerAllotStatus: false,
    ownerList: [],
    owner: null
  };

  // executes before component render
  // call the function which make api call to get booking details and to get all owners
  componentDidMount() {
    this.getBooking();
    CreateOwnerDropdownOptions(this, "ownerList");
  }

  // modal opening and closing functions
  openTrailerAssignmentModal = () => {
    this.setState({ showAssignTrailerModal: true });
  };
  closeTrailerAssignmentModal = () => {
    this.setState({ showAssignTrailerModal: false });
  };
  openOwnerAllotmentModal = () => {
    this.setState({ showAllotOwnerModal: true });
  };
  closeOwnerAllotmentModal = () => {
    this.setState({ showAllotOwnerModal: false });
  };
  openConfirmOwnerAllotmentModal = () => {
    this.setState({ showAllotOwnerConfirmModal: true });
  };
  closeConfirmOwnerAllotmentModal = () => {
    this.setState({ showAllotOwnerConfirmModal: false });
  };
  openSetPricingModal = () => {
    this.setState({ showSetPricingModal: true });
  };
  closeSetPricingModal = () => {
    this.setState({ showSetPricingModal: false });
  };
  openInitiateRefundModal = () => {
    this.setState({ showInitiateRefundModal: true });
  };
  closeInitiateRefundModal = () => {
    this.setState({ showInitiateRefundModal: false });
  };

  // function which make api call to get booking details
  getBooking = async () => {
    let response = await GetSingleBooking(this.props.match.params.id);
    this.setState({ data: response.data.data }, () => {
      if (this.state.data.owner) {
        this.setState({ownerAllotStatus: true})
      }
    });
  };

  // function which make api call to get verify booking
  verifyBooking = async () => {
    let response = await VerifyBooking(this.props.match.params.id);
    this.getBooking();
  };

  // function which make api call to get booking cancellation estimate amounts
  // return the estimate amount
  getCancellationEstimate = async () => {
    let response = await GetBookingCancellationEstimate(
      this.props.match.params.id
    );
    console.log("Cancellation Est.", response);
    if (response) return response.data.refund_amount;
  };

  // function which make api call to allot booking to owner
  allotOwner = async () => {
    let response = await AllotBookingToOwner(this.props.match.params.id, {
      owner: this.state.owner.id,
    });

    if(response) {
      window.location.reload();
      console.log("alloted response:", response);
    }
  }

  // function which make api call to confirm cancellation
  canceBooking = async () => {
    let response = await ConfirmBookingCancellation(this.props.match.params.id);
    if (response) {
      this.getBooking();
    }
  };

  // to set ower state on selecting an item in allot to owner modal
  setOwnerToAllot = (owner) => {
    console.log("setting owner:", owner);
    this.setState({owner: owner})
  }

  // function which render confirm verify booking popup
  confirmVerifyBooking = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Are you sure?"}
            message={"This action cannot be undone"}
            onConfirm={this.verifyBooking}
            onClose={onClose}
          />
        );
      },
    });
  };

  // function which render confirm booking cancellation popup
  confirmCancelBooking = async () => {
    let cancellationEstimate = await this.getCancellationEstimate();
    if (cancellationEstimate)
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <CancellationEstimateDialog
              title={"Cancellation Estimate"}
              refund={cancellationEstimate}
              onConfirm={this.canceBooking}
              onClose={onClose}
            />
          );
        },
      });
  };

  // to show emergency cancellation popup
  ConfirmOwnerAllotment = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AllotOwnerConfirmationDialog
            onClose={onClose}
            onConfirm={this.allotOwner}
            confirmButtonText="Yes"
            cancelButtonText="No"
          />
        );
      },
    });
  };

  // to render booking details component
  render() {
    let item = this.state.data;
    console.log("Booking data:",item);
    if (!item) {
      return <Loader />;
    }
    return (
      <div style={{ width: "100%", backgroundColor: "#353b50" }}>
        {/* to conditionally render "Mark Pricing", "Cancel Booking", "Assign Trailer" buttons */}
        {item.status != "complete" &&
          item.status != "cancelled" &&
          !item.cancelled &&
          item.is_cancellable && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {item.awaiting_price_marking && (
                <ProfileActionButton
                  backgroundColor="#ccc"
                  textColor="#454545"
                  onClick={() => {
                    this.openSetPricingModal();
                    // this.confirmCancelBooking();
                  }}
                >
                  Mark Pricing
                </ProfileActionButton>
              )}
              {!item.awaiting_price_marking && !item.awaiting_payment && (
                <ProfileActionButton
                  backgroundColor="rgb(242, 168, 59)"
                  textColor="#ffffff"
                  onClick={() => {
                    this.confirmCancelBooking();
                  }}
                >
                  Cancel Booking
                </ProfileActionButton>
              )}
              {!(!this.state.data.owner && this.state.data.journey) && !item.awaiting_price_marking && !item.awaiting_payment && (
                <ProfileActionButton
                  onClick={() => {
                    this.openOwnerAllotmentModal();
                  }}
                  backgroundColor={this.state.ownerAllotStatus ? "#a1a1a1" : "#ccc"}
                  textColor="#454545"
                  disabled={this.state.ownerAllotStatus}
                >
                  {this.state.ownerAllotStatus? "Allotted to Owner" : "Allot to Owner"}
                </ProfileActionButton>
              )}
              {!item.journey && !item.awaiting_price_marking && !item.awaiting_payment && (
                <ProfileActionButton
                  onClick={() => {
                    this.openTrailerAssignmentModal();
                  }}
                  backgroundColor="#ccc"
                  textColor="#454545"
                >
                  {" "}
                  Assign Trailer
                </ProfileActionButton>
              )}
            </div>
          )}
        {console.log(item, "refund")}
        {/* to conditionally render "Initiate Refund" button */}
        {item.cancelled &&
          item.cancellation_refundable &&
          item.refunded_amount === 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ProfileActionButton
                onClick={() => {
                  this.openInitiateRefundModal();
                }}
              >
                Initiate Refund
              </ProfileActionButton>
            </div>
          )}
        <BookingOverview data={item} />
        <CargoList data={item} />
        <AssignTrailerDialog
          uid={this.props.match.params.id}
          open={this.state.showAssignTrailerModal}
          handleClose={this.closeTrailerAssignmentModal}
          pickup_city={
            item.cityId ? item.cityId : item.route ? item.route.origin : null
          } //TODO: Pickup CITY for itercity needs to be added
          allotedOwner={item.owner}
        />
        <AllotOwnerDialog
          open={this.state.showAllotOwnerModal}
          handleClose={this.closeOwnerAllotmentModal}
          openConfirmaion={this.ConfirmOwnerAllotment}
          ownerList={this.state.ownerList}
          owner={this.state.owner}
          selectOwner={this.setOwnerToAllot}
        />
        <CreatePricingDialog
          open={this.state.showSetPricingModal}
          handleClose={this.closeSetPricingModal}
          id={this.props.match.params.id}
          fare={this.state.data.fare}
        />
        <InitiateRefundDialog
          uid={this.props.match.params.id}
          open={this.state.showInitiateRefundModal}
          handleClose={this.closeInitiateRefundModal}
          total_cost={item.total_cost}
        />
      </div>
    );
  }
}

export default Booking;
