import React, { Component } from "react";
import { JourneyOverview } from "Sections";
import Loader from "Components/Loader";
import Tabb from "@material-ui/core/Tabs";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { CurrentBookings, JourneyStops } from "Sections";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import EmergencyCancellationDialog from "../../Components/EmergencyCancellationDialog";
import { MarkEmergencyJourneyCancellation } from "logic";
import {
  // GetBookingsByJourneyId,
  GetJourneyById,
  GetStopsByJourneyId,
  PrepareForDispatch,
  CloseBookings,
  CancelJourney,
} from "logic";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ProfileActionButton } from "Components/ActionButtons";
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const Tabs = withStyles({ indicator: { border: "1px solid #999" } })(Tabb);
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
// gives details of a journey
class Journey extends Component {
  // state = {};
  state = {
    tab: this.props.match.params.tab ? Number(this.props.match.params.tab) : 0,
    // bookings: null,
    stops: null,
    loading: false,
  };
  handleChange = (event, value) => {
    this.setState({ tab: value }, () => {
      if (this.state.tab == 0) {
        // window.location.reload();
      }
    });
    // window.location.reload();
  };

  // executes on click 'Mark as cancelled' button in emergency cancellation popup
  // calls api that makes the journey marked as cancelled 
  MarkJourneyCancelled = async () => {
    let response = await MarkEmergencyJourneyCancellation(
      this.props.match.params.id
    );

    if (response) {
      this.GetJourney();
      window.location.reload();
    }
  };

  // function makes api call to get journey details
  GetJourney = async () => {
    let journey = await GetJourneyById(this.props.match.params.id);
    console.log("active", journey);
    this.setState({ data: journey });
  };
  // function makes api call to get stop details
  GetStops = async () => {
    let stops = await GetStopsByJourneyId(this.props.match.params.id);
    console.log("stops", stops);

    this.setState({ stops: stops });
  };

  // to show emergency cancellation confirmation popup
  ShowEmergencyCancellationDialog = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <EmergencyCancellationDialog
            onClose={onClose}
            cancellation_code={
              this.state.data && this.state.data.driver_cancellation_code
                ? this.state.data.driver_cancellation_code
                : ""
            }
            marked={
              this.state.data && this.state.data.emergency_cancellation
                ? true
                : false
            }
            btnDissable={this.state.loading}
            btnDissabled={this.donothing}
            onConfirm={this.MarkJourneyCancelled}
            confirmButtonText="Mark as cancelled"
          />
        );
      },
    });
  };
  // not used
  ConfirmDispatch = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Are You Sure?"}
            message={"Journey Once Dispatched Cannot Be Modified"}
            onConfirm={this.DispatchJourney}
            onClose={onClose}
          />
        );
      },
    });
  };
  // not used
  DispatchJourney = async () => {
    let dispatchResponse = await PrepareForDispatch(this.props.match.params.id);
    console.log(dispatchResponse);
    this.GetJourney();
  };
  // not used
  ConfirmCloseBooking = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Are You Sure?"}
            message={"Journey Once Dispatched Cannot Be Modified"}
            onConfirm={this.CloseBookings}
            onClose={onClose}
          />
        );
      },
    });
  };
  // not used
  CloseBookings = async () => {
    let closeBookingsResponse = await CloseBookings(this.props.match.params.id);
    this.GetJourney();
  };
  // not used
  ConfirmCancelJourney = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Are You Sure?"}
            message={"This action cannot be undone"}
            onConfirm={this.CancelJourney}
            onClose={onClose}
          />
        );
      },
    });
  };
  CancelJourney = async () => {
    console.log("Cancelling Journey");
    let response = await CancelJourney(this.props.match.params.id);
    this.GetJourney();
  };

  // executes before component render
  componentDidMount() {
    this.GetJourney();
    this.GetStops();
  }

  // to render journey details component
  render() {
    const { tab } = this.state;
    console.log(this.state.data);

    if (!this.state.data) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    return (
      <div style={{ backgroundColor: "#353b50" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* show emergency cancellation button if journey is active */}
          {this.state.data && this.state.data.active && (
            <ProfileActionButton
              width="200px"
              backgroundColor="rgb(235, 136, 136)"
              onClick={() => {
                this.ShowEmergencyCancellationDialog();
              }}
            >
              {" "}
              Emergency Cancellation
            </ProfileActionButton>
          )}
        </div>
        <div style={{ height: "25px" }} />
        <JourneyOverview data={this.state.data} />
        <div style={{ height: "25px" }} />
        <div
          style={{
            marginLeft: "2px",
            padding: "8px",
            backgroundColor: "#353b50",
            paddingBottom: "150px",
          }}
        >
          <h4
            style={{
              textAlign: "left",
              // textDecoration: "underline",
              color: "#f3f3f3",
            }}
          >
            Stops
          </h4>
          <JourneyStops
            data={{ journey: this.state.data, stops: this.state.stops }}
          />
        </div>
      </div>
      // </div>
    );
  }
}
export default Journey;
