import { colors } from "@material-ui/core";
import React, { Component } from "react";
import NotificationItem from "../../Components/NotificationItem";
import { Colors } from "../../Constants";
import { GetAdminNotificationList } from "../../logic";
import "./notifications.css"

// component for send notifications screen
class Notifications extends Component {
  state = {
    notifications: null
  }
  // function makes api call to get last 100 admin notifications
  componentDidMount = async () => {
    let response = await GetAdminNotificationList(100)
    this.setState({ notifications: response });
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: Colors.body,
          color: Colors.table_text,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "32px"
        }}
        className="Notifications-page"
      >
        <div
          style={{backgroundColor: Colors.background}}
          className="Notifications-page_wpr"
        >
          <h6 className="Notifications-page_title">All Notifications</h6>
          {this.state.notifications && this.state.notifications.map(item => {
            return (
              <NotificationItem notification={item} key={item.id}/>
            )
          })}
        </div>
      </div>
    );
  }
}
export default Notifications;
