import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {
  CreditsList,
  DuesList,
  PaymentsList,
  RefundsList,
  UpdateCreditDialog,
  OwnerSettlements,
  DriverSettlement
} from "Sections";
import Tabs, { ThemedTab as Tab } from "../../Components/ThemedTabs";
import { Colors } from "Constants";
import { ChangeCreditLimitForUser } from "logic";
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class CashManagement extends Component {
  state = {
    tab: this.props.match.params.tab ? Number(this.props.match.params.tab) : 0,
    showCreditEdit: false,
    currentCreditEdit: null,
    currentEditCreditMax: 0
  };

  // to handle tab change
  handleChange = (event, value) => {
    this.setState({ tab: value });
  };

  // to handle credit amount editing
  openCreditEdit = (listing_id, current_max) => {
    this.setState(
      {
        currentCreditEdit: listing_id,
        currentEditCreditMax: current_max
      },
      () => {
        this.setState({
          showCreditEdit: true
        });
      }
    );
  };

  // executes when update button in credit update popup is clicked
  // make api call to update credit amount
  saveCredit = async (id, amount) => {
    let response = await ChangeCreditLimitForUser(id, amount);
    if (response) window.location.reload();
  };
  closeCreditEdit = callback => {
    this.setState({ showCreditEdit: false }, callback);
  };

  // to render finance listing component
  render() {
    const { tab } = this.state;
    console.log(this.props.match.params.tab);
    return (
      <div style={{ backgroundColor: Colors.body, minHeight: "100vh" }}>
        <Tabs
          value={tab}
          onChange={this.handleChange}
          centered
          style={{ width: "100%", padding: "20px 18px" }}
        >
          <Tab label="Credit" className="MainTab" />
          <Tab label="Dues" className="MainTab" />
          <Tab label="Payment" className="MainTab" />
          <Tab label="Refunds" className="MainTab" />
          <Tab label="Owner Payout" className="MainTab" />
          <Tab label="Driver Payout" className="MainTab" />
        </Tabs>
        {/* render finance data according to tab value */}
        {tab === 0 && (
          <TabContainer>
            <CreditsList openEdit={this.openCreditEdit}></CreditsList>
          </TabContainer>
        )}
        {tab === 1 && (
          <TabContainer>
            <DuesList />
          </TabContainer>
        )}
        {tab === 2 && (
          <TabContainer>
            <PaymentsList />
          </TabContainer>
        )}
        {tab === 3 && (
          <TabContainer>
            <RefundsList />
          </TabContainer>
        )}
        {tab === 4 && (
          <TabContainer>
            <OwnerSettlements />
          </TabContainer>
        )}
        {tab === 5 && (
          <TabContainer>
            <DriverSettlement />
          </TabContainer>
        )}
        <UpdateCreditDialog
          open={this.state.showCreditEdit}
          handleClose={this.closeCreditEdit}
          current_max={this.state.currentEditCreditMax}
          uid={this.state.currentCreditEdit}
          save={this.saveCredit}
        />
      </div>
    );
  }
}
export default CashManagement;
