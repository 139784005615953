import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow
} from "@devexpress/dx-react-grid-material-ui";
// currently not used
class EagleEyeList extends Component {
  render() {
    return (
      <Grid
        rows={[
          {
            id: 0,
            licensePlate: "AB 123 C 43",
            owner: "Al-Arabi",
            driver: "Arabi II",
            currentLocation: "14.3434,44.4435"
          },
          {
            id: 1,
            licensePlate: "AB 123 C 43",
            owner: "Al-Arabi",
            driver: "Arabi II",
            currentLocation: "14.3434,44.4435"
          },
          {
            id: 2,
            licensePlate: "AB 123 C 43",
            owner: "Al-Arabi",
            driver: "Arabi II",
            currentLocation: "14.3434,44.4435"
          },
          {
            id: 3,
            licensePlate: "AB 123 C 43",
            owner: "Al-Arabi",
            driver: "Arabi II",
            currentLocation: "14.3434,44.4435"
          },
          {
            id: 4,
            licensePlate: "AB 123 C 43",
            owner: "Al-Arabi",
            driver: "Arabi II",
            currentLocation: "14.3434,44.4435"
          },
          {
            id: 5,
            licensePlate: "AB 123 C 43",
            owner: "Al-Arabi",
            driver: "Arabi II",
            currentLocation: "14.3434,44.4435"
          }
        ]}
        columns={[
          { name: "id", title: "Trailer ID" },
          { name: "licensePlate", title: "License Plate" },
          { name: "driver", title: "Driver" },
          { name: "owner", title: "Owner" },
          { name: "currentLocation", title: "Current Location" }
        ]}
      >
        <Table />
        <TableHeaderRow />
      </Grid>
    );
  }
}

export default EagleEyeList;
