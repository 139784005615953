import React from "react";
// to show warning popup on reject document
// props: [title, statusMessage, confirmButtonText, cancelButtonText, updateStatusMessage, onClose, onClose]
const DocumentRejectDialog = props => {
  return (
    <div
      style={{
        height: "400px",
        width: "340px",
        border: "5px solid rgb(255, 202, 53)",
        borderRadius: "20px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        position: "relative"
      }}
    >
      <div style={{ fontSize: "18px", fontWeight: "bold" }}>
        {props.title ? props.title : ""}
      </div>
      <div>
        <textarea
          className="DataTextarea"
          style={{
            resize: "none",
            height: "300px",
            width: "300px",
            margin: "10px 0px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            boxSizing: "border-box",
            padding: "8px"
          }}
          placeholder={"Reason for rejection"}
          value={props.statusMessage}
          onChange={e => {
            if (props.updateStatusMessage) {
              props.updateStatusMessage(e.target.value);
            }
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          right: "0px",
          justifyContent: "flex-end"
        }}
      >
        {" "}
        <div
          onClick={() => {
            props.onCancel && props.onCancel();
            props.onClose && props.onClose();
          }}
          style={{
            height: "35px",
            width: "90px",
            backgroundColor: "rgb(179, 40, 33)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "5px",
            color: "#fff",
            borderRadius: "40px",
            cursor: "pointer"
          }}
        >
          {props.cancelButtonText ? props.cancelButtonText : "Cancel"}
        </div>
        <div
          onClick={() => {
            props.onConfirm && props.onConfirm();
            props.onClose && props.onClose();
          }}
          style={{
            height: "35px",
            width: "90px",
            backgroundColor: "rgb(231, 158, 49)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "5px",
            color: "#fff",
            borderRadius: "40px",
            cursor: "pointer"
          }}
        >
          {props.confirmButtonText ? props.confirmButtonText : "Confirm"}
        </div>
      </div>
    </div>
  );
};

export default DocumentRejectDialog;
