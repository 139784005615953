import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "react-select";
// import "./style.css";
import { GetCityList, NewRoute, UpdateRoute, SetRouteFare } from "logic";
import { TableActionButton } from "Components/ActionButtons";
import { SelectStyle } from "componentstyle";
const initialState = {
  name: null,
  origin: null,
  destination: null,
  cost: null,
  ollys_cut: null,
  drivers_cut: null,
  owners_cut: null,
  tax: null,
  insurance: null,
  cities: []
};
// popup appears on click "Add new Route"/"Edit Details"/"Edit Fare" button in Routes screen
class NewRouteDialog extends Component {
  constructor(props) {
    super(props)
    this.state = initialState;
  }
  // state = {
  //   name: null,
  //   origin: null,
  //   destination: null,
  //   cost: null,
  //   ollys_cut: null,
  //   drivers_cut: null,
  //   owners_cut: null,
  //   tax: null,
  //   insurance: null,
  //   cities: []
  // };
  // function which make api call to get cities data
  GetCitiesOptions = async () => {
    let response = await GetCityList();
    // map through response array and return object with label and value keys
    let options = response.data.map(item => {
      return { label: item.name, value: item.id };
    });
    console.log(options);
    this.setState({ cities: options });
  };
  // execute on click create button for create new route
  // make api call to create new city
  CreateRoute = async () => {
    let response = await NewRoute({
      name: this.state.name,
      origin: this.state.origin.value,
      destination: this.state.destination.value,
      cost: this.state.cost,
      ollys_cut: this.state.ollys_cut,
      drivers_cut: this.state.drivers_cut,
      owners_cut: this.state.owners_cut,
      tax: this.state.tax,
      insurance: this.state.insurance
    });
    if (response) {
      window.location.reload();
    }
  };
  // execute on click save button for fare edit
  // make api call to save the changes made
  SaveFareEdit = async () => {
    let response = await SetRouteFare(this.props.uid, {
      cost: this.state.cost,
      ollys_cut: this.state.ollys_cut,
      drivers_cut: this.state.drivers_cut,
      owners_cut: this.state.owners_cut,
      tax: this.state.tax,
      insurance: this.state.insurance
    });
    if (response) {
      window.location.reload();
    }
  };
  // execute on click save button for details edit
  // make api call to save the changes made
  SaveDetailsEdit = async () => {
    let response = await UpdateRoute(this.props.uid, {
      name: this.state.name,
      origin: this.state.origin.value,
      destination: this.state.destination.value
    });
    if (response) {
      window.location.reload();
    }
  };
  // reset state to initial value
  resetState = () => {
    this.setState(initialState);
  }

  // executes before component render
  // call the function which make api call to get cities data
  componentDidMount() {
    this.GetCitiesOptions();
  }
  componentWillReceiveProps = props => {
    if (props.data) {
      this.setState(props.data);
    }
  };

  // reset state if modal opened is to add route
  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate:::::",this.props, prevProps);
    if(this.props.edit !== prevProps.edit && !this.props.edit) {
      console.log("states reseted");
      this.resetState()
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              {this.props.edit
                ? this.props.edit === "fare"
                  ? "Edit Fare"
                  : this.props.edit === "details"
                  ? "Edit Details"
                  : "Error"
                : "New Route"}
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            <div style={{}}>
              {this.props.edit != "fare" && (
                <React.Fragment>
                  <Select
                    classNamePrefix="Route-Select"
                    options={this.state.cities}
                    placeholder={"Origin"}
                    styles={SelectStyle}
                    onChange={opt => {
                      this.setState({ origin: opt });
                    }}
                    value={this.state.origin}
                  />
                  <Select
                    classNamePrefix="Route-Select"
                    options={this.state.cities}
                    onChange={opt => {
                      this.setState({ destination: opt });
                    }}
                    placeholder={"Destination"}
                    styles={SelectStyle}
                    value={this.state.destination}
                  />

                  <p
                    style={{
                      marginBottom: "0px",
                      marginLeft: "5px",
                      color: "#999"
                    }}
                  >
                    Route Name (Optional):
                  </p>
                  <input
                    className="DataInput"
                    type="text"
                    style={{
                      width: "200px",
                      border: "1px solid #ddd",
                      //   marginLeft: "5px",
                      padding: "0px 10px",
                      width: "100%",
                      boxSizing: "border-box",
                      margin: "5px"
                    }}
                    placeholder="Name"
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    value={this.state.name}
                  />
                </React.Fragment>
              )}
            </div>
            {this.props.edit != "details" && (
              <React.Fragment>
                {" "}
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px",
                    color: "#999"
                  }}
                >
                  Route Cost:
                </p>
                <input
                  className="DataInput"
                  type="number"
                  style={{
                    width: "200px",
                    border: "1px solid #ddd",
                    //   marginLeft: "5px",
                    padding: "0px 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "5px"
                  }}
                  placeholder="Cost"
                  onChange={e => {
                    this.setState({ cost: e.target.value });
                  }}
                  value={this.state.cost}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ marginRight: "2px" }}>
                    <p
                      style={{
                        marginBottom: "0px",
                        marginLeft: "5px",
                        color: "#999"
                      }}
                    >
                      Ollys %:
                    </p>
                    <input
                      className="DataInput"
                      type="number"
                      style={{
                        width: "200px",
                        border: "1px solid #ddd",
                        //   marginLeft: "5px",
                        padding: "0px 10px",
                        width: "100%",
                        boxSizing: "border-box",
                        margin: "5px"
                      }}
                      placeholder="Olly's %"
                      onChange={e => {
                        this.setState({ ollys_cut: e.target.value });
                      }}
                      value={this.state.ollys_cut}
                    />
                  </div>
                  <div>
                    {" "}
                    <p
                      style={{
                        marginBottom: "0px",
                        marginLeft: "5px",
                        color: "#999"
                      }}
                    >
                      Owners's %:
                    </p>
                    <input
                      className="DataInput"
                      type="number"
                      style={{
                        width: "200px",
                        border: "1px solid #ddd",
                        //   marginLeft: "5px",
                        padding: "0px 10px",
                        width: "100%",
                        boxSizing: "border-box",
                        margin: "5px"
                      }}
                      placeholder="Owner's %"
                      onChange={e => {
                        this.setState({ owners_cut: e.target.value });
                      }}
                      value={this.state.owners_cut}
                    />
                  </div>
                  <div style={{ marginLeft: "2px" }}>
                    <p
                      style={{
                        marginBottom: "0px",
                        marginLeft: "5px",
                        color: "#999"
                      }}
                    >
                      Drivers's %:
                    </p>
                    <input
                      className="DataInput"
                      type="number"
                      style={{
                        width: "200px",
                        border: "1px solid #ddd",
                        //   marginLeft: "5px",
                        padding: "0px 10px",
                        width: "100%",
                        boxSizing: "border-box",
                        margin: "5px"
                      }}
                      placeholder="Driver's %"
                      onChange={e => {
                        this.setState({ drivers_cut: e.target.value });
                      }}
                      value={this.state.drivers_cut}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        marginBottom: "0px",
                        marginLeft: "5px",
                        color: "#999"
                      }}
                    >
                      Tax %
                    </p>
                    <input
                      className="DataInput"
                      type="number"
                      style={{
                        width: "200px",
                        border: "1px solid #ddd",
                        //   marginLeft: "5px",
                        padding: "0px 10px",
                        width: "100%",
                        boxSizing: "border-box",
                        margin: "5px"
                      }}
                      placeholder="Tax %"
                      onChange={e => {
                        this.setState({ tax: e.target.value });
                      }}
                      value={this.state.tax}
                    />
                  </div>

                  <div style={{ marginLeft: "2px", width: "100%" }}>
                    <p
                      style={{
                        marginBottom: "0px",
                        marginLeft: "5px",
                        color: "#999"
                      }}
                    >
                      Insurance Cost:
                    </p>
                    <input
                      className="DataInput"
                      type="number"
                      style={{
                        width: "200px",
                        border: "1px solid #ddd",
                        //   marginLeft: "5px",
                        padding: "0px 10px",
                        width: "100%",
                        boxSizing: "border-box",
                        margin: "5px"
                      }}
                      placeholder="Insurance"
                      onChange={e => {
                        this.setState({ insurance: e.target.value });
                      }}
                      value={this.state.insurance}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                onClick={this.props.handleClose}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={e => {
                  if (!this.props.edit) {
                    this.CreateRoute();
                  } else if (this.props.edit === "details") {
                    this.SaveDetailsEdit();
                  } else if (this.props.edit === "fare") {
                    this.SaveFareEdit();
                  } else {
                    alert("Error");
                  }
                }}
              >
                {this.props.edit ? "Save" : "Create"}
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default NewRouteDialog;
