import React, { Component } from "react";
import "./style.css";
import { GetDocuments, VerifyDocument, RejectDocument } from "logic.js";
import ViewDocumentDialog from "../../Components/ViewDocumentDialog";
import DocumentRejectDialog from "../../Components/DocumentRejectDialog";
import { confirmAlert } from "react-confirm-alert";

// document listing section in driver, owner, trailer , user document sections
class DocumentListing extends Component {
  state = { docs: [], message: null, type: null };
  componentDidMount() {
    this.Fetch();
    this.setState({type: this.props.type})
  }
  componentDidUpdate() {
    if (this.props.updateAvailable) {
      this.Fetch();
    }
  }
  // function make api call to get documents data and set to docs state
  async Fetch() {
    let data = await GetDocuments(this.props.type, this.props.uid);
    this.setState({ docs: data });
  }
  // to set document reject message
  updateStatusMessage = message => {
    this.setState({ message: message });
  };
  // function make api call to verify document
  verifyDocument = async id => {
    let response = await VerifyDocument(id);
    this.Fetch();
  };
  // function make api call to reject document
  rejectDocument = async (id, message) => {
    let response = await RejectDocument(id, message);
    this.props.fetchProfile()
    this.Fetch();
  };
  // executes on click reject button corresponding to document
  // open popup to enter reject message and confirm
  verifyReject = id => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DocumentRejectDialog
            title={"Are you sure?"}
            updateStatusMessage={this.updateStatusMessage}
            statusMessage={this.state.statusMessage}
            onConfirm={() => {
              this.rejectDocument(id, this.state.message);
            }}
            onClose={onClose}
          />
        );
      }
    });
  };
  // executes on click view button corresponding to document
  // open popup to which displays document
  viewDocument = (url, showButtons, id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ViewDocumentDialog
            type={this.state.type}
            cancelButtonText="Close"
            url={url}
            showActionButtons={showButtons}
            onConfirm={() => {
              this.verifyDocument(id);
            }}
            onClose={onClose}
          />
        );
      }
    });
  };
  render() {
    return (
      <div style={{ ...this.props.style, maxWidth: "450px" }}>
        {this.state.docs && this.state.docs.map(item => {
          // console.log(item.pages.length, "page length");
          return (
            <div
              className="RowCenter DocumentListingItem"
              style={{ justifyContent: "space-between", padding: "0px 20px" }}
            >
              <div className="RowCenter DocumentListingDataWrapper">
                <p>
                  <span className="DocumentListingLabel">Type:</span>
                  {item.type}
                </p>
                <p>
                  {" "}
                  <span className="DocumentListingLabel">Page:</span>
                  {item.page ? item.page : "Unknown"}
                </p>
                {/* {this.props.type !== "user" && */}
                <p>
                  {" "}
                  <span className={`DocumentListingLabel`}>Status:</span>
                  {
                    <span
                      className={`${item.verified == "verified" &&
                        "DocumentListingStatusVerified"}`}
                    >
                      {item.status ? item.status.toUpperCase() : "Not Uploaded"}
                    </span>
                  }
                </p>
                {/* } */}
              </div>
              {item.status != "not_uploaded" && (
                <div className="RowCenter DocumentListingActionWrapper">
                  {/* <a href={`${item.url}`}> */}
                  <div
                    onClick={() => {
                      let showButtons =
                        item.status != "accepted" && item.status != "rejected"
                          ? true
                          : false;
                      console.log("Show Buttons", showButtons);
                      this.viewDocument(item.url, showButtons, item.id);
                    }}
                  >
                    View
                  </div>
                  {/* </a> */}
                  {this.props.type !== "user" && item.status != "accepted" && (
                    <div
                      onClick={() => {
                        // console.log(item);
                        if(item.status === "rejected") return
                        this.verifyReject(item.id);
                      }}
                      className={`${item.status === "rejected" && "btn-disabled"}`}
                    >
                      {item.status === "rejected" ? "Rejected" : "Reject"}
                    </div>
                  )}
                  {this.state.type !== "user" && item.status != "accepted" && item.status != "rejected" && (
                    <div
                      onClick={() => {
                        // console.log(item);
                        this.verifyDocument(item.id);
                      }}
                    >
                      Verify
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
export default DocumentListing;
