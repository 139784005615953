import React, { Component } from "react";
import Tabb from "@material-ui/core/Tabs";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { EagleEyeMap, TrailerOverviewOverlay, EagleEyeList } from "Sections";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { GetOverview } from "logic";
import Loader from "Components/Loader";
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};
const Tabs = withStyles({ indicator: { border: "1px solid #999" } })(Tabb);
TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};
class EagleEye extends Component {
  state = {
    tab: this.props.match.params.tab ? Number(this.props.match.params.tab) : 0,
    data: null
  };

  handleChange = (event, value) => {
    this.setState({ tab: value }, () => {
      if (this.state.tab == 0) {
        // window.location.reload();
      }
    });
    // window.location.reload();
  };

  // function which make api call to get admin overview data [active_journey_count, booking_count, journeys, total_cargo_weight]
  getData = async () => {
    let response = await GetOverview();
    console.log(response);
    this.setState({ data: response });
  };

  // executes before component render
  componentDidMount() {
    this.getData();
  }

  // to render eagle eye component
  render() {
    console.log(this.props.match.params.tab);
    const { tab } = this.state;
    if (!this.state.data) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    return (
      <div>
        {/* <Tabs
          value={tab}
          onChange={this.handleChange}
          centered
          style={{ width: "100%" }}
        >
          <Tab label="Map View" className="MainTab" />

          <Tab label="ListView" className="MainTab" />
        </Tabs> */}
        {tab === 0 && (
          <TabContainer>
            <div>
              <EagleEyeMap
                tab={this.state.tab}
                data={this.state.data.journeys}
              />
            </div>
            {/* <TrailerOverviewOverlay data={this.state.data} /> */}
          </TabContainer>
        )}
        {/* {tab === 1 && (
          <TabContainer>
            <EagleEyeList />
          </TabContainer>
        )} */}
      </div>
    );
  }
}
export default EagleEye;
