import React, { Component } from "react";
import "./login.css";
import { Button } from "react-materialize";
import InputBox from "Components/InputBox";
import SquareLogo from "Components/SquareLogo";

import { NotificationManager } from "react-notifications";
import { API_URL, Colors } from "Constants.js";
import Logic from "./logic";
var AuthErrorDisplayed = false;
class Login extends Component {
  state = {
    username: null,
    password: null,
  };
  // updateusername;
  render() {
    return (
      <div
        className="RowCenter"
        style={{
          minHeight: "100vh",
          width: "100%",
          backgroundColor: Colors.background,
        }}
      >
        <div className="ColumnCenter">
          <SquareLogo
            style={{
              // marginBottom: "50px",
              // border: "3px solid #ddd",
              // borderRadius: "100%",
              backgroundColor: "none",
            }}
          />
          {/* <img
            src={require("Assets/Logos/logoText.png")}
            style={{ height: "100px" }}
            alt="logo text"
          /> */}

          <InputBox
            placeholder="Phone Number"
            className="LoginScreenInput LoginScreenEmailInput"
            value={this.state.username}
            onChange={(data) => {
              this.setState({ username: data });
            }}
            style={{
              marginTop: "25px",
              marginBottom: "0px",
              backgroundColor: "#262c40",
            }}
          />
          <InputBox
            placeholder="Password"
            type={"password"}
            hideEye={true}
            className="LoginScreenInput LoginScreenPasswordInput"
            value={this.state.password}
            onChange={(data) => {
              this.setState({ password: data });
            }}
            style={{ marginTop: "25px", backgroundColor: "#262c40" }}
            onKeyPress={(e) => {
              if (e.key == "Enter") {
                (async () => {
                  console.log("buttonClicked");
                  let login = await Logic.login(
                    this.state.username,
                    this.state.password
                  );
                  console.log(login);
                  if (login) {
                    window.location.assign("/dashboard");
                  } else {
                    if (!AuthErrorDisplayed) {
                      AuthErrorDisplayed = true;
                      window.setTimeout(() => {
                        AuthErrorDisplayed = false;
                      }, 1400);
                      NotificationManager.error(
                        "Invalid Username Or Password",
                        "Authentication Error",
                        1000,
                        () => {}
                      );
                    }
                  }
                })();
              }
            }}
          />
          <div>
            <Button
              className="LoginButton"
              style={{
                marginTop: "10px",
                borderRadius: "0px",
                height: "55px",
                width: "339px",
                backgroundColor: Colors.primary,
              }}
              onClick={async () => {
                console.log("buttonClicked");
                // api passes username and password to authenticate
                let login = await Logic.login(
                  this.state.username,
                  this.state.password
                );
                console.log(login);
                if (login) {
                  window.location.assign("/dashboard");
                } else {
                  if (!AuthErrorDisplayed) {
                    AuthErrorDisplayed = true;
                    window.setTimeout(() => {
                      AuthErrorDisplayed = false;
                    }, 1400);
                    NotificationManager.error(
                      "Invalid Username Or Password",
                      "Authentication Error",
                      1000,
                      () => {}
                    );
                  }
                }
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
