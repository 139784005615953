import React, { Component } from "react";
import "./driveraccountactions.css";
// currently not used
class DriverAccountActions extends Component {
  render() {
    return (
      <div className="RowCenter">
        <div className="DriverAccountActionVerify DriverAccountActionButton">
          Verify Account
        </div>
        <div className="DriverAccountActionDisable DriverAccountActionButton">
          Disable Account
        </div>
      </div>
    );
  }
}
export default DriverAccountActions;
