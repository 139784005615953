import React, { Component } from "react";
import "./style.css";
class CargoPhotos extends Component {
  render() {
    let photos = this.props.data.photos;
    console.log(photos);
    return (
      <div>
        <div className="CargoPhotosHeader">
          <p>Photos</p>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", padding: "0px 20px" }}>
          {photos.map(item => {
            return <Photo source={item.url} />;
          })}

          {photos.length < 1 && (
            <div style={{ width: "100%" }}>
              <p>No Photos Available</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default CargoPhotos;

const Photo = props => {
  return (
    <div
      style={{
        height: "100px",
        width: "100px",
        margin: "8px",
        boxSizing: "border-box"
      }}
    >
      <img
        src={props.source}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover"
          //   borderRadius: "20px"
        }}
      />
    </div>
  );
};
