import React, { Component } from "react";
import get12HrTimeString, { timestampFormatter } from "../../Commons";
// journey overview section in journey details screen
class JourneyOverview extends Component {
  render() {
    let item = this.props.data;
    console.log("mmm", item);
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "0px 5px",
            boxSizing: "border-box",
            backgroundColor: "#353b50",
            margin: "0px 0px",
            padding: "8px"
          }}
        >
          <div style={{ width: "100%", marginRight: "5px" }}>
            <div className="BookingOverviewPrimaryList">
              <div>ID</div>
              <div>{item.id}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Scheduled Date</div>
              <div>{new Date(item.start_date).toDateString()}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Scheduled Pickup Time</div>
              <div>{get12HrTimeString(new Date(item.start_date))}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Assigned Trailer</div>
              <div>
                {item.trailer ? item.trailer.reg_no : "-"}{" "}
                <a href={`/dashboard/trailer/${item.trailerId}`}>
                  <div
                    style={{
                      height: "30px",
                      width: "80px",
                      borderRadius: "0px",
                      backgroundColor: "#4caf50",
                      marginLeft: "25px",
                      color: "#fff",
                      display: "flex",
                      fontSize: "12px",
                      fontWeight: "bolder",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Montserrat"
                    }}
                  >
                    Go To Trailer
                  </div>
                </a>
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Assigned Driver</div>
              <div>
                {item.driver
                  ? `${item.driver.first_name || ""} ${item.driver
                      .middle_name || ""} ${item.driver.last_name || ""}`
                  : "-"}
                {item.driverId && (
                  <a href={`/dashboard/driver/${item.driverId}`}>
                    <div
                      style={{
                        height: "30px",
                        width: "120px",
                        borderRadius: "0px",
                        backgroundColor: "#4caf50",
                        marginLeft: "25px",
                        color: "#fff",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Montserrat"
                      }}
                    >
                      View Driver Profile
                    </div>
                  </a>
                )}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>status</div>
              <div>
                {item.status &&
                  item.status.split("_").map((item, index, arr) => {
                    return item.toUpperCase() + " ";
                  })}
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className="BookingOverviewPrimaryList">
              <div>City Scope</div>
              <div style={{ textTransform: "capitalize" }}>
                {item.bookingDetails ? item.bookingDetails.city_scope : "-"}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Returning Trailer ? </div>
              <div>{item.type == "returning" ? "Yes" : "No"}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Delayed</div>
              <div>{item.delay ? "Yes" : "No"}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Driver Status</div>
              <div>{item.driver_resting ? "Resting" : "On Duty"}</div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginLeft: "2px",
            backgroundColor: "#353b50",
            padding: "8px"
          }}
        >
          <h4
            style={{
              textAlign: "left",
              // textDecoration: "underline",
              color: "#f3f3f3",
              marginTop: "15px",
              marginLeft: "2px"
            }}
          >
            Booking Details
          </h4>
          <div
            style={{ width: "100%", marginRight: "5px" }}
            className="bookingSectionJourney"
          >
            <div className="BookingOverviewPrimaryList">
              <div>ID</div>
              <div>
                {item.bookingDetails.bookingId}
                {item.bookingDetails.id && (
                  <a href={`/dashboard/booking/${item.bookingDetails.id}`}>
                    <div
                      style={{
                        height: "30px",
                        width: "120px",
                        borderRadius: "0px",
                        backgroundColor: "#4caf50",
                        marginLeft: "25px",
                        color: "#fff",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Montserrat"
                      }}
                    >
                      View Booking Page
                    </div>
                  </a>
                )}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Booking timestamp</div>
              <div>
                {timestampFormatter(item.bookingDetails.booking_date)}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Pickup Location</div>
              <div>
                {item.bookingDetails
                  ? item.bookingDetails.pickup_location_name
                  : "-"}{" "}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Drop Location</div>
              <div>
                {item.bookingDetails
                  ? item.bookingDetails.drop_location_name
                  : "-"}{" "}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>status</div>
              <div>
                {item.status &&
                  item.status.split("_").map((item, index, arr) => {
                    return item.toUpperCase() + " ";
                  })}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginLeft: "2px",
            color: "#f3f3f3",
            backgroundColor: "#353b50",
            minHeight: "150px",
            padding: "8px"
          }}
        >
          <h4
            style={{
              textAlign: "left"
              // textDecoration: "underline"
            }}
          >
            Event Log
          </h4>
          <div
            style={{ width: "100%", marginRight: "5px" }}
            className="bookingSectionJourney"
          >
            {item.events.length == 0 && <div>No Event Data</div>}
            {item.events.map(event => {
              return (
                <div className="BookingOverviewPrimaryList BookingOverviewFullLength">
                  <div>
                    {"Time : " + new Date(event.occured_at).toLocaleString()}
                  </div>
                  <div>{event.event}</div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default JourneyOverview;
