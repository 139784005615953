import React, { Component } from "react";
import "./style.css";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ReactSelect from "react-select";
import { SelectStyle } from "componentstyle";
import { SendGenericNotification } from "logic";
const KeyValuePair = props => {
  return (
    <div className="NotificationKeyValuePairWrapper">
      <input
        type="text"
        className="NotificationKeyValuePairInput DataInput"
        value={props.data.key}
        onChange={e => {
          props.onKeyChange(props.data.index, e.target.value);
        }}
      />
      <p>:</p>
      <input
        type="text"
        className="NotificationKeyValuePairInput DataInput"
        value={props.data.value}
        onChange={e => {
          props.onValueChange(props.data.index, e.target.value);
        }}
      />
      <div style={{ position: "absolute", right: "-60px" }}>
        {props.showAddButton && (
          <img
            src={require("Assets/Icons/add.svg")}
            style={{
              height: "25px",
              width: "25px",
              margin: "4px"
            }}
            onClick={e => {
              props.addNewPair();
            }}
            alt=""
          />
        )}
        {props.data.index > 0 && (
          <img
            src={require("Assets/Icons/remove.svg")}
            style={{
              height: "25px",
              width: "25px",
              margin: "4px"
            }}
            onClick={e => {
              props.removePair(props.data.index);
            }}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

// component for send notification screen
class Notification extends Component {
  state = {
    notificationType: " ",
    pushTargets: [],
    KeyValuePairs: [{ key: "", value: "" }],
    title: null,
    body: null,
    image: null
  };

  // to handle selected item change in notification type
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  //not used
  readURL = input => {
    const setImage = image => {
      this.setState({ image: image });
    };
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        document.getElementById("notification-image").src = e.target.result;
        setImage(e.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }
  };
  // to handle changes in text in key field
  onKeyChange = (index, key) => {
    this.state.KeyValuePairs[index].key = key;
    this.setState({});
  };
  // to handle changes in text in value field
  onValueChange = (index, value) => {
    this.state.KeyValuePairs[index].value = value;
    this.setState({});
  };
  // handle click in add key value pair button
  addNewPair = () => {
    this.state.KeyValuePairs.push({ key: "", value: "" });
    this.setState({});
  };
  // handle click in remove key value pair button
  removePair = index => {
    this.state.KeyValuePairs.splice(index, 1);
    this.setState({});
  };

  render() {
    const { notificationType } = this.state;
    console.log("notificationType", notificationType);
    // console.log(this.state.KeyValuePairs);
    return (
      <div
        className="ColumnCenter"
        style={{
          minHeight: "100vh",
          width: "100%",
          // paddingTop: "50px",
          backgroundColor: "#353b50",
          position: "absolute",
          top: "64px"
        }}
      >
        <div className="NotificationInputWrapper">
          <div className="NotificationImageInput">
            {/* <img
              src="//unsplash.it/200/210"
              id="notification-image"
              style={{ height: "200px", width: "200px", objectFit: "cover" }}
              alt=""
            />
            <div class="upload-btn-wrapper">
              <button class="btn">Upload Image</button>
              <input
                className="DataInput"
                type="file"
                name="notificationImage"
                onChange={e => {
                  this.readURL(e.target);
                }}
              />
            </div> */}
          </div>
          <div className="NotificationTextInput">
            {/* <input type="file" name="notificationImage" /> */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row"
              }}
              className="Notification NotificationTypeSelectWrapper"
            />
            <Select
              value={this.state.notificationType}
              onChange={this.handleChange}
              input={
                <OutlinedInput
                  labelWidth={this.state.labelWidth}
                  name="notificationType"
                  id="notification-type-select"
                />
              }
              style={{
                margin: "5px",
                // borderRadius: "20px",
                backgroundColor: "#262c40",
                color: "#f3f3f3"
              }}
              placeholder="Notification type"
            >
              <MenuItem value=" ">
                <em>Notification Type</em>
              </MenuItem>
              <MenuItem value={0}>Push Notification</MenuItem>
              <MenuItem value={1}>Splash Notification</MenuItem>
            </Select>
            <input
              type="text"
              placeholder="Notification Title"
              className="DataInput"
              onChange={e => {
                this.setState({ title: e.target.value });
              }}
            />
            <textarea
              placeholder="Notification Text"
              className="DataTextarea"
              onChange={e => {
                this.setState({ body: e.target.value });
              }}
            />
          </div>
          <div className="NotificationTargetWrapper">
            <p style={{ color: "#fff" }}>Select Notification Target(s)</p>
            <ReactSelect
              isMulti
              options={[
                { value: "drivers", label: "All Drivers" },
                { value: "owners", label: "All Owners" },
                { value: "users", label: "All Users" },
                { value: "everyone", label: "Everyone" }
              ]}
              styles={SelectStyle}
              onChange={e => {
                let targets = [];
                for (let target of e) {
                  targets.push(target.value);
                }
                this.setState({ pushTargets: targets });
              }}
            />
            <div>
              <p style={{ color: "#fff" }}>Add Key Value Pairs</p>
              {this.state.KeyValuePairs.map((item, index, arr) => {
                return (
                  <div style={{}}>
                    {" "}
                    <KeyValuePair
                      data={{ key: item.key, value: item.value, index: index }}
                      onKeyChange={this.onKeyChange}
                      onValueChange={this.onValueChange}
                      showAddButton={index == arr.length - 1 ? true : false}
                      addNewPair={this.addNewPair}
                      removePair={this.removePair}
                    />
                  </div>
                );
              })}
              {/* <div className="NotificationAddNewPairWrapper">
                <div
                  className="NotificationAddNewPairButton ColumnCenter"
                  onClick={e => {
                    this.state.KeyValuePairs.push({ key: "", value: "" });
                    this.setState({});
                  }}
                >
                  Add New Pair
                </div>
              </div> */}
              <div className="NotificationSendWrapper">
                <div
                  className="NotificationSendButton ColumnCenter"
                  onClick={async () => {
                    let state = this.state;
                    let data = {};
                    for (let pair of state.KeyValuePairs) {
                      data[pair.key] = pair.value;
                    }
                    console.log({
                      image: state.image,
                      targets: state.pushTargets,
                      title: state.title,
                      body: state.body,
                      data: {
                        type:
                          state.notificationType == 0 ||
                          state.notificationType == "0"
                            ? "push"
                            : "splash",
                        ...data
                      }
                    });
                    let response = await SendGenericNotification({
                      targets: state.pushTargets,
                      title: state.title,
                      body: state.body,
                      data: {
                        ...data
                      }
                    }).catch(error => {
                      alert(JSON.stringify(error));
                    });
                    if (response) {
                      alert("Notification Sent!");
                    }
                  }}
                >
                  Send Notification
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Notification;
