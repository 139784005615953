import React, { Component } from "react";
import ChatListItem from "Components/ChatListItem";
import SearchBox from "Components/SearchBox";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { GetChatList } from "logic";
import { MessageThread } from "Sections";
import { Colors } from "Constants";

// component for messages recieved screen
class Messages extends Component {
  state = { senderID: 0, chatList: [], selected: null, searchTerm: null };

  // executes before component render
  componentDidMount() {
    (async () => {
      // api call to list previous chats
      let chats = await GetChatList();
      if (chats) {
        let chatList = chats.data.data.map(item => {
          return {
            senderName: item.name,
            notificationCount: item.ncount,
            time: item.time,
            sender: item.sender
          };
        });
        this.setState({ chatList: chatList });
      }
    })();
  }

  // to set selected sender
  setSelected = sender => {
    if (this.state.selected !== sender) {
      this.setState({ selected: sender });
    }
  };

  // to set search text
  setSearchTerm = query => {
    this.setState({ searchTerm: query });
  };

  // to render messeges received component
  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: Colors.body,
          minHeight: "92vh",
          position: "absolute",
          top: "64px"
        }}
      >
        <div
          style={{
            flex: 40,
            minHeight: "90vh",
            borderRight: "1px solid rgb(220, 220, 220)"
          }}
        >
          <SearchBox
            style={{ height: "47px", width: "246px", paddingLeft: "8px" }}
            rootStyle={{ margin: "21px 41px" }}
            setQuery={this.setSearchTerm}
          />
          {this.state.chatList.map(item => {
            let regexString = `${this.state.searchTerm}`;
            let regex = new RegExp(regexString, "gi");
            if (
              item.senderName.match(regex) ||
              this.state.searchTerm === null ||
              this.state.searchTerm === ""
            )
              return (
                <ChatListItem
                  data={item}
                  selected={this.state.selected === item.sender ? true : false}
                />
              );
          })}
        </div>

        {/* <Router>
          <Switch> */}
        {/* route if a chat is not selected */}
        <Route
          path="/dashboard/messages"
          exact={true}
          render={() => {
            return (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <p
                  style={{
                    color: "darkgray",
                    fontSize: "44px"
                    // fontWeight: "200"
                  }}
                >
                  Please Select A Chat
                </p>
              </div>
            );
          }}
        />
        {/* route if a chat is selected */}
        <Route
          path="/dashboard/messages/:uid"
          exact={true}
          render={props => (
            <MessageThread
              uid={props.match.params.uid}
              setSelected={this.setSelected}
            />
          )}
        />
        {/* </Switch> */}
        {/* </Router> */}
      </div>
    );
  }
}
export default Messages;
