import React, { Component } from "react";
import "./style.css";

// this component is to display cargo overview in cargo details screen
class CargoOverview extends Component {
  render() {
    let item = this.props.data;
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "0px 5px",
            boxSizing: "border-box"
          }}
        >
          <div style={{ width: "100%", marginRight: "5px" }}>
            <div className="CargoOverviewPrimaryList">
              <div>ID</div>
              <div>{item.id}</div>
            </div>
            <div className="CargoOverviewPrimaryList">
              <div>Category</div>
              <div>
                {item.cargoType ? item.cargoType.type_name : "Not Provided"}
              </div>
            </div>
            <div className="CargoOverviewPrimaryList">
              <div>Weight</div>
              <div>{item.weight}</div>
            </div>
            <div className="CargoOverviewPrimaryList">
              <div>Dimensions</div>
              <div>{item.dimensions}</div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className="CargoOverviewPrimaryList">
              <div>Content Description</div>
              <div>{item.content}</div>
            </div>
            <div className="CargoOverviewPrimaryList">
              <div>Quantity</div>
              <div>{item.quantity}</div>
            </div>
            <div className="CargoOverviewPrimaryList">
              <div>Booking</div>
              <div>
                {item.bookingId}{" "}
                <a href={`/dashboard/booking/${item.bookingId}`}>
                  <div
                    style={{
                      height: "30px",
                      width: "80px",
                      borderRadius: "0px",
                      backgroundColor: "#4caf50",
                      marginLeft: "25px",
                      color: "#fff",
                      display: "flex",
                      fontSize: "12px",
                      fontWeight: "bolder",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Montserrat"
                    }}
                  >
                    Booking Info
                  </div>
                </a>
              </div>
            </div>
            <div className="CargoOverviewPrimaryList">
              <div>Status</div>
              <div>{item.verified ? "Verified" : "Not Verified"}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default CargoOverview;
