import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "Components/Loader";
import InputBox from "Components/InputBox";
import Select from "react-select";
import { SelectStyle } from "componentstyle";
import "./style.css";
import {
  GetTrailerTypes,
  AssignTrailerToBooking,
  GetBookableTrailerListInCity,
  GetCityList,
} from "logic";
import { TRAILER_DISTANCE_RADIUS } from "../../Constants";

// to list owners of trailers which are near to the pickup location
const OwnerListItem = (props) => {
  const style = {
    header: {
      margin: "2px 0px",
      width: "300px",
      fontSize: "14px",
      color: "#454545",
    },
    headerContailer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "44px",
    },
    data: { margin: "2px 0px", width: "100%", fontSize: "16px" },
  };
  return (
    <div
      style={{
        margin: "8px",
        // padding: "8px 20px",
        boxSizing: "border-box",
        cursor: "pointer",
        width: "450px",
        height: "180px",
      }}
      className="AssignTrailerDialogOwnerContainer"
      onClick={props.onClick}
    >
      <div style={style.headerContailer}>
        <p style={style.header}>Company Name</p>
        <p style={style.data}>{props.data.company_name}</p>
      </div>
      <div style={style.headerContailer}>
        <p style={style.header}>Name</p>
        <p style={style.data}>{props.data.name}</p>
      </div>
      <div style={style.headerContailer}>
        <p style={style.header}>Phone</p>
        <p style={style.data}>{props.data.phone}</p>
      </div>
      <div style={style.headerContailer}>
        <p style={style.header}>Email</p>
        <p style={style.data}>{props.data.email}</p>
      </div>{" "}
    </div>
  );
};

// to list trailers which are near to the pickup location
const TrailerListItem = (props) => {
  const style = {
    header: {
      margin: "2px 0px",
      width: "100%",
      fontSize: "14px",
      color: "#454545",
      cursor: "pointer",
    },
    headerContailer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "44px",
      padding: "10px",
    },
    data: { margin: "2px 0px", width: "100%", fontSize: "18px" },
  };
  return (
    <div
      style={{
        margin: "8px",
        border: props.selected ? "1px solid #f95138" : "initial",
        // padding: "8px",
        boxSizing: "border-box",
        width: "250px",
        backgroundColor: props.selected ? "#f95138" : "#eee",
        color: props.selected ? "#f3f3f3" : "#000",
      }}
      className="AssignTrailerDialogTrailerContainer"
      onClick={props.onClick}
    >
      <div style={style.headerContailer}>
        <p style={style.data}>{props.data.reg_no}</p>
      </div>
    </div>
  );
};

// this popup displays when "Assign Trailer" button in booking details screen is clicked
class AssignTrailerDialog extends Component {
  state = {
    trailerId: null,
    datalist: [],
    ownerId: null,
    loading: false,
    isFetchingTrailers: false,
    trailerInRadius: {
      label: "75 km",
      value: 75 
    },
  };

  // to get different trailer options and set the options to state "trailerTypeOptions". this state is currently not used
  GetTrailerTypeOptions = async () => {
    let options = await GetTrailerTypes();
    if (options) {
      this.setState({ trailerTypeOptions: options });
    }
  };
  // to get different city options and set the options to state "cityTypeOptions". this state is currently not used
  GetCityOptions = async () => {
    let response = await GetCityList();

    let options = response.data.map((item) => {
      return { label: item.name, value: item.id };
    });
    console.log(options);
    if (options) {
      this.setState({ cityTypeOptions: options });
    }
  };
  GetTrailerOfTypeInCity = async (cityid) => {
    if (cityid) {
      this.setState({isFetchingTrailers: true})
      // api call to get trailers having status ready and near to the pickup location
      let trailers = await GetBookableTrailerListInCity(cityid, {
        radius: this.state.trailerInRadius.value,
        typeId: this.state.trailer_type,
      });
      this.setState({isFetchingTrailers: false})
      let ownerFilteredTrailerList = [];
      let checkOwnerInList = (owner) => {
        return owner.id === trailer.owner;
      };
      // to create owner data list array 
      if (trailers) {
        for (var trailer of trailers.data) {
          if (ownerFilteredTrailerList.some(checkOwnerInList)) {
            let ownerInList = ownerFilteredTrailerList.find(checkOwnerInList);
            ownerInList["trailers"].push(trailer);
          } else {
            let trailers = [trailer];
            ownerFilteredTrailerList.push({
              id: trailer.owner,
              name: `${trailer.ownerDetails.first_name || "-"} ${
                trailer.ownerDetails.last_name || "-"
              } `,
              email: trailer.ownerDetails ? trailer.ownerDetails.email : null,
              phone: trailer.ownerDetails ? trailer.ownerDetails.phone : null,
              company_name: trailer.ownerDetails
                ? trailer.ownerDetails.company_name
                : null,
              trailers: trailers,
            });
          }
        }
        this.setState({ datalist: ownerFilteredTrailerList });
      } else {
        this.setState({ datalist: [] });
      }
    }
  };
  // executes on click confirm button in the popup
  AssignTrailer = async () => {
  // validate trailer selection and send aler if no trailer is selected
    if (!this.state.trailerId) {
      alert("Please Select A Trailer");
      return 0;
    }
    this.setState({ loading: true });
    // make api call to assign trailer for booking
    let response = await AssignTrailerToBooking(this.props.uid, {
      trailerId: this.state.trailerId,
    });

    if (response) {
      this.setState({ loading: false });
      window.location.reload();
    }
  };
  donothing = async () => {};


  // executes before component render
  // call the function which make api call to get trailer type options
  componentDidMount() {
    this.GetTrailerTypeOptions();
    console.log("mount", this.props);
    // this.GetCityOptions();
    // if the component have props pickup_city make api call to get trailers near to the pickup location
    this.props.pickup_city &&
      this.GetTrailerOfTypeInCity(this.props.pickup_city);
  }

  componentDidUpdate(prevProps,prevState) {
    // to filter out all owners than alloted owner from dataList if there is an allotted owner  
    if (this.props.allotedOwner && prevState.datalist && prevState.datalist.length === 1 && this.state.datalist.length && prevState.datalist.id === this.state.datalist.id) return
    if (this.props.allotedOwner && this.state.datalist.length > 0) {
      let filteredDataList = this.state.datalist.filter(item => {
        return item.id === this.props.allotedOwner
      })
      this.setState({datalist: filteredDataList})
    }
  }

  componentDidUpdate(prevProps,prevState) {
    if(prevState.trailerInRadius.value !== this.state.trailerInRadius.value) {
      console.log('changed', this.state.trailerInRadius.value);
      this.props.pickup_city &&
      this.GetTrailerOfTypeInCity(this.props.pickup_city);
    }
  }

  render() {
    console.log(this.state.datalist);
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff",
              }}
            >
              Assign Trailer
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              backgroundColor: "#353b50",
              borderRadius: "0px",
              position: "relative",
              minHeight: "480px"
            }}
          >
            {this.state.ownerId === null && (
              <Select
                classNamePrefix="radius-select"
                className="radius-select"
                options={TRAILER_DISTANCE_RADIUS.map(item => ({label: item + " km", value: item}))}
                styles={SelectStyle}
                placeholder={
                  this.state.trailerInRadius
                    ? this.state.trailerInRadius.label
                    : "Choose radius"
                }
                onChange={radius => {
                  this.setState({trailerInRadius: radius})
                }}
              />
            )}
            {this.state.isFetchingTrailers ? <Loader color="#fff" height="400px" /> :
              <div style={{ minHeight: "400px" }}>
                {this.state.ownerId == null && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    {this.state.datalist.map((item) => {
                      return (
                        <OwnerListItem
                          data={item}
                          onClick={() => {
                            this.setState({
                              ownerId: item.id,
                              trailer_list: item.trailers,
                            });
                          }}
                        />
                      );
                    })}
                  </div>
                )}
                {this.state.ownerId && (
                  <div>
                    {this.state.trailer_list.map((item) => {
                      return (
                        <TrailerListItem
                          data={item}
                          selected={
                            this.state.trailerId == item.id ? true : false
                          }
                          onClick={() => {
                            this.setState({ trailerId: item.id });
                          }}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            }
            { !this.state.isFetchingTrailers && this.state.datalist && this.state.datalist.length === 0 && (
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  bottom: "0px",
                  left: "0px",
                  right: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "#f3f3f3aa" }}>
                  No Trailers Currently Available At This Location
                </p>
              </div>
            )}
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div
              onClick={this.props.handleClose}
              color="primary"
              className="RowCenter"
              style={{
                cursor: "pointer",
                width: "150px",
                height: "50px",
                // margin: "5px 4px",
                marginRight: "4px",
                backgroundColor: "#262c40",
                color: "#f3f3f3",
              }}
            >
              Close
            </div>
            {this.state.ownerId && (
              <div
                onClick={() => {
                  this.setState({ ownerId: null });
                }}
                color="primary"
                className="RowCenter"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ccc",
                  height: "50px",
                  color: "#454545",
                  width: "150px",
                  margin: "0px 0px",
                  marginRight: "4px",
                }}
              >
                Back
              </div>
            )}
            {this.state.datalist && this.state.datalist.length > 0 && (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f95138",
                  height: "50px",
                  color: "#fff",
                  width: "150px",
                  margin: "0px 0px",
                  cursor: "pointer",
                }}
                className="RowCenter"
                onClick={(e) => {
                  this.state.loading ? this.donothing() : this.AssignTrailer();
                }}
              >
                Confirm
              </div>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default AssignTrailerDialog;
