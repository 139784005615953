import React, { Component } from "react";
import RouteListItem from "Components/RouteListItem";
import { CreateRoutes } from "logic";
import "./style.css";
import { GetRoutes } from "../../logic";
// currently not using
class RouteListing extends Component {
  state = { activeIndex: null, routes: [] };
  setExpansionIndex = index => {
    this.setState({
      activeIndex: this.state.activeIndex == index ? null : index
    });
  };
  componentDidMount() {
    this.getRoutes();
  }
  getRoutes = async () => {
    let response = await GetRoutes();
    let routes = [];
    for (var route of response.data.data) {
      // console.log(route.route);
      route.route["origin"] = route.route.origin.name;
      route.route["destination"] = route.route.destination.name;
      route.route["stops"] = route.route.waypoints;
      route.route["stopCount"] = route.route.waypoints.length;

      routes.push(route.route);
    }
    this.setState({ routes: routes });
  };
  render() {
    return (
      <div>
        <div className="RouteListingTitle">Routes</div>
        <div
          style={{ width: "100%", border: "2px solid #275bb0" }}
          className="RouteListingContentWrapper"
        >
          {this.state.routes.map((item, index) => {
            return (
              <RouteListItem
                data={item}
                expanded={this.state.activeIndex == index ? true : false}
                setExpansionIndex={this.setExpansionIndex}
                index={index}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
export default RouteListing;
const data = [
  {
    origin: "Dammam",
    destination: "Riyadh",
    name: "Route One",
    stopCount: 5,
    stops: [
      {
        name: "Dammam",
        type: "Origin"
      },
      {
        name: "Cafe",
        type: "Oases"
      },
      {
        name: "Some Other Place",
        type: "Other"
      },
      {
        name: "Riyadh",
        type: "Destination"
      }
    ]
  },
  {
    origin: "Dammam",
    destination: "Riyadh",
    name: "Route One",
    stopCount: 5,
    stops: [
      {
        name: "Dammam",
        type: "Origin"
      },
      {
        name: "Cafe",
        type: "Oases"
      },
      {
        name: "Some Other Place",
        type: "Other"
      },
      {
        name: "Riyadh",
        type: "Destination"
      }
    ]
  },
  {
    origin: "Dammam",
    destination: "Riyadh",
    name: "Route One",
    stopCount: 5,
    stops: [
      {
        name: "Dammam",
        type: "Origin"
      },
      {
        name: "Cafe",
        type: "Oases"
      },
      {
        name: "Some Other Place",
        type: "Other"
      },
      {
        name: "Riyadh",
        type: "Destination"
      }
    ]
  }
];
