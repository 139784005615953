import React from "react";
// popup displayed on click emergency cancellation
// props: {marked, cancellation_code, cancelButtonText, confirmButtonText, onCancel, onClose}
const EmergencyCancellationDialog = (props) => {
  return (
    <div
      style={{
        height: "350px",
        width: "400px",
        border: "1px solid #353b50",
        // borderRadius: "20px",
        // padding: "10px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          height: "50px",
          backgroundColor: "#262c40",
          textAlign: "center",
          // textTransform: "uppercase",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   borderRadius: "20px 20px 0px 0px",
          color: "#fff",
        }}
      >
        {"Emergency Trip Cancellation"}
      </div>
      <div style={{ padding: "10px" }}>
        <p>
          Please contact the driver and provide the following code for
          confirming cancellation.
        </p>
        <div style={{ fontSize: "28px" }}>
          {props.marked === true ? props.cancellation_code : ""}
        </div>
        <p style={{ fontSize: "12px", color: "#999" }}>
          Once Cancelled the driver will be directed to move the cargo to the
          pickup point. This action cannot be undone.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          right: "0px",
          justifyContent: "flex-end",
        }}
      >
        {" "}
        <div
          onClick={() => {
            props.onCancel && props.onCancel();
            props.onClose && props.onClose();
          }}
          style={{
            height: "45px",
            width: "100%",
            backgroundColor: "#262c40",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // margin: "5px",
            color: "#fff",
            // borderRadius: "40px",
            cursor: "pointer",
          }}
        >
          {props.cancelButtonText ? props.cancelButtonText : "Close"}
        </div>
        {!props.marked && (
          <div
            onClick={() => {
              props.onConfirm && props.onConfirm();
              props.onClose && props.onClose();
            }}
            style={{
              height: "45px",
              width: "100%",
              backgroundColor: "#f95138",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // margin: "5px",
              color: "#fff",
              // borderRadius: "40px",
              cursor: "pointer",
            }}
          >
            {props.confirmButtonText ? props.confirmButtonText : "Confirm"}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmergencyCancellationDialog;
