import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { timestampFormatter } from "../../Commons";
import "./style.css";

// this component is to display booking overview in booking details screen
class BookingOverview extends Component {
  render() {
    let item = this.props.data;
    return (
      <div style={{ backgroundColor: "#353b50", padding: "20px 0px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "0px 5px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ width: "100%", marginRight: "5px", padding: "8px" }}>
            <div className="BookingOverviewPrimaryList">
              <div>ID</div>
              <div>{item.bookingId}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Booking Timestamp</div>
              <div>{ timestampFormatter(item.booking_date)}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Pickup Location</div>
              <div>
                {item.pickup_location_name
                  ? `${item.pickup_location_name.toString().substring(0, 60)} ${
                      item.pickup_location_name.length > 60 ? "..." : ""
                    }`
                  : "-"}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Drop Location</div>
              <div>
                {item.drop_location_name
                  ? `${item.drop_location_name.toString().substring(0, 60)} ${
                      item.drop_location_name.length > 60 ? "..." : ""
                    }`
                  : "-"}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Pickup Date And Time</div>
              <div>
                {timestampFormatter(item.pickup_datetime)}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Attended On</div>
              <div>
                {item.assignedAt ? timestampFormatter(item.assignedAt) : "-"}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Estimated Delivery Date</div>
              <div>
                {item.estimated_drop_date
                  ? new Date(item.estimated_drop_date).toDateString()
                  : "-"}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>status</div>
              <div>{item.status.replace(/_/g, " ").toUpperCase()}</div>
            </div>
          </div>
          <div style={{ width: "100%", padding: "8px" }}>
            <div className="BookingOverviewPrimaryList">
              <div>Type of Load</div>
              <div>{item.loadDetails ? item.loadDetails.title : '-'}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Weight of the Load</div>
              <div style={{textTransform: "initial"}}>{item.weight_of_load ? item.weight_of_load : '-' } {item.unit_of_load}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Type of Vehicle</div>
              <div>{item.vehicleDetails ? item.vehicleDetails.title : '-'}</div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>User</div>
              <div>
                {item.user ? item.user.first_name : "-"}{" "}
                <a href={`/dashboard/user/${item.userId}`}>
                  <div
                    style={{
                      height: "30px",
                      width: "80px",
                      borderRadius: "0px",
                      backgroundColor: "#4caf50",
                      marginLeft: "25px",
                      color: "#fff",
                      display: "flex",
                      fontSize: "12px",
                      fontWeight: "bolder",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Visit Profile
                  </div>
                </a>
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Owner</div>
              <div>
                {item.ownerDetails? `${item.ownerDetails.first_name || ""} ${item.ownerDetails.middle_name || ""} ${item.ownerDetails.last_name || ""}`  : "-"}
                {item.ownerDetails && (
                  <a href={`/dashboard/owner/${item.ownerDetails.id}`}>
                    <div
                      style={{
                        height: "30px",
                        width: "80px",
                        borderRadius: "0px",
                        backgroundColor: "#4caf50",
                        marginLeft: "25px",
                        color: "#fff",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Visit Profile
                    </div>
                  </a>
                )}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList">
              <div>Trailer</div>
              <div>
                {item.journey
                  ? item.journey.trailer
                    ? item.journey.trailer.reg_no
                    : "-"
                  : "-"}
                {item.journey && (
                  <a href={`/dashboard/journey/${item.journey.id}`}>
                    <div
                      style={{
                        height: "30px",
                        width: "80px",
                        borderRadius: "0px",
                        backgroundColor: "#4caf50",
                        marginLeft: "25px",
                        color: "#fff",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Montserrat",
                      }}
                    >
                      See Journey
                    </div>
                  </a>
                )}
              </div>
            </div>
            {/* <div className="BookingOverviewPrimaryList">
              <div>Verified?</div>
              <div>{item.verified ? "Verified" : "Not Verified"}</div>
            </div> */}
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
            padding: "8px 0px",
          }}
        >
          <h4
            style={{ marginLeft: "10px", textAlign: "left", color: "#f3f3f3" }}
          >
            Cost
          </h4>
          <div style={{ margin: "8px" }}>
            <div className="BookingOverviewPrimaryList BookingOverviewFullLength">
              <div>Base Cost</div>
              <div>{item.route_cost.toFixed(2)}</div>
            </div>
            <div className="BookingOverviewPrimaryList BookingOverviewFullLength">
              <div>Insurance </div>
              <div>{item.insurance_cost}</div>
            </div>
            <div className="BookingOverviewPrimaryList BookingOverviewFullLength">
              <div>Tax </div>
              <div>{item.tax_amount.toFixed(2)}</div>
            </div>
            <div className="BookingOverviewPrimaryList BookingOverviewFullLength">
              <div>Total Cost</div>
              <div style={{ fontSize: "18px", fontWeight: "400" }}>
                {item.total_cost}
              </div>
            </div>
            <div className="BookingOverviewPrimaryList BookingOverviewFullLength">
              <div>Delivery Note</div>
              <div style={{ fontSize: "18px", fontWeight: "400" }}>
                {/* {item.delivery_note ? (
                  <a href={item.delivery_note}>
                    <div
                      style={{
                        height: "30px",
                        width: "150px",
                        borderRadius: "0px",
                        backgroundColor: "#4caf50",
                        marginLeft: "25px",
                        color: "#fff",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Montserrat",
                      }}
                    >
                      See Delivery Note
                    </div>
                  </a>
                ) : (
                  "Not Uploaded"
                )} */}
                {item.delivery_note ? (
                  <Popup
                    trigger={
                      <div
                        style={{
                          height: "30px",
                          width: "150px",
                          borderRadius: "0px",
                          backgroundColor: "#4caf50",
                          marginLeft: "25px",
                          color: "#fff",
                          display: "flex",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Montserrat",
                        }}
                      >
                        See Delivery Note
                      </div>
                    }
                  >
                    <div className="model">
                      <div className="text">Image</div>
                      <div className="delivery-note">
                        <img
                          src={item.delivery_note}
                          className="delivary-note"
                        />
                      </div>
                      <div className="text">Text</div>
                      <div className="text-field">
                        {item.delivery_note_text
                          ? item.delivery_note_text
                          : "-"}
                      </div>
                    </div>
                  </Popup>
                ) : (
                  "Not Uploaded"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BookingOverview;
