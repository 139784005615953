import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel,
  TableColumnResizing,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import { ListRoutes, ToggleRouteServiceStatus } from "logic";
import GridSearchBox from "Components/GridSearchBox";
import { TableActionButton } from "Components/ActionButtons";
import TableComponent, { PagingContainer } from "Components/TableComponent";
import Switch from "@material-ui/core/Switch";
import "./RoutesList.css"
// route listing section of route tab in routes and cities screen
class RoutesList extends Component {
  state = {
    rows: [],
  };
  // handle service toggling
  ToggleService = async (id, status) => {
    let response = await ToggleRouteServiceStatus(id, status);
    if (response) this.GetRoutes();
  };
  // make api call to get routes data
  GetRoutes = async () => {
    let routes = await ListRoutes();
    if (routes) {
      let table_data = routes.data.map((route, index) => {
        return {
          origin: route.originDetails ? route.originDetails.name : "",
          destination: route.destinationDetails
            ? route.destinationDetails.name
            : "",
          name: route.name,
          active: route.active,
          ...route.fare,
          toggle: (
            <Switch
              checked={route.active ? true : false}
              inputProps={{ "aria-label": "primary checkbox" }}
              onClick={(e) => {
                this.ToggleService(route.id, !this.state.rows[index].active);
              }}
            />
          ),
          action: (
            <div style={{ display: "flex", zIndex: 2 }}>
              <TableActionButton
                rootStyle={{ margin: "0px 1px" }}
                onClick={() => {
                  this.props.openEdit(route.id, "details", {
                    ...route,
                    ...route.fare,
                    origin: route.originDetails && {
                      value: route.originDetails.id,
                      label: route.originDetails.name,
                    },
                    destination: route.destinationDetails && {
                      value: route.destinationDetails.id,
                      label: route.destinationDetails.name,
                    },
                  });
                }}
              >
                Edit Details
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "0px 1px" }}
                type="two"
                onClick={() => {
                  this.props.openEdit(route.id, "fare", {
                    ...route,
                    ...route.fare,
                  });
                }}
              >
                Edit Fare
              </TableActionButton>
            </div>
          ),
        };
      });
      this.setState({ rows: table_data });
    }
  };
  // executes before component render
  // call the function which make api call to get routes data
  componentDidMount() {
    this.GetRoutes();
  }
  columns = [
    { name: "index", title: "S.No" },
    { name: "name", title: "Route Name" },
    { name: "origin", title: "Origin" },
    { name: "destination", title: "Destination" },
    { name: "toggle", title: "Service Active" },
    { name: "cost", title: "Route Fare" },
    { name: "ollys_cut", title: "Olly's %" },
    { name: "owners_cut", title: "Owner's %" },
    { name: "drivers_cut", title: "Drivers %" },
    { name: "tax", title: "Tax Cut %" },
    { name: "insurance", title: "Insurance Cost" },
    { name: "action", title: "Actions" },
  ];
  defaultColumnWidths = this.columns.map((item) => {
    if (item.name === "action") return { columnName: item.name, width: 240 };
    return { columnName: item.name, width: 120 };
  });
  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          const row = {
            ...item,
            index: index + 1,
          };
          return row;
        })}
        columns={this.columns}
      >
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={10} />

        <IntegratedPaging />
        <Table
          tableComponent={TableComponent}
          // noDataCellComponent={NoDataCellComponent}
          messages={{ noData: "..." }}
        />
        <TableColumnResizing defaultColumnWidths={this.defaultColumnWidths} />
        <TableHeaderRow />
        <TableFixedColumns  rightColumns={["action"]} />
        <Toolbar />
        <SearchPanel inputComponent={GridSearchBox} />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}
export default RoutesList;
