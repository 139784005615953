import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
// import "./style.css";
import { UpdateTrailerBookingAvailability, GetActiveCityList } from "logic";
import Switch from "@material-ui/core/Switch";
import { SelectStyle } from "componentstyle";
import { TableActionButton } from "Components/ActionButtons";
// popup appears on click on click update button corresponding to marked location in trailer details screen
class UpdateTrailerAvailabilityDialog extends Component {
  state = {
    availability: this.props.availability,
    location: null,
    cityPlaceholder: null,
    cities: []
  };
  // fetch cities
  GetCitiesOptions = async () => {
    let response = await GetActiveCityList();
    // use response to create array of objects having label and value object
    let options = response.data.map(item => {
      return { label: item.name, value: item.center.coordinates };
    });
    console.log(options);
    this.setState({ cities: options });
  };
  // executes on click save button, make api call to save selected marked location
  SetAvailability = async () => {
    console.log(this.state);
    let response = await UpdateTrailerBookingAvailability(
      this.props.trailerid,
      this.state.availability,
      this.state.location
    );
    if (response) {
      window.location.reload();
    }
  };
  // set city dropdown placeholder and location coordinates if location is already marked
  SetCityFromCoordinates = (coordinates, cities) => {
    this.setState({ location: coordinates.coordinates });
    let city = cities.filter( city => {
      return(
        city.value[0] === coordinates.coordinates[0] && city.value[1] === coordinates.coordinates[1]
      )
    })
    city.length > 0 && city[0].label && this.setState({cityPlaceholder: city[0].label})
  }
  // executes before component render
  // call the function which make api call to get cities data
  async componentDidMount() {
    await this.GetCitiesOptions();
    this.props.location && this.SetCityFromCoordinates(this.props.location, this.state.cities);
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              Update Trailer Availability
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            <div>
              <div
                style={{
                  padding: "10px",
                  boxSizing: "border-box",
                  minHeight: "300px"
                }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px",
                    color: "#999"
                  }}
                >
                  Availability:
                </p>
                <Switch
                  checked={this.state.availability}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  onClick={() => {
                    this.setState({ availability: !this.state.availability });
                  }}
                />
                <Select
                  classNamePrefix="City-Select"
                  // options={[
                  //   { value: "40fot", label: "40 Foot Open Trailer" },
                  //   { value: "40fct", label: "40 Foot Closed Trailer" }
                  // ]}
                  options={this.state.cities}
                  placeholder={this.state.cityPlaceholder ? this.state.cityPlaceholder : "City"}
                  styles={SelectStyle}
                  onChange={opt => {
                    console.log(opt.value, "valueeeeeeeeeeeeeee");
                    this.setState({ location: opt.value });
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                onClick={this.props.handleClose}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={e => {
                  this.SetAvailability();
                }}
              >
                Save
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default UpdateTrailerAvailabilityDialog;
