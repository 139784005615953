import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  TableColumnResizing 
} from "@devexpress/dx-react-grid";
import GridSearchBox from "Components/GridSearchBox";
import { GetDriverList } from "logic";
import TableComponent, { PagingContainer, TableCellAccountStatus } from "Components/TableComponent";
import { getUrlParamsValue, handlePageChange, removeLastColumn, resetPageNo, setPageNoParams } from "../../Commons";

// driver data listing section in drivers screen and owner details screen, listing is done using @devexpress/dx-react-grid library
class DriverData extends Component {
  constructor(props) {
    super(props);
    this.initialRender = React.createRef();
    this.initialRender.current = true;
    this.DRIVER_PAGE_NUMBER = "driverPageNo"
  }
  state = { rows: [], selection: [], driverPageNo: 0,
    customColumnWidth: [
      { columnName: "index", width: 110 },
      { columnName: "name", width: "auto" },
      { columnName: "phone", width: "auto" },
      { columnName: "age", width: "auto" },
      { columnName: "licenseNumber", width: "auto" },
      { columnName: "nationality", width: "auto" },
      { columnName: "registeredOn", width: "auto" },
      { columnName: "accountStatus", width: "auto" },
    ]
  }
  componentDidMount() {
    console.log("Got Uid", this.props.uid);
    let filter = {};
    if (this.props.uid) {
      filter["owner"] = this.props.uid;
    }
    GetDriverList(this, filter);
    if (getUrlParamsValue(this.DRIVER_PAGE_NUMBER) !== 0) {
      setPageNoParams(this, this.DRIVER_PAGE_NUMBER)
    }
    // remove last column of the table which comes along with TableColumnResizing as a bug. 
    removeLastColumn();
  }
  changeSelection = (selection) => {
    this.setState({ selection: selection });
  };
  // to enable table row click action
  TableRowClickable = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        window.location.assign(`/dashboard/driver/${tableRow.row.id}`)
      }}
      className={"clickable"}
    />
  );
  // to make "Not verified" cell color different
  Cell = (props) => {
    const { row, column } = props;
    if ( column.name === "accountStatus" && row.accountStatus === "Not Verified") {
      return <TableCellAccountStatus {...props} />;
    }
    return <Table.Cell {...props} />;
  };

  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          const row = {
            ...item,
            index: index + 1
          };
          return row;
        })}
        columns={[
          { name: "index", title: "S.No" },
          { name: "name", title: "Name (First Middle Last)" },
          { name: "phone", title: "Phone Number" },
          { name: "age", title: "Age" },
          { name: "licenseNumber", title: "License Number" },
          { name: "nationality", title: "Nationality" },
          { name: "registeredOn", title: "Registered On" },
          { name: "accountStatus", title: "Account Status" }
        ]}
      >
        <SelectionState
          selection={this.state.selection}
          onSelectionChange={(selection) => {
            // console.log(selection);
            let ids = [];
            for (var i of selection) {
              ids.push(this.state.rows[i].id);
            }
            this.props.setDriverSelection && this.props.setDriverSelection(ids);
            this.changeSelection(selection);
          }}
        />
        <SearchState defaultValue=""  onValueChange={() => resetPageNo(this, this.DRIVER_PAGE_NUMBER)} />
        <IntegratedFiltering />
        <PagingState 
          currentPage={this.state.driverPageNo} 
          pageSize={10} 
          onCurrentPageChange={(page) => {
            const driverPageNoParams = getUrlParamsValue(this.DRIVER_PAGE_NUMBER)
            if (driverPageNoParams === null || (driverPageNoParams !== 0 && !this.initialRender.current)) {
              handlePageChange(page, this, this.DRIVER_PAGE_NUMBER)
            }
            if (this.initialRender.current) this.initialRender.current = false
          }} 
        />
        <IntegratedPaging />

        <Table
          tableComponent={
            this.props.tableComponent
              ? this.props.tableComponent
              : TableComponent
          }
          cellComponent={this.Cell}
          messages={{ noData: "..." }}
        />
        <TableColumnResizing resizingMode="nextColumn" columnWidths={this.state.customColumnWidth} onColumnWidthsChange={(width) => this.setState({customColumnWidth: width})}/>
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel
          inputComponent={
            this.props.searchComponent
              ? this.props.searchComponent
              : GridSearchBox
          }
        />
        <IntegratedSelection />

        <TableSelection rowComponent={this.TableRowClickable} showSelectAll={true} highlightRow={true} />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}

export default DriverData;
