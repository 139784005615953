// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

// import { getMessaging, getToken, deleteToken } from "firebase/messaging";
// const firebaseConfig = {
//     apiKey: "AIzaSyCR3kPoKFukBejexPGVDPgauLQziVXwxq0",
//     authDomain: "olly-294007.firebaseapp.com",
//     databaseURL: "https://olly-294007.firebaseio.com",
//     projectId: "olly-294007",
//     storageBucket: "olly-294007.appspot.com",
//     messagingSenderId: "63649325020",
//     appId: "1:63649325020:web:6440da8b6352fcb3f5b919",
//     measurementId: "G-2PT7201SCP"
// };

// const firebase = initializeApp(firebaseConfig);

// export const messaging = getMessaging(firebase);
 

// export default firebase;


import {initializeApp} from "firebase/app";
// eslint-disable-next-line
// import _messaging from "firebase/messaging";
import { getMessaging, getToken, deleteToken } from "firebase/messaging";

// firebase consiguration
const firebaseConfig = {
        apiKey: "AIzaSyCR3kPoKFukBejexPGVDPgauLQziVXwxq0",
        authDomain: "olly-294007.firebaseapp.com",
        databaseURL: "https://olly-294007.firebaseio.com",
        projectId: "olly-294007",
        storageBucket: "olly-294007.appspot.com",
        messagingSenderId: "63649325020",
        appId: "1:63649325020:web:6440da8b6352fcb3f5b919",
        measurementId: "G-2PT7201SCP"
    };

const app = initializeApp(firebaseConfig);
export const fMessaging = getMessaging(app);


const publicKey = "BMEr4JlAxpPBOXdRx_w2amkNN8eReZoCc_e3CDOS6dJQWjsAXectKOwtOscf71nKpLGjz3A_2zdbfngkqBGWz88";

export const getFBToken = async () => {
  let currentToken = "";
  try {
    currentToken = await getToken(fMessaging, { vapidKey: publicKey });
    if (currentToken) {
      return currentToken;
    } else {
      return false;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token.", error);
  }
};

export const deleteFBToken = async () => {
  let currentToken = "";
  try {
    currentToken = await deleteToken(fMessaging, { vapidKey: publicKey });
    if (currentToken) {
      return currentToken;
    } else {
      return false;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token.", error);
  }
};