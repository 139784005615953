import React, { Component } from "react";
import "./style.css";

// currently not using
class DocumentPreview extends Component {
  onError = error => {
    console.log(error);
  };

  render() {
    return (
      <div
        className="DocumentPreviewWrapper"
        style={{ margin: "30px", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            backgroundColor: "#275bb0",
            height: "50px",
            color: "#fff"

            // borderTopLeftRadius: "10px",
            // borderTopRightRadius: "10px"
          }}
          className="ColumnCenter"
        >
          {this.props.document.name}
        </div>
        <div style={{ paddingTop: "50px" }}>
          <div>
            <img
              style={{ height: "200px", width: "200px" }}
              src={
                this.props.document.type != "pdf"
                  ? this.props.document.url
                  : require("Assets/Logos/pdf.svg")
              }
            />
          </div>
          {/* <p>{this.props.document.name}</p> */}

          <div
            className="DocumentPreviewVerification"
            style={{
              background:
                this.props.document.status == "verified" && "rgb(203, 59, 59)"
            }}
          >
            <p style={{ cursor: "pointer" }}>
              {" "}
              {this.props.document.status == "verified"
                ? "Cancel Verification"
                : "Verify"}
            </p>
          </div>
          {/* <div className="DocumentDownload">
            <p style={{ cursor: "pointer" }}>Download</p>
          </div> */}
          <div className="DocumentPreviewActionWrapper">
            <div>
              {" "}
              <img
                src={require("Assets/Actions/view.svg")}
                style={{ height: "20px", width: "20px" }}
              />
            </div>
            <div>
              <img
                src={require("Assets/Actions/download.svg")}
                style={{ height: "20px", width: "20px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DocumentPreview;
