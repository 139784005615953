import React, { Component } from "react";
import { Table, PagingPanel } from "@devexpress/dx-react-grid-material-ui";
import { withStyles } from "@material-ui/core/styles";
import { Colors } from "Constants";
const styles = theme => ({
  tableStriped: {
    "& thead,tbody,th,td": {
      backgroundColor: Colors.body
    },
    "& tr td, tr td span": {
      color: Colors.table_text,
      borderRadius: "0px"
    },
    "& thead tr th,thead tr th span": {
      color: Colors.table_text,
      borderRadius: "0px"
    },
    "& tr": {
      height: "62px"
    },
    "& thead tr": {
      height: "86px"
    },
    "& thead tr th": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "bold"
    },
    "& thead tr th div": {
      justifyContent: "center"
    },
    "& tr td": {
      fontSize: "14px",
      lineHeight: "22px",
      textAlign: "center"
    },
    "& tr.clickable" : {
      "&:hover" : {
        "& td": {
          backgroundColor: Colors.body_hover,
          cursor: "pointer"
        }
      }
    },
  }
});

export class TableCellAccountStatus extends Component {
  render() {
    return (
      <Table.Cell
        {...this.props}
      >
        <span style={{backgroundColor: Colors.primary, padding: "10px 20px"}}>
          {this.props.value}
        </span>
      </Table.Cell>
    )
  }
}

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);
const TableComponent = withStyles(styles, { name: "TableComponent" })(
  TableComponentBase
);
export default TableComponent;
const stylesLight = theme => ({
  tableStriped: {
    "& thead,tbody,th,td": {
      backgroundColor: "#fff"
    },
    "& tr td, tr td span": {
      color: "#333",
      borderRadius: "0px"
    },
    "& thead tr th,thead tr th span": {
      color: "#333",
      borderRadius: "0px"
    },
    "& tr": {
      height: "62px"
    },
    "& thead tr": {
      height: "86px"
    },
    "& thead tr th": {
      fontSize: "17px",
      lineHeight: "20px",
      fontWeight: "bold"
    },
    "& thead tr th div": {
      justifyContent: "center"
    },
    "& tr td": {
      fontSize: "16px",
      lineHeight: "22px",
      textAlign: "center"
    }
  }
});
const TableComponentLight = withStyles(stylesLight, { name: "TableComponent" })(
  TableComponentBase
);
export { TableComponentLight };
const PagingContainer = props => (
  <PagingPanel.Container {...props} className="PagingComponent" />
);
export { PagingContainer };

const NoDataCellComponent = props => {
  console.log(props);
  return (
    <div style={{ width: "100%", color: "#f3f3f3" }}>
      <p style={{ margin: "auto" }}>Loading.....</p>
    </div>
  );
};

export { NoDataCellComponent };
