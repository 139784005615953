import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
import SpinnerTextSwitch from "../../Components/SpinnerTextSwitch";
import { TableActionButton } from "Components/ActionButtons";
import { SelectStyle } from "componentstyle";
import "./style.css";
import {
  CreateOwnerDropdownOptions,
  GetDriversOfOwner,
  CreateTrailerTypesDropdownOptions,
  CreateNewTrailer,
  GetSingleTrailer
} from "logic";
// popup appears on click "Add New Trailer" button in Trailers screen and "Edit" button in Trailer details screen
class NewTrailerDialog extends Component {
  constructor(props) {
    super(props);
    this.driverSelectRef = React.createRef();
  }

  state = {
    reg_no: null,
    tonnage: null,
    typeId: null,
    owner: null,
    driver: null,
    yop: null,
    last_health_check: null,
    tare_weight: null,
    ownerList: [],
    driverList: [],
    trailerTypesList: [],
    driverDetailsPlaceholder: null,
    make: null,
    // model: null
  };

  // executes before component render
  componentDidMount() {
    // function make api call to get owners and return owner dropdown options
    CreateOwnerDropdownOptions(this, "ownerList");
    // function make api call to get trailer types and return trailer types dropdown options
    CreateTrailerTypesDropdownOptions(this, "trailerTypesList");
    this.GetTrailer();
  }

  // execute on state or prop change
  // reset the driver when owner changes
  componentDidUpdate(prevProps,prevState) {
    if (this.state.owner !== prevState.owner) {
      GetDriversOfOwner(this, "driverList", {owner: this.state.owner})
      if(this.driverSelectRef.current) {
        if (this.props.type == "edit") {
          this.state.driver = null
          this.state.driverDetailsPlaceholder = null
        }
        this.driverSelectRef.current.select.setValue("");
      }
    }
  }

  // reset the driver and owner field to initial unedited state 
  resetOwnerDriver = () => {
    this.state.driverDetailsPlaceholder = this.state.driverDetails
    this.state.driver = this.state.driverDetails.id
    this.state.owner = this.state.ownerDetails.id
    this.state.ownerDetails && GetDriversOfOwner(this, "driverList", {owner: this.state.ownerDetails.id})
  };

  // handle dialog close
  closeNewTrailerDialog = () => {
    this.state.driverDetails && this.resetOwnerDriver();
    this.props.handleClose()
  }

  // handle trailer update, fetch trailer details on successful update
  updateTrailer = async() => {
    this.props.setSpin()
    await this.props.onSave(this.state)
    !this.props.spin && this.props.updateSuccess && this.GetTrailer()
  }

  // function makes api call to get trailer data in case of editing and have a trailer id
  async GetTrailer() {
    if (this.props.type && this.props.type == "edit" && this.props.trailerid) {
      let trailer = await GetSingleTrailer(this.props.trailerid);
      this.setState(trailer);
      this.state.driverDetails && this.resetOwnerDriver();
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              {this.props.type
                ? this.props.type == "edit"
                  ? "Edit Trailer Details "
                  : "Create New Trailer"
                : "Create New Trailer"}
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            {/* <p>Create Route Content Goes Here!</p> */}
            <div>
              {/* <div>RouteStepper Goes Here</div> */}
              <div
                style={{
                  padding: "10px",
                  boxSizing: "border-box",
                  minHeight: "400px"
                }}
                className="NewTrailerModal"
              >
                <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Registration No:
                </p>
                <InputBox
                  className="NewTrailerModalInputBox"
                  placeholder="Registration Number"
                  onChange={val => {
                    this.setState({ reg_no: val });
                  }}
                  value={this.state.reg_no}
                />
                <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Tonnage:
                </p>
                <InputBox
                  className="NewTrailerModalInputBox"
                  placeholder="Tonnage"
                  onChange={val => {
                    this.setState({ tonnage: val });
                  }}
                  value={this.state.tonnage}
                />
                <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Owner:
                </p>
                <Select
                  classNamePrefix="OwnerSelect"
                  // options={[
                  //   { value: "owner_1", label: "Owner 1" },
                  //   { value: "owner_2", label: "Owner 2" }
                  // ]}
                  options={this.state.ownerList}
                  styles={SelectStyle}
                  placeholder={
                    this.state.ownerDetails
                      ? `${this.state.ownerDetails.first_name} ${this.state.ownerDetails.last_name}`
                      : "Owner.."
                  }
                  onChange={owner => {
                    this.setState({ owner: owner.value });
                    // this.setState({ driver: "" });

                  }}
                />
                <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Driver:
                </p>
                <Select
                  ref={this.driverSelectRef}
                  classNamePrefix="DriverSelect"
                  options={this.state.driverList}
                  styles={SelectStyle}
                  // placeholder={
                  //   this.state.driverDetailsPlaceholder ? `${this.state.driverDetailsPlaceholder.first_name} ${this.state.driverDetailsPlaceholder.middle_name} ${this.state.driverDetailsPlaceholder.last_name}` :
                  //   this.state.driverDetails
                  //     ? `${this.state.driverDetails.first_name} ${this.state.driverDetails.middle_name} ${this.state.driverDetails.last_name}`
                  //     : "Driver.."
                  // }
                  placeholder={
                    this.state.driverDetailsPlaceholder ? 
                      `${this.state.driverDetailsPlaceholder.first_name} ${this.state.driverDetailsPlaceholder.middle_name || ""} ${this.state.driverDetailsPlaceholder.last_name}`
                      : "Driver.."
                  }
                  onChange={driver => {
                    this.setState({ driver: driver.value });
                  }}
                />
                <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Type:
                </p>
                <Select
                  classNamePrefix="Type Select"
                  // options={[
                  //   { value: "40fot", label: "40 Foot Open Trailer" },
                  //   { value: "40fct", label: "40 Foot Closed Trailer" }
                  // ]}
                  options={this.state.trailerTypesList}
                  placeholder={
                    this.state.trailer_type
                      ? this.state.trailer_type.title
                      : "Trailer Type.."
                  }
                  styles={SelectStyle}
                  onChange={typeId => {
                    this.setState({ typeId: typeId.value });
                  }}
                />
                <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Last Health Check:
                </p>
                <input
                  className="DataInput"
                  type="date"
                  style={{
                    width: "200px",
                    border: "1px solid #ddd",
                    padding: "0px 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "5px"
                  }}
                  value={this.state.last_health_check}
                  placeholder="Last Health Check"
                  onChange={e => {
                    this.setState({ last_health_check: e.target.value });
                  }}
                />
                <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Year Of Purchase:
                </p>
                <input
                  type="number"
                  className="NewTrailerModalInputBox DataInput"
                  style={{
                    width: "200px",
                    border: "1px solid #ddd",
                    padding: "0px 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "5px"
                  }}
                  value={this.state.yop}
                  placeholder="Year Of Purchase"
                  onChange={e => {
                    this.setState({ yop: e.target.value });
                  }}
                  min={1900}
                  max={new Date().getFullYear()}
                />
                <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Tare Weight:
                </p>
                <input
                  type="number"
                  className="NewTrailerModalInputBox DataInput"
                  style={{
                    width: "200px",
                    border: "1px solid #ddd",
                    //,
                    padding: "0px 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "5px"
                  }}
                  value={this.state.tare_weight}
                  placeholder="Tare Weight"
                  onChange={e => {
                    this.setState({ tare_weight: e.target.value });
                  }}
                  min={0}
                  max={30}
                />
                <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Make:
                </p>
                <InputBox
                  className="NewTrailerModalInputBox"
                  placeholder="Mercedes, Scania, etc."
                  onChange={val => {
                    this.setState({ make: val });
                  }}
                  value={this.state.make}
                />
                {/* <p
                  style={{
                    marginBottom: "0px"
                  }}
                  className="DataInputLabel"
                >
                  Model:
                </p>
                <InputBox
                  className="NewTrailerModalInputBox"
                  placeholder="Model"
                  onChange={val => {
                    this.setState({ model: val });
                  }}
                  value={this.state.model}
                /> */}
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                // onClick={this.props.handleClose}
                onClick={this.closeNewTrailerDialog}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={e => {
                  this.props.onSave
                    ? this.updateTrailer()
                    : CreateNewTrailer(this, this.state);
                }}
              >
                {this.props.type ? (
                  this.props.type == "edit" ? (
                    <SpinnerTextSwitch spin={this.props.spin} color={"#fff"}>
                      Update
                    </SpinnerTextSwitch>
                  ) : (
                    "Create"
                  )
                ) : (
                  "Create"
                )}
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default NewTrailerDialog;
