import React, { Component } from "react";
import {
  TrailerOverview,
  JourneyHistory,
  UploadDocumentDialog,
  NewTrailerDialog,
  UpdateTrailerAvailabilityDialog,
} from "Sections";
import Typography from "@material-ui/core/Typography";
import SpinnerTextSwitch from "../../Components/SpinnerTextSwitch";
import {
  GetTrailerJourneyHistory,
  GetSingleTrailer,
  VerifyTrailer,
  GetTrailerQR,
  UpdateTrailer,
  RejectApplication
} from "logic";
import Loader from "Components/Loader";
import { confirmAlert } from "react-confirm-alert";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import Tabs, { ThemedTab as Tab } from "../../Components/ThemedTabs";
import { TableActionButton } from "Components/ActionButtons";
import { TableComponentLight } from "Components/TableComponent";
import { GridSearchBoxLight } from "Components/GridSearchBox";
import GridSearchBox from "../../Components/GridSearchBox";
import { ProfileActionButton } from "Components/ActionButtons";
import { Colors } from "Constants";
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

// component for trailer details screen
class Trailer extends Component {
  state = {
    tab: 0,
    showUploadDocumentDialog: false,
    uid: this.props.match.params.id ? this.props.match.params.id : 0,
    journey_history: [],
    overview: null,
    qr: null,
    current_driver: null,
    current_route: null,
    current_booking: null,
    showEditTrailerModal: false,
    showVerifyTrailerSpinner: false,
    rejectTrailerSpin: false,
    showUpdateTrailerAvailabilityDialog: false,
    updateInProgress: false,
    updateSuccess: false
  };
  openUploadDocumentDialog = () => {
    this.setState({ showUploadDocumentDialog: true });
  };
  closeUploadDocumentDialog = () => {
    this.setState({ showUploadDocumentDialog: false }, () => {
      this.GetTrailer();
    });
  };
  openEditTrailerDialog = () => {
    this.setState({ showEditTrailerModal: true });
  };
  closeEditTrailerDialog = () => {
    this.setState({ showEditTrailerModal: false, updateInProgress: false });
  };
  openUpdateTrailerAvailabilityDialog = () => {
    this.setState({ showUpdateTrailerAvailabilityDialog: true });
  };
  closeUpdateTrailerAvailabilityDialog = () => {
    this.setState({ showUpdateTrailerAvailabilityDialog: false });
  };
  // not used since we have only one tab
  handleChange = (event, value) => {
    this.setState({ tab: value });
  };

  setSpin = () => {
    this.setState({ updateInProgress: true });
  }
  // function which make api call to get selected trailer details
  GetTrailer = async () => {
    let trailer = await GetSingleTrailer(this.props.match.params.id);
    this.setState({ overview: trailer });
  }
  // api call to get svg of QR code
  getQR = async () => {
    let qr = await GetTrailerQR(this.props.match.params.id);
    this.setState({ qr: qr });
  };
  // executes on click confirm button in confirm verification popup
  // funtion make api call to confirm trailer verification
  VerifyTrailer = async () => {
    this.setState({ showVerifyTrailerSpinner: true });
    let response = await VerifyTrailer(this.props.match.params.id);
    await this.GetTrailer();
    this.setState({ showVerifyTrailerSpinner: false });
  };
  // executes on click update button in edit trailer details popup
  // funtion make api call to update trailer details 
  updateTrailer = async (data) => {
    console.log("updating data :", data);
    this.setState({ updateInProgress: true });
    this.state.updateSuccess = false;
    let response = await UpdateTrailer(this.props.match.params.id, data);
    this.setState({ updateInProgress: false });
    this.GetTrailer();
    console.log("response.......", response);
    if (response) {
      console.log();
      this.state.updateSuccess = true;
      this.closeEditTrailerDialog();
    }
  };
  // executes on click confirm rejection button
  // make api call to reject application
  RejectTrailer = async () => {
    this.setState({ rejectTrailerSpin: true });
    await RejectApplication(this.props.match.params.id, "trailer");
    await this.GetTrailer();
    this.setState({ rejectTrailerSpin: false });
  };
  // to render confirm verify trailer popup
  confirmVerifyTrailer = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Are You Sure?"}
            message={"This action cannot be undone"}
            onConfirm={this.VerifyTrailer}
            onClose={onClose}
          />
        );
      },
    });
  };
  ConfirmRejectTrailer = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Confirm Rejection"}
            message={"Are you sure you want to reject this user's application?"}
            cancelButtonText={"No"}
            confirmButtonText={"Yes"}
            onConfirm={this.RejectTrailer}
            onClose={onClose}
          />
        );
      }
    });
  };
  // function make api call to get trailer journey history and set up journey history table
  fetchJourneyHistory = async () => {
    let response = await GetTrailerJourneyHistory(this.props.match.params.id);
    let journeys = [];
    let current_route = null;
    let current_driver = null;
    let current_booking = null;
    for (var item of response) {
      if (item.status == "complete" || item.status == "cancelled") {
        journeys.push(item);
      } else {
        console.log("Active Journey Log", item);
        current_route = item.route;
        current_driver = item.driver;
        current_booking = item.bookingDetails;
        // this.setState({});
      }
    }
    let MappedRows = journeys.map((item, index) => {
      // let startTime = new Date(item.start_date);
      // let [month, date, year] = startTime.toLocaleDateString().split("/");
      // let [hour, minute, second, a] = startTime
      //   .toLocaleTimeString()
      //   .split(/:| /);
      return {
        index: index + 1,
        trailer: item.trailer ? item.trailer.reg_no : "-",
        id: item.id,
        type: item.type,
        // route: item.route.route.name,
        bookingId: item.bookingDetails.bookingId,
        driver:
          item.driver &&
          `${item.driver.first_name ? item.driver.first_name : ""} ${
            item.driver.last_name ? item.driver.last_name : ""
          }`,
        start_date: new Date(item.start_date).toDateString(),
        // start_date: date + "/" + month + "/" + year,
        status: item.status.replace("_", " ").toUpperCase(),
        action: (
          <TableActionButton url={`/dashboard/journey/${item.id}`}>
            Details
          </TableActionButton>
        ),
      };
    });
    // this.state.overview["current_route"] = current_route;
    // this.state.overview["current_driver"] = current_driver;
    this.setState({
      journey_history: MappedRows,
      current_route: current_route,
      current_driver: current_driver,
      current_booking: current_booking,
      overview: this.state.overview,
    });
  };
  // executes before component render
  // call trailer details, journey history and qr code svg fetching functions
  componentDidMount() {
    this.GetTrailer();
    this.fetchJourneyHistory();
    this.getQR();
  }

  // render trailer details component
  render() {
    const { tab } = this.state;
    if (!this.state.overview) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    const disableApplicaionRejection = this.state.overview.verified ? true : this.state.overview.rejected ? true : this.state.overview.documentRejected ? false : true;
    return (
      <div style={{ backgroundColor: Colors.body }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ProfileActionButton
            backgroundColor={ disableApplicaionRejection  ? "#a1a1a1" : "#ccc"}
            textColor={"#454545"}
            onClick={this.ConfirmRejectTrailer}
            disabled={disableApplicaionRejection ? true : false }
          >
            <SpinnerTextSwitch
              spin={this.state.rejectTrailerSpin}
              color={"#454545"}
            >
              {this.state.overview.rejected ? "Rejected" : "Reject"}
            </SpinnerTextSwitch>
          </ProfileActionButton>
          {!this.state.overview.verified ? (
            <ProfileActionButton
              backgroundColor="#ccc"
              textColor={"#454545"}
              onClick={() => {
                this.confirmVerifyTrailer();
              }}
            >
              {" "}
              <SpinnerTextSwitch
                color={"#454545"}
                spin={this.state.showVerifyTrailerSpinner}
              >
                Verify Trailer
              </SpinnerTextSwitch>
            </ProfileActionButton>
          ) : (
            <ProfileActionButton
              backgroundColor="rgb(76, 175, 80,0.8)"
              textColor={"#fff"}
              disabled
            >
              Verified
            </ProfileActionButton>
          )}
        </div>
        <TrailerOverview
          openUploadDocumentDialog={this.openUploadDocumentDialog}
          uid={this.state.uid}
          data={this.state.overview}
          qr={this.state.qr}
          route={this.state.current_route}
          driver={this.state.current_driver}
          booking={this.state.current_booking}
          openEditDialog={this.openEditTrailerDialog}
          openBookingAvailabilityEdit={this.openUpdateTrailerAvailabilityDialog}
          fetchTrailerOverview={this.GetTrailer}
        />

        <div
          style={{
            padding: "10px",
            margin: "10px",
            marginBottom: "0px",
            paddingBottom: "50px",
            // border: "1px solid #ddd"
          }}
        >
          <Tabs
            value={tab}
            onChange={this.handleChange}
            centered
            style={{ width: "100%" }}
          >
            <Tab label="Journey History" className="OwnerTab" />
          </Tabs>

          {tab === 0 && (
            <TabContainer>
              <JourneyHistory
                data={this.state.journey_history}
                // tableComponent={TableComponentLight}
                // searchComponent={GridSearchBoxLight}
              />
            </TabContainer>
          )}
        </div>
        <UploadDocumentDialog
          open={this.state.showUploadDocumentDialog}
          handleClose={this.closeUploadDocumentDialog}
          type={"trailers"}
          uid={this.state.uid}
        />
        <NewTrailerDialog
          open={this.state.showEditTrailerModal}
          handleClose={this.closeEditTrailerDialog}
          type="edit"
          onSave={this.updateTrailer}
          trailerid={this.props.match.params.id}
          spin={this.state.updateInProgress}
          setSpin = {this.setSpin}
          updateSuccess = {this.state.updateSuccess}
        />
        <UpdateTrailerAvailabilityDialog
          open={this.state.showUpdateTrailerAvailabilityDialog}
          handleClose={this.closeUpdateTrailerAvailabilityDialog}
          trailerid={this.props.match.params.id}
          availability={
            this.state.overview ? this.state.overview.booking_visibility : false
          }
          location={this.state.overview ? this.state.overview.marked_location : null}
        />
      </div>
    );
  }
}

export default Trailer;
