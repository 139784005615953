import React, { Component } from "react";
import Axios from "axios";
import {
  Map,
  Marker,
  InfoWindow,
  GoogleApiWrapper,
  Polyline
} from "google-maps-react";
var constants = require("Constants.js");
const polylineDecode = function(str, precision) {
  var index = 0,
    lat = 0,
    lng = 0,
    coordinates = [],
    shift = 0,
    result = 0,
    byte = null,
    latitude_change,
    longitude_change,
    factor = Math.pow(10, precision || 5);

  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < str.length) {
    // Reset shift, result, and byte
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitude_change;
    lng += longitude_change;

    coordinates.push({ lat: lat / factor, lng: lng / factor });
  }

  return coordinates;
};
// trailer route map section  in trailer details screen
export class TrailerRouteMap extends Component {
  state = { pathData: [] };
  componentWillMount() {
    let data = this.props.data;
    var map = window.google.maps;
    console.log(map);
    try {
      let originLoc = {
        lat: data.pickup.coordinates[0],
        lng: data.pickup.coordinates[1]
      };
      let destinationLoc = {
        lat: data.drop.coordinates[0],
        lng: data.drop.coordinates[1]
      };
      //Waypoints Disabled
      // let inbtwn = data.route.waypoints.map(item => {
      //   return { location: { lat: item.lat, lng: item.lng }, stopover: false };
      // });
      // console.log(inbtwn);
      console.log(originLoc, destinationLoc);
      let DirectionOptions = {
        origin: originLoc,
        destination: destinationLoc,
        travelMode: map.TravelMode.DRIVING
        // waypoints: inbtwn
      };
      var DirectionsRequestHandler = new map.DirectionsService();
      DirectionsRequestHandler.route(DirectionOptions, res => {
        console.log("MapsAPIResponse", res);
        let pathData = polylineDecode(res.routes[0].overview_polyline, 5);
        console.log(pathData);
        let latlngData = res.routes[0].overview_path;
        let pathArray = [];
        let bounds = res.routes[0].bounds;
        // for (var latlng of latlngData) {
        //   console.log({ lat: latlng.lat(), lng: latlng.lng() });
        // }
        this.setState({ pathData: pathData, bounds: bounds });
      });
    } catch {
      console.log("route not found");
    }
  }
  render() {
    let location = {};
    if (this.props.marker_location) {
      location["lat"] = this.props.marker_location.coordinates[0];
      location["lng"] = this.props.marker_location.coordinates[1];
    }
    if (!this.props.data.pickup || !this.props.data.drop) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px"
          }}
          className={"TrailerRouteMapNoRouteWrapper"}
        >
          <div style={{ fontSize: "33px", color: "#ccc", height: "100px" }}>
            Route Data Not Available
          </div>
        </div>
      );
    }
    return (
      <div style={{ width: "100%", position: "relative" }}>
        <Map
          google={this.props.google}
          // zoom={8}
          bounds={this.state.bounds}
          initialCenter={{ lat: 26.3881805, lng: 49.9720875 }}
          styles={mapStyle}
          maxZoom={15}
          style={{ height: "1115px", width: "100%" }}
        >
          <Polyline
            // path={[
            //   { lat: 26.3881805, lng: 49.9720875 },
            //   { lat: 26.866, lng: 49.118 },
            //   { lat: 32.321, lng: 46.757 },
            //   { lat: 25.774, lng: 50.19 }
            // ]}
            strokeColor="#19b3e8"
            strokeOpacity={0.8}
            strokeWeight={6}
            path={this.state.pathData}
          />
          <Marker
            title={"Current Trailer Position"}
            name={"Trailer"}
            position={location}
          />
          <InfoWindow onClose={this.onInfoWindowClose}>
            <div />
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: constants.MAPS_API_KEY
})(TrailerRouteMap);
const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#ebe3cd"
      }
    ]
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#523735"
      }
    ]
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f1e6"
      }
    ]
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9b2a6"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#dcd2be"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ae9e90"
      }
    ]
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#93817c"
      }
    ]
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#a5b076"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#447530"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f1e6"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#fdfcf8"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#f8c967"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#e9bc62"
      }
    ]
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#e98d58"
      }
    ]
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#db8555"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#806b63"
      }
    ]
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae"
      }
    ]
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8f7d77"
      }
    ]
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ebe3cd"
      }
    ]
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#b9d3c2"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#92998d"
      }
    ]
  }
];
