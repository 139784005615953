import React, { Component } from "react";
import {
  RouteListing,
  WaypointListing,
  CreateRouteDialog,
  CreateWaypointDialog
} from "Sections";
import "./style.css";
// not used
class RouteManagement extends Component {
  state = { showCreateRouteDialog: false, showCreateOasisDialog: false };

  openCreateRouteDialog = () => {
    this.setState({ showCreateRouteDialog: true });
  };
  closeCreateRouteDialog = () => {
    this.setState({ showCreateRouteDialog: false });
  };
  openCreateOasisDialog = () => {
    this.setState({ showCreateOasisDialog: true });
  };
  closeCreateOasisDialog = () => {
    this.setState({ showCreateOasisDialog: false });
  };

  render() {
    return (
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px 10px"
          }}
        >
          <div
            style={{
              height: "50px",
              width: "150px",
              backgroundColor: "rgb(39, 91, 176)",
              color: "#fff",
              borderRadius: "40px"
            }}
            onClick={e => {
              this.openCreateRouteDialog();
            }}
            className="RowCenter RouteManagementAddRouteButton"
          >
            Create New Route
          </div>
          <div
            style={{
              height: "50px",
              width: "150px",
              backgroundColor: "rgb(39, 91, 176)",
              color: "#fff",
              marginLeft: "10px",
              borderRadius: "40px"
            }}
            onClick={e => {
              this.openCreateOasisDialog();
            }}
            className="RowCenter RouteManagementAddRouteButton"
          >
            New Waypoint
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          <div style={{ flex: "10", padding: "10px" }}>
            <RouteListing />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <div
            style={{
              // flex: "4",
              width: "100%",
              padding: "10px"
            }}
          >
            <WaypointListing title={"Hubs"} type="hub" />
          </div>{" "}
          <div
            style={{
              // flex: "4",
              width: "100%",
              padding: "10px"
            }}
          >
            <WaypointListing title={"Oases"} type="oasis" />
          </div>{" "}
          <div
            style={{
              // flex: "4",
              width: "100%",
              padding: "10px"
            }}
          >
            <WaypointListing title={"Substations"} type="substation" />
          </div>
        </div>
        <CreateRouteDialog
          open={this.state.showCreateRouteDialog}
          handleClose={this.closeCreateRouteDialog}
        />
        <CreateWaypointDialog
          open={this.state.showCreateOasisDialog}
          handleClose={this.closeCreateOasisDialog}
        />
      </div>
    );
  }
}
export default RouteManagement;
