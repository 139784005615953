import React, { Component } from "react";
import { CurrentBookings, BookingHistory, NewBookingDialog } from "Sections";

import Typography from "@material-ui/core/Typography";
import { GetCurrentBookings, GetCancelledBookings } from "logic";
import Tabs, { ThemedTab as Tab } from "../../Components/ThemedTabs";
import { ActionButton, TableActionButton } from "Components/ActionButtons";
import { Colors } from "Constants";
import { CreateBooking } from "logic";
import { timestampFormatter } from "../../Commons";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

class Bookings extends Component {
  state = {
    tab: this.props.match.params.tab ? Number(this.props.match.params.tab) : 0,
    showNewBookingModal: false,
    currentBookings: [],
    cancelledBookings: [],
    confirmButton: false
  };

  // to handle tab change
  handleChange = (event, value) => {
    this.setState({ tab: value }, () => {
      if (this.state.tab == 0) {
        // window.location.reload();
      }
    });
  };

  // function which make api call to create new booking
  createNewBooking = async data => {
    let response = await CreateBooking(data);
    this.toggleConfirm(false);
    if (response) {
      window.location.reload();
    }
  };
  toggleConfirm = status => {
    this.setState({ confirmButton: status });
  };
  
  // function which make api call to get current bookings data and setup current bookings table
  fetchCurrentBookings = async () => {
    let bookings = await GetCurrentBookings();
    let MappedRows = bookings.map((item, index) => {
      return {
        index: index + 1,
        id: item.id,
        bookingId: item.bookingId,
        pickup_date: item.pickup_datetime
          ? new Date(item.pickup_datetime).toDateString()
          : "-",
        pickup_time: item.pickup_datetime
          ? new Date(item.pickup_datetime).toLocaleTimeString()
          : "-",
        booking_date: item.booking_date
          ? timestampFormatter(item.booking_date)
          : "-",
        attended_on: item.assignedAt
          ? timestampFormatter(item.assignedAt)
          : "-",
        // startPoint: item.pickup_location
        //   ? `${item.pickup_location.coordinates[0]},${
        //       item.pickup_location.coordinates[1]
        //     }`
        //   : "NA",
        // dropPoint: item.drop_location
        //   ? `${item.drop_location.coordinates[0]},${
        //       item.drop_location.coordinates[1]
        //     }`
        //   : "NA",
        startPoint: item.pickup_location_name.split(",")[0] || "-",
        dropPoint:
          `${item.drop_location_name.split(",")[0]} ${
            item.drop_location_name.split(",")[1]
              ? item.drop_location_name.split(",")[1]
              : ""
          }` || "-",
        status: item.status.replace(/_/g, " ").toUpperCase(),
        verified: item.verified ? "Verified" : "Not Verified",
      };
    });
    this.setState({ currentBookings: MappedRows });
  };

  // function which make api call to get cancelled bookings data and setup cancelled bookings table
  fetchCancelledBookings = async () => {
    let bookings = await GetCancelledBookings();
    let MappedRows = bookings.map((item, index) => {
      return {
        index: index + 1,
        id: item.id,
        bookingId: item.bookingId,
        pickup_date: item.pickup_datetime
          ? new Date(item.pickup_datetime).toDateString()
          : "-",
        pickup_time: item.pickup_datetime
          ? new Date(item.pickup_datetime).toLocaleTimeString()
          : "-",
        booking_date: item.booking_date
          ?  timestampFormatter(item.booking_date)
          : "-",
          attended_on: item.assignedAt
          ? timestampFormatter(item.assignedAt)
          : "-",
        // startPoint: item.pickup_location
        //   ? `${item.pickup_location.coordinates[0]},${
        //       item.pickup_location.coordinates[1]
        //     }`
        //   : "NA",
        // dropPoint: item.drop_location
        //   ? `${item.drop_location.coordinates[0]},${
        //       item.drop_location.coordinates[1]
        //     }`
        //   : "NA",
        startPoint:
          item.pickup_location_name.split(",")[0] +
            " " +
            item.pickup_location_name.split(",")[1] || "-",
        dropPoint: item.drop_location_name.split(",")[0] || "-",
        status: item.status.replace("_", " ").toUpperCase(),
        verified: item.verified ? "Verified" : "Not Verified",
      };
    });
    this.setState({ cancelledBookings: MappedRows });
  };

  // create new booking popup open and close functions
  openCreateNewBookingDialog = () => {
    this.setState({ showNewBookingModal: true });
  };
  closeCreateNewBookingDialog = () => {
    this.setState({ showNewBookingModal: false });
    this.toggleConfirm(false);
  };

  // executes before component render
  // call the function which make api call to get current and cancelled booking data
  componentDidMount() {
    this.fetchCurrentBookings();
    this.fetchCancelledBookings();
  }

  // to render bookings data component
  render() {
    console.log(this.props.match.params.tab);
    const { tab } = this.state;
    return (
      <div
        style={{
          backgroundColor: Colors.body,
          minHeight: "100vh",
          paddingTop: "20px"
        }}
      >
        <div>
          <ActionButton
            iconsrc={require("Assets/Icons/Icons_Plus.svg")}
            rootStyle={{
              marginLeft: "8px",
              boxSizing: "border-box"
            }}
            onClick={() => {
              this.openCreateNewBookingDialog();
            }}
          >
            Create New Booking
          </ActionButton>
        </div>
        <Tabs
          value={tab}
          onChange={this.handleChange}
          centered
          style={{ width: "100%" }}
        >
          <Tab label="Current Bookings" className="MainTab" />
          <Tab label="Cancelled Bookings" className="MainTab" />
          <Tab label="Booking History" className="MainTab" />
        </Tabs>
        
        {/* render booking data according to tab value */}
        {tab === 0 && (
          <TabContainer>
            <div>
              <CurrentBookings data={this.state.currentBookings} />
            </div>
          </TabContainer>
        )}
        {tab === 1 && (
          <TabContainer>
            <div>
              <CurrentBookings data={this.state.cancelledBookings} />
            </div>
          </TabContainer>
        )}
        {tab === 2 && (
          <TabContainer>
            <BookingHistory />
          </TabContainer>
        )}
        <NewBookingDialog
          open={this.state.showNewBookingModal}
          handleClose={this.closeCreateNewBookingDialog}
          create={this.createNewBooking}
          confirmButton={this.state.confirmButton}
          toggleConfirmButton={this.toggleConfirm}
        />
      </div>
    );
  }
}
export default Bookings;
