import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
// import "./style.css";
import { InitiateRefund } from "logic";
import { TableActionButton } from "Components/ActionButtons";
// initiate refund popup, displays on click initiate refund button in booking details screen
class InitiateRefundDialog extends Component {
  state = {
    amount: null,
  };
  // executes on click confirm button
  // function make api call to confirm initiate refund
  Refund = async () => {
    let response = await InitiateRefund(this.props.uid, this.state.amount);
    if (response) {
      window.location.reload();
    }
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff",
              }}
            >
              Initiate Refund
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "baseline",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  color: "#f3f3f3",
                  margin: "8px 0px",
                }}
              >
                Maximum Amount :{" "}
              </p>
              <p
                style={{
                  fontSize: "24px",
                  color: "#f3f3f3",
                  margin: "8px 0px",
                }}
              >
                {this.props.total_cost}
              </p>
            </div>
            <div>
              <p
                style={{
                  fontSize: "16px",
                  color: "#f3f3f3",
                  margin: "8px 0px",
                }}
              >
                Please enter refund amount :{" "}
              </p>
              <input
                type="number"
                placeholder="Refund Amount"
                onChange={(e) => {
                  this.setState({ amount: e.target.value });
                }}
                value={this.state.amount}
                className="DataInput"
              ></input>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                onClick={this.props.handleClose}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={(e) => {
                  this.Refund();
                }}
              >
                Confirm
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default InitiateRefundDialog;
