import React, { Component } from "react";
import { Colors } from "Constants";

class InputBox extends Component {
  state = {showPassword: false }
  handleShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword})
  }
  render() {
    return (
      <>
        {this.props.type === "password" ? 
          <div style={{position: "relative"}}>
            <input
              type={this.state.showPassword ? "text" : "password"}
              placeholder={this.props.placeholder}
              className={`${this.props.className} DataInput`}
              onChange={e => {
                // this.props.onChange(e.target.value);
                this.props.regex ? this.props.onChange(e.target.value.replace(this.props.regex, "")) : this.props.onChange(e.target.value)
              }}
              style={{ ...defaultStyle, ...this.props.style }}
              value={this.props.value}
              onKeyPress={this.props.onKeyPress}
              minLength={this.props.minLength}
              maxLength={this.props.maxLength}
              autoComplete={this.props.autoComplete}
              accept={this.props.accept}
            />
            { this.props.type === "password" && !this.props.hideEye &&
              <img 
                onClick={this.handleShowPassword} 
                src={this.state.showPassword ? require("../../Assets/Icons/eye-opened.svg") : require("../../Assets/Icons/eye-closed.svg")} 
                style={{width: "21px", position: "absolute", right: " 10px", top: "0", cursor: "pointer", marginTop: "16px"}}
              />
            }
          </div> : 
          <input
            type={this.props.type ? this.props.type : "text"}
            placeholder={this.props.placeholder}
            className={`${this.props.className} DataInput`}
            onChange={e => {
              // this.props.onChange(e.target.value);
              this.props.regex ? this.props.onChange(e.target.value.replace(this.props.regex, "")) : this.props.onChange(e.target.value)
            }}
            style={{ ...defaultStyle, ...this.props.style }}
            value={this.props.value}
            onKeyPress={this.props.onKeyPress}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            autoComplete={this.props.autoComplete}
            accept={this.props.accept}
          />
        }
      </>
      
    );
  }
}
export default InputBox;
const defaultStyle = {
  borderRadius: "0px",
  backgroundColor: Colors.input_background,
  height: "47px",
  width: "339px",
  boxSizing: "border-box",
  color: Colors.input_text
};
