import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SpinnerTextSwitch from "../../Components/SpinnerTextSwitch";
import { TableActionButton } from "Components/ActionButtons";
import { SelectStyle } from "componentstyle";
import {
  CreateOwnerDropdownOptions,
  CreateNationalityDropdownOptions,
  CreateNewDriver,
  GetSingleDriver,
} from "logic";

import "./style.css";
import { BLOOD_GROUPS } from "../../Constants";
// popup appears on click "Add New Driver" button in Drivers screen and "Edit" button in Driver details screen
class NewDriverDialog extends Component {
  state = {
    dob: new Date(),
    first_name: null,
    middle_name: null,
    last_name: null,
    dob: null,
    phone: null,
    email: null,
    passcode: null,
    sex: null,
    selectedSex: null,
    address: null,
    perm_address: null,
    license_number: null,
    license_expiry: null,
    license_date_of_issue: null,
    nationality: null,
    passport_number: null,
    passport_expiry: null,
    passport_date_of_issue: null,
    iqama_number: null,
    iqama_expiry: null,
    iqama_date_of_issue: null,
    owner: null,
    nationality: null,
    ownerList: [],
    nationalityList: [],
    emergency_contact_name: null,
    emergency_contact_number: null,
    blood_group: null,
    selected_blood_group: null,
    truck_plate_number: null,
    istemara: null,
    insurance_number: null,
    bank_name: null,
    bank_address: null,
    iban_number: null,
    bank_beneficiary: null,
    bank_swift_code: null,
    owner_option_set: false,
    nationality_option_set: false,
    selectedOwner: null,
    selectedNationality: null,
  };
  // not used
  handleChange = (date) => {
    this.setState({
      dob: date,
    });
  };

  // executes before component render
  // call the function which make api call to get owners data and driver data in case of editing
  async componentDidMount() {
    CreateOwnerDropdownOptions(this, "ownerList");
    CreateNationalityDropdownOptions(this, "nationalityList");
    this.GetDriver();
  }
  // function make api call to get drivers data in case of editing
  async GetDriver() {
    if (this.props.type && this.props.type == "edit" && this.props.driverid) {
      let driver = await GetSingleDriver(this.props.driverid);
      switch (driver.sex) {
        case "male": {
          driver.selectedSex = { label: "Male", value: "male" };
          break;
        }
        case "female": {
          driver.selectedSex = { label: "Female", value: "female" };
          break;
        }
        default: {
          driver.selectedSex = null;
        }
      }
    // set blood group field in case of editing
      if (driver.blood_group) {
        driver.selected_blood_group = {label: driver.blood_group, value: driver.blood_group}
      }

      this.setState(driver);
    }
  }
  render() {
    // set owner field in case of editing
    if (
      this.state.ownerList &&
      !this.state.owner_option_set &&
      this.state.owner
    ) {
      this.state.ownerList.forEach((owner) => {
        if (owner.value === this.state.owner) {
          this.state.selectedOwner = owner;
        }
      });
    }
    // set nationality field in case of editing
    if (
      this.state.nationalityList &&
      !this.state.nationality_option_set &&
      this.state.nationality
    ) {
      this.state.nationalityList.forEach((nationality) => {
        if (nationality.value === this.state.nationality) {
          this.state.selectedNationality = nationality;
        }
      });
    }
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="md"
          //   fullScreen
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff",
              }}
            >
              {this.props.type
                ? this.props.type == "edit"
                  ? "Edit Driver Details"
                  : "Create New Driver"
                : "Create New Driver"}
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            {/* <p>Create Route Content Goes Here!</p> */}
            <div>
              {/* <div>RouteStepper Goes Here</div> */}
              <div style={{ padding: "10px", boxSizing: "border-box" }}>
                <div
                  style={{
                    display: "flex",
                    width: "101%",
                    justifyContent: "center",
                  }}
                >
                  <InputBox
                    className="NewDriverModalInputBox"
                    placeholder="First Name"
                    onChange={(val) => {
                      this.setState({ first_name: val });
                    }}
                    value={this.state.first_name}
                  />
                  <InputBox
                    className="NewDriverModalInputBox"
                    placeholder="Middle Name"
                    onChange={(val) => {
                      this.setState({ middle_name: val });
                    }}
                    value={this.state.middle_name}
                  />
                  <InputBox
                    className="NewDriverModalInputBox"
                    placeholder="Last Name"
                    onChange={(val) => {
                      this.setState({ last_name: val });
                    }}
                    value={this.state.last_name}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginRight: "10px", width: "100%" }}>
                    {" "}
                    <InputBox
                      className="NewDriverModalInputBox"
                      placeholder="Phone Number"
                      onChange={(val) => {
                        this.setState({ phone: val });
                      }}
                      value={this.state.phone}
                      type="number"                 
                    />
                    <InputBox
                      className="NewDriverModalInputBox"
                      placeholder="Email"
                      onChange={(val) => {
                        this.setState({ email: val });
                      }}
                      value={this.state.email}
                    />
                    <InputBox
                      className="NewDriverModalInputBox"
                      placeholder="Passcode"
                      onChange={(val) => {
                        this.setState({ passcode: val });
                      }}
                      value={this.state.passcode}
                      autoComplete="new-password"
                      type="password"
                      maxLength={4}
                      regex={/[^0-9]/g}
                    />
                    <Select
                      classNamePrefix="Gender Select"
                      options={[
                        { value: "male", label: "Male" },
                        { value: "female", label: "Female" },
                      ]}
                      placeholder={"Sex"}
                      styles={SelectStyle}
                      onChange={(e) => {
                        this.setState({ sex: e.value, selectedSex: e });
                      }}
                      value={this.state.selectedSex}
                    />
                    <Select
                      classNamePrefix="Employer Select"
                      options={this.state.ownerList}
                      placeholder={"Employer"}
                      styles={SelectStyle}
                      onChange={(e) => {
                        this.setState({ owner: e.value, selectedOwner: e });
                      }}
                      value={this.state.selectedOwner}
                    />
                    <div
                      className="ColumnCenter"
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginTop: "0px",
                        marginLeft: "5px",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      {" "}
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#777",
                          marginBottom: "0px",
                        }}
                      >
                        Date Of Birth:
                      </p>
                      <input
                        className="DataInput"
                        type="date"
                        style={{
                          width: "200px",
                          border: "1px solid #ddd",
                          //   marginLeft: "5px",
                          padding: "0px 10px",
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                        placeholder="Date Of Birth"
                        onChange={(e) => {
                          this.setState({ dob: e.target.value });
                        }}
                        value={this.state.dob}
                      />
                    </div>
                    <Select
                      classNamePrefix="Nationality Select NewDriverModalInputBox"
                      className={"mb-5"}
                      options={this.state.nationalityList}
                      placeholder={"Nationality"}
                      styles={SelectStyle}
                      onChange={(e) => {
                        this.setState({ nationality: e.value, selectedNationality: e });
                      }}
                      value={this.state.selectedNationality}
                    />
                    <InputBox
                      className="NewDriverModalInputBox RightNewDriverModalInputBox"
                      placeholder="Truck plate number"
                      onChange={(val) => {
                        this.setState({ truck_plate_number: val });
                      }}
                      value={this.state.truck_plate_number}
                      style={{ marginLeft: "5px" }}
                    />
                    <InputBox
                      className="NewDriverModalInputBox RightNewDriverModalInputBox"
                      placeholder="Insurance Number"
                      onChange={(val) => {
                        this.setState({ insurance_number: val });
                      }}
                      value={this.state.insurance_number}
                      style={{ marginLeft: "5px" }}
                    />
                    {/* <InputBox
                      className="NewDriverModalInputBox RightNewDriverModalInputBox"
                      placeholder="Blood Group"
                      onChange={(val) => {
                        this.setState({ blood_group: val });
                      }}
                      value={this.state.blood_group}
                      style={{ marginLeft: "5px" }}
                    /> */}
                    <Select
                      classNamePrefix="Blood Select"
                      options={BLOOD_GROUPS.map(item => {
                        return {value: item, label: item}
                      })}
                      placeholder={"Blood Group"}
                      styles={SelectStyle}
                      onChange={(e) => {
                        this.setState({ blood_group: e.value, selected_blood_group: e });
                      }}
                      value={this.state.selected_blood_group}
                    />
                    <h6
                      style={{
                        margin: "0px",
                        marginLeft: "8px",
                        marginTop: "10px",
                        color: "#777",
                      }}
                    >
                      License Details
                    </h6>
                    <div
                      className="ColumnCenter"
                      style={{
                        alignItems: "flex-start",
                        border: "1px solid #262c40",
                        boxSizing: "border-box",
                        padding: "8px",
                        margin: "8px",
                      }}
                    >
                      <InputBox
                        className="NewDriverModalInputBox NewDriverModalInputBoxNoMargin RightNewDriverModalInputBox"
                        placeholder="License Number"
                        value={this.state.license_number}
                        style={{}}
                        onChange={(val) => {
                          this.setState({ license_number: val });
                        }}
                      />
                      <div
                        className="ColumnCenter"
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "0px",
                          // marginLeft: "5px",
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#777",
                            margin: "0px",
                          }}
                        >
                          Date Of Issue:
                        </p>
                        <input
                          className="DataInput"
                          type="date"
                          style={{
                            border: "1px solid #ddd",
                            //   marginLeft: "5px",
                            padding: "0px 10px",
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          placeholder="Date Of Issue"
                          value={this.state.license_date_of_issue}
                          onChange={(e) => {
                            this.setState({
                              license_date_of_issue: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div
                        className="ColumnCenter"
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "0px",
                          // marginLeft: "5px",
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#777",
                            margin: "0px",
                          }}
                        >
                          Date Of Expiry:
                        </p>
                        <input
                          className="DataInput"
                          type="date"
                          style={{
                            border: "1px solid #ddd",
                            //   marginLeft: "5px",
                            padding: "0px 10px",
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          placeholder="Date Of Expiry"
                          value={this.state.license_expiry}
                          onChange={(e) => {
                            this.setState({
                              license_expiry: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    {" "}
                    <textarea
                      className="NewDriverModalInputBox DataTextarea"
                      placeholder="Address..."
                      style={{ resize: "none", height: "130px" }}
                      value={this.state.address}
                      onChange={(e) => {
                        this.setState({
                          address: e.target.value,
                        });
                      }}
                    />
                    <textarea
                      className="NewDriverModalInputBox DataTextarea"
                      placeholder="Permanent Address"
                      style={{ resize: "none", height: "130px" }}
                      value={this.state.perm_address}
                      onChange={(e) => {
                        this.setState({
                          perm_address: e.target.value,
                        });
                      }}
                    />
                    <h6
                      style={{
                        margin: "0px",
                        marginLeft: "8px",
                        color: "#777",
                      }}
                    >
                      Passport Details
                    </h6>
                    <div
                      className="ColumnCenter"
                      style={{
                        alignItems: "flex-start",
                        border: "1px solid #262c40",
                        padding: "8px",
                        margin: "8px",
                      }}
                    >
                      <InputBox
                        className="NewDriverModalInputBox NewDriverModalInputBoxNoMargin RightNewDriverModalInputBox"
                        placeholder="Passport Number"
                        value={this.state.passport_number}
                        style={{}}
                        onChange={(val) => {
                          this.setState({ passport_number: val });
                        }}
                      />
                      <div
                        className="ColumnCenter"
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "0px",
                          // marginLeft: "5px",
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#777",
                            margin: "0px",
                          }}
                        >
                          Date Of Issue:
                        </p>
                        <input
                          className="DataInput"
                          type="date"
                          style={{
                            width: "200px",
                            border: "1px solid #ddd",
                            //   marginLeft: "5px",
                            padding: "0px 10px",
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          placeholder="Date Of Issue"
                          value={this.state.passport_date_of_issue}
                          onChange={(e) => {
                            this.setState({
                              passport_date_of_issue: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div
                        className="ColumnCenter"
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "0px",
                          // marginLeft: "5px",
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#777",
                            margin: "0px",
                          }}
                        >
                          Date Of Expiry:
                        </p>
                        <input
                          className="DataInput"
                          type="date"
                          style={{
                            border: "1px solid #ddd",
                            //   marginLeft: "5px",
                            padding: "0px 10px",
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          placeholder="Date Of Expiry"
                          value={this.state.passport_expiry}
                          onChange={(e) => {
                            this.setState({
                              passport_expiry: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h6
                style={{
                  margin: "0px",
                  marginLeft: "8px",
                  color: "#777",
                }}
              >
                Iqama Details
              </h6>
              <div
                className="ColumnCenter"
                style={{
                  alignItems: "flex-start",
                  // margin: "10px",
                  border: "1px solid #262c40",
                  padding: "8px",
                  margin: "8px 16px",
                }}
              >
                <InputBox
                  className="NewDriverModalInputBox NewDriverModalInputBoxNoMargin RightNewDriverModalInputBox"
                  placeholder="Iqama Number"
                  onChange={(val) => {
                    this.setState({ iqama_number: val });
                  }}
                  value={this.state.iqama_number}
                  style={{}}
                />
                <div
                  className="ColumnCenter"
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "0px",
                    // marginLeft: "5px",
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                >
                  {" "}
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#777",
                      margin: "0px",
                    }}
                  >
                    Date Of Issue:
                  </p>
                  <input
                    className="DataInput"
                    type="date"
                    style={{
                      width: "200px",
                      border: "1px solid #ddd",
                      //   marginLeft: "5px",
                      padding: "0px 10px",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                    placeholder="Date Of Issue"
                    value={this.state.iqama_date_of_issue}
                    onChange={(e) => {
                      this.setState({
                        iqama_date_of_issue: e.target.value,
                      });
                    }}
                  />
                </div>
                <div
                  className="ColumnCenter"
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "0px",
                    // marginLeft: "5px",
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                >
                  {" "}
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#777",
                      margin: "0px",
                    }}
                  >
                    Date Of Expiry:
                  </p>
                  <input
                    className="DataInput"
                    type="date"
                    style={{
                      width: "200px",
                      border: "1px solid #ddd",
                      //   marginLeft: "5px",
                      padding: "0px 10px",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                    placeholder="Date Of Expiry"
                    value={this.state.iqama_expiry}
                    onChange={(e) => {
                      this.setState({
                        iqama_expiry: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="NewDriverDialogBankDetails">
                <h6
                  style={{
                    margin: "0px",
                    marginLeft: "8px",
                    color: "#777",
                  }}
                >
                  Bank Account Details
                </h6>
                <div
                  className="ColumnCenter"
                  style={{
                    alignItems: "flex-start",
                    // margin: "10px",
                    border: "1px solid #262c40",
                    padding: "8px",
                    margin: "8px 16px",
                  }}
                >
                  <InputBox
                    className="NewDriverModalInputBox NewDriverModalInputBoxNoMargin RightNewDriverModalInputBox mtb-5"
                    placeholder="Bank Name"
                    onChange={(val) => {
                      this.setState({ bank_name: val });
                    }}
                    value={this.state.bank_name}
                  />
                    <textarea
                      className="NewOwnerModalInputBox DataTextarea mtb-5"
                      placeholder="Bank Address"
                      style={{ resize: "none", height: "100px",  }}
                      onChange={e => {
                        this.setState({ bank_address: e.target.value });
                      }}
                      value={this.state.bank_address}
                    />
                    <InputBox
                      className="NewDriverModalInputBox NewDriverModalInputBoxNoMargin RightNewDriverModalInputBox mtb-5"
                      placeholder="Bank Beneficiary Name"
                      onChange={(val) => {
                        this.setState({ bank_beneficiary: val });
                      }}
                      value={this.state.bank_beneficiary}
                      style={{}}
                    />
                    <InputBox
                      className="NewDriverModalInputBox NewDriverModalInputBoxNoMargin RightNewDriverModalInputBox mtb-5"
                      placeholder="IBAN Number"
                      onChange={(val) => {
                        this.setState({ iban_number: val });
                      }}
                      value={this.state.iban_number}
                      style={{}}
                    />
                    <InputBox
                      className="NewDriverModalInputBox NewDriverModalInputBoxNoMargin RightNewDriverModalInputBox mtb-5"
                      placeholder="Swift Code"
                      onChange={(val) => {
                        this.setState({ bank_swift_code: val });
                      }}
                      value={this.state.bank_swift_code}
                      style={{}}
                    />
                </div>
              </div>
              <div
                className="ColumnCenter"
                style={{ alignItems: "flex-start", margin: "10px" }}
              >
                <h6
                  style={{
                    margin: "0px",
                    marginLeft: "5px",
                    color: "#777",
                  }}
                >
                  Emergency Contact
                </h6>
                <InputBox
                  className="NewDriverModalInputBox RightNewDriverModalInputBox"
                  placeholder="Contact Name"
                  onChange={(val) => {
                    this.setState({ emergency_contact_name: val });
                  }}
                  value={this.state.emergency_contact_name}
                  style={{ marginLeft: "10px " }}
                />
                <InputBox
                  className="NewDriverModalInputBox RightNewDriverModalInputBox"
                  placeholder="Contact Number"
                  type="number"
                  onChange={(val) => {
                    this.setState({ emergency_contact_number: val });
                  }}
                  value={this.state.emergency_contact_number}
                  style={{ marginLeft: "10px " }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                onClick={this.props.handleClose}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={(e) => {
                  this.props.onSave
                    ? this.props.onSave(this.state)
                    : CreateNewDriver(this, this.state);
                }}
              >
                {this.props.type ? (
                  this.props.type == "edit" ? (
                    <SpinnerTextSwitch spin={this.props.spin} color={"#fff"}>
                      Update
                    </SpinnerTextSwitch>
                  ) : (
                    "Create"
                  )
                ) : (
                  "Create"
                )}
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default NewDriverDialog;
