import React, { Component } from "react";
import OverviewItem from "./OverviewItem";
import "./owneroverview.css";
// currently not used
class OwnerOverview extends Component {
  state = {
    total_trailer_count: "-",
    trailers_in_transit: "-",
    idle_trailers: "-",
    journey_assigned: "-",
    total_number_of_drivers: "-",
    currently_on_duty: "-",
    drivers_on_leave: "-",
    awaiting_consignment: "-"
  };
  render() {
    return (
      <div className="OwnerOverviewWrapper">
        {/* <OverviewItem
          icon={require("Assets/Icons/trailer.svg")}
          title="Trailers"
        >
          pp
        </OverviewItem> */}

        <div className="OwnerOverviewItem">
          <div className="OwnerOverviewStatsTitle">Trailers</div>
          <div className="OwnerOverviewStats">
            <div className="OwnerOverviewStatsItem">
              <p>Total Trailer Count :</p>
              <p>{this.state.total_trailer_count}</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>Trailers In Transit :</p>
              <p>{this.state.trailers_in_transit}</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>Idle Trailers :</p>
              <p>{this.state.idle_trailers}</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>Assigned To Journery :</p>
              <p>{this.state.journey_assigned}</p>
            </div>
            {/* <div className="OwnerOverviewStatsItem">
              <p>Currently Unavailable :</p>
              <p>230</p>
            </div> */}
          </div>
        </div>
        <div className="OwnerOverviewItem">
          <div className="OwnerOverviewStatsTitle">Drivers</div>
          <div className="OwnerOverviewStats">
            <div className="OwnerOverviewStatsItem">
              <p>Total Number Of Drivers :</p>
              <p>{this.state.total_number_of_drivers}</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>Currently On Duty:</p>
              <p>{this.state.currently_on_duty}</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>On Leave :</p>
              <p>{this.state.drivers_on_leave}</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>Awaiting Consignment :</p>
              <p>{this.state.awaiting_consignment}</p>
            </div>
            {/* <div className="OwnerOverviewStatsItem">
              <p>Others :</p>
              <p>230</p>
            </div> */}
          </div>
        </div>
        <div className="OwnerOverviewItem">
          <div className="OwnerOverviewStatsTitle">Revenue</div>
          <div className="OwnerOverviewStats">
            <div className="OwnerOverviewStatsItem">
              <p>Total Revenue :</p>
              <p>230</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>Prebooked :</p>
              <p>20</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>Payment to Olly :</p>
              <p>100</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>Olly's Cut :</p>
              <p>230</p>
            </div>
            <div className="OwnerOverviewStatsItem">
              <p>Miscellanious :</p>
              <p>230</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OwnerOverview;
