import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
const ThemedTabs = withStyles({ indicator: { display: "none" } })(Tabs);
const ThemedTab = withStyles({
  root: {
    borderRadius: "16px",
    backgroundColor: "#262C40",
    color: "#fff",
    margin: "0px 13px",
    opacity: 1,
    fontSize: "14px",
    textTransform: "none"
  },
  selected: { backgroundColor: "#F95138" }
})(Tab);
export default ThemedTabs;
export { ThemedTab };
const ThemedTabLight = withStyles({
  root: {
    borderRadius: "16px",
    backgroundColor: "#ecedf1",
    color: "#000",
    margin: "0px 13px",
    opacity: 1,
    fontSize: "14px",
    textTransform: "none"
  },
  selected: { backgroundColor: "#F95138", color: "#fff" }
})(Tab);
export { ThemedTabLight };
