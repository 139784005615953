import React, { Component } from "react";
import { OwnerOverview, UploadDocumentDialog } from "Sections";
import { GetOwnerProfile } from "logic";
import Loader from "Components/Loader";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import SpinnerTextSwitch from "../../Components/SpinnerTextSwitch";
import { confirmAlert } from "react-confirm-alert";
import { NewOwnerDialog } from "Sections";
import Tabs, { ThemedTab as Tab } from "../../Components/ThemedTabs";
import { ActionButton } from "Components/ActionButtons";
import { GridSearchBoxLight } from "Components/GridSearchBox";
import {
  DriverData,
  TrailerData,
  UserData,
  OwnerProfile,
  CurrentBookings,
  BookingHistory
} from "Sections";
import Typography from "@material-ui/core/Typography";
import "./owner.css";
import { VerifyOwner, UpdateOwner, RejectApplication } from "logic";
import { Colors } from "Constants";
import { ProfileActionButton } from "Components/ActionButtons";

// to render tab button component
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}
// component for owner details screen
class Owner extends Component {
  state = {
    tab: this.props.match.params.tab ? Number(this.props.match.params.tab) : 0,
    uid: this.props.match.params.id ? this.props.match.params.id : 0,
    showUploadDocumentDialog: false,
    showEditOwnerDialog: false,
    profile: null,
    verifyOwnerSpin: false,
    rejectOwnerSpin: false,
    updateInProgress: false
  };
  openUploadDocumentDialog = () => {
    this.setState({ showUploadDocumentDialog: true });
  };

  // executes on click confirm verification button
  // make api call to make owner verify
  VerifyOwner = async () => {
    this.setState({ verifyOwnerSpin: true });
    let response = await VerifyOwner(this.props.match.params.id);
    await this.getProfile();
    this.setState({ verifyOwnerSpin: false });
  };
  // executes on click confirm rejection button
  // make api call to reject application
  RejectOwner = async () => {
    this.setState({ rejectOwnerSpin: true });
    await RejectApplication(this.props.match.params.id, "owner");
    await this.getProfile();
    this.setState({ rejectOwnerSpin: false });
  };

  // executes on click update button in edit owners profile popup
  // make api call to update owners details
  updateOwner = async data => {
    this.setState({ updateInProgress: true });
    let response = await UpdateOwner(this.props.match.params.id, data);
    this.getProfile();
    this.closeEditOwnerDialog();
  };

  openEditOwnerDialog = () => {
    this.setState({ showEditOwnerDialog: true });
  };
  closeEditOwnerDialog = () => {
    this.setState({ showEditOwnerDialog: false, updateInProgress: false });
  };

  // to render confirm owner verify popup
  confirmVerifyOwner = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Are You Sure?"}
            message={"This action cannot be undone"}
            onConfirm={this.VerifyOwner}
            onClose={onClose}
          />
        );
      }
    });
  };
  ConfirmRejectOwner = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Confirm Rejection"}
            message={"Are you sure you want to reject this user's application?"}
            cancelButtonText={"No"}
            confirmButtonText={"Yes"}
            onConfirm={this.RejectOwner}
            onClose={onClose}
          />
        );
      }
    });
  };
  closeUploadDocumentDialog = () => {
    this.setState({ showUploadDocumentDialog: false });
  };

  // to handle switching between trailers and drivers tab
  handleChange = (event, value) => {
    this.setState({ tab: value });
  };

  // function makes api call to get owner's profile date and set profile state object
  getProfile = async () => {
    let profile = await GetOwnerProfile(this.props.match.params.id);
    console.log(profile);
    this.setState({
      profile: {
        name:
          (profile.first_name || profile.last_name) &&
          `${profile.first_name} ${profile.middle_name || ""} ${
            profile.last_name
          }`,
        address: profile.address,
        phone: profile.phone,
        verified: profile.verified,
        ...profile
      }
    });
  };

  // executes before component render
  // call function which makes api call to get profile details of selected owner
  componentDidMount() {
    this.getProfile();
  }

  // to render owner details screen
  render() {
    const ownerProfile = this.state.profile
    const { tab } = this.state;
    if (!this.state.profile) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    const disableApplicaionRejection = this.state.profile.verified ? true : this.state.profile.rejected ? true : this.state.profile.documentRejected ? false : true 
    const disableVerifyOwner = !!! (ownerProfile.vat_reg_no && ownerProfile.number_of_trucks)
    // console.log("disableVerifyOwner>>", disableVerifyOwner);
    // console.log("ownerProfile.vat_reg_no >> ", ownerProfile.vat_reg_no );
    // console.log("ownerProfile.number_of_trucks >> ", ownerProfile.number_of_trucks );
    // console.log("ownerProfile.bank_name >> ", ownerProfile.bank_name );
    // console.log("ownerProfile.bank_address >> ", ownerProfile.bank_address );
    // console.log("ownerProfile.iban_number >> ", ownerProfile.iban_number );
    // console.log("ownerProfile.bank_beneficiary >> ", ownerProfile.bank_beneficiary );
    return (
      <div
        style={{
          backgroundColor: Colors.body,
          minHeight: "100vh",
          height: "100%"
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          {/* show verify owner button if the profile is not verified */}
          <ProfileActionButton
            backgroundColor={ disableApplicaionRejection  ? "#a1a1a1" : "#ccc"}
            textColor={"#454545"}
            onClick={() => {
              this.ConfirmRejectOwner();
            }}
            disabled={disableApplicaionRejection}
          >
            <SpinnerTextSwitch
              spin={this.state.rejectOwnerSpin}
              color={"#454545"}
            >
              {this.state.profile.rejected ? "Rejected" : "Reject"}
            </SpinnerTextSwitch>
          </ProfileActionButton>
          {!this.state.profile.verified ? (
            <ProfileActionButton
              backgroundColor={ disableVerifyOwner  ? "#a1a1a1" : "#ccc"}
              textColor={"#454545"}
              onClick={() => {
                this.confirmVerifyOwner();
              }}
              disabled={disableVerifyOwner}
            >
              <SpinnerTextSwitch
                spin={this.state.verifyOwnerSpin}
                color={"#454545"}
              >
                Verify Owner
              </SpinnerTextSwitch>
            </ProfileActionButton>
          ) : (
            <ProfileActionButton
              backgroundColor="rgb(76, 175, 80,0.8)"
              textColor={"#fff"}
              disabled
            >
              Verified
            </ProfileActionButton>
          )}
        </div>
        <OwnerProfile
          openUploadDocumentDialog={this.openUploadDocumentDialog}
          openEditDialog={this.openEditOwnerDialog}
          uid={this.state.uid}
          data={this.state.profile}
          fetchOwnerProfile={this.getProfile}
        />
        {/* <OwnerOverview /> */}
        <div
          style={{
            padding: "0px",
            margin: "15px",
            marginBottom: "0px",
            paddingBottom: "50px",
            boxSizing: "border-box"
            // border: "1px solid #ddd"
          }}
        >
          <Tabs
            value={tab}
            onChange={this.handleChange}
            centered
            style={{ width: "100%", padding: "20px 18px" }}
          >
            <Tab label="Trailers" className="OwnerTab" />
            <Tab label="Drivers" className="OwnerTab" />
            {/* <Tab label="Bookings History" className="OwnerTab" />
            <Tab label="Current Bookings" className="OwnerTab" /> */}
          </Tabs>
          {/* render either of trailers or drivers section according to tab value */}
          {tab === 0 && (
            <TabContainer>
              <TrailerData
                uid={this.state.uid}
                // tableComponent={TableComponentLight}
                // searchComponent={GridSearchBoxLight}
              />
            </TabContainer>
          )}
          {tab === 1 && (
            <TabContainer>
              <DriverData
                uid={this.state.uid}
                // tableComponent={TableComponentLight}
                // searchComponent={GridSearchBoxLight}
              />
            </TabContainer>
          )}
          {/* {tab === 2 && (
            <TabContainer>
              <BookingHistory />
            </TabContainer>
          )}
          {tab === 3 && (
            <TabContainer>
              <CurrentBookings />
            </TabContainer>
          )} */}
        </div>
        <UploadDocumentDialog
          open={this.state.showUploadDocumentDialog}
          handleClose={this.closeUploadDocumentDialog}
          type={"owners"}
          uid={this.state.uid}
        />
        <NewOwnerDialog
          open={this.state.showEditOwnerDialog}
          handleClose={this.closeEditOwnerDialog}
          type="edit"
          onSave={this.updateOwner}
          ownerid={this.props.match.params.id}
          spin={this.state.updateInProgress}
        />
      </div>
    );
  }
}
export default Owner;
