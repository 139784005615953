import React, { Component } from "react";
import { DocumentListing } from "Sections";
import "./style.css";
// user profile in users screen
class UserProfile extends Component {
  render() {
    let data = this.props.data;
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div
          className="UserProfileWrapper"
          style={{
            flex: "2",
            marginRight: "5px",
            backgroundColor: "#353b50",
            border: "1px solid #262c40"
          }}
        >
          <div className="UserOverviewStatsTitle">User Profile</div>
          <div className="UserProfileItemsWrapper">
            <div className="UserProfileItem">
              <p>Name: </p>
              <p>{`${data.first_name ? data.first_name : ""} ${
                data.last_name ? data.last_name : ""
              }`}</p>
            </div>
            <div className="UserProfileItem">
              <p>Address: </p>
              <p>{data.address}</p>
            </div>
            <div className="UserProfileItem">
              <p>Phone Number: </p>
              <p>{data.phone}</p>
            </div>
            <div className="UserProfileItem">
              <p>Email: </p>
              <p>{data.email}</p>
            </div>
            <div className="UserProfileItem">
              <p>Phone Number Verification: </p>
              <p>
                {data.signup_verification_status ? "Verified" : "Not Verified"}
              </p>
            </div>
            <div className="UserProfileItem">
              <p>Company: </p>
              <p>{data.company_name}</p>
            </div>
            <div className="UserProfileItem">
              <p>Company website: </p>
              <p>{data.website}</p>
            </div>
          </div>
        </div>
        <div
          className="UserProfileWrapper"
          style={{
            flex: "4",
            marginLeft: "0px",
            backgroundColor: "#353b50",
            border: "1px solid #262c40"
          }}
        >
          <div
            className="UserOverviewStatsTitle"
            style={{ position: "relative" }}
          >
            Documents{" "}
            {/* <div
              style={{
                position: "absolute",
                right: "20px",
                top: "0px",
                bottom: "0px",
                height: "100%",
                cursor: "pointer"
              }}
              className="ColumnCenter"
              onClick={() => {
                this.props.openUploadDocumentDialog();
              }}
            >
              <img
                src={require("Assets/Actions/upload.svg")}
                style={{ height: "25px", widht: "25px" }}
              />
            </div> */}
          </div>
          <DocumentListing type={"user"} uid={this.props.uid} fetchProfile={this.props.fetchUserProfile}/>
        </div>
      </div>
    );
  }
}
export default UserProfile;
