import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  TableColumnResizing 
} from "@devexpress/dx-react-grid";
import GridSearchBox from "Components/GridSearchBox";
import { GetTrailerList } from "logic";
import TableComponent, { PagingContainer, TableCellAccountStatus } from "Components/TableComponent";
import { getUrlParamsValue, handlePageChange, removeLastColumn, resetPageNo, setPageNoParams } from "../../Commons";

// trailer listing in trailers and owner details screen
class TrailerData extends Component {
  constructor(props) {
    super(props);
    this.initialRender = React.createRef();
    this.initialRender.current = true;
    this.TRAILER_PAGE_NUMBER = "trailerPageNo"
  }
  state = { rows: [], selection: [], trailerPageNo: 0,
    customColumnWidth: [
      { columnName: "index", width: 110 },
      { columnName: "reg", width: "auto" },
      { columnName: "owner", width: "auto" },
      { columnName: "driver", width: "auto" },
      { columnName: "type", width: "auto" },
      { columnName: "tonnage", width: "auto" },
      { columnName: "status", width: "auto" },
      { columnName: "accountStatus", width: "auto" },
    ]
  }
    // executes before component render
  // call the function which make api call to get trailers data
  componentDidMount() {
    this.GetTrailers();
    if (getUrlParamsValue(this.TRAILER_PAGE_NUMBER) !== 0) {
      setPageNoParams(this, this.TRAILER_PAGE_NUMBER)
    }

    // remove last column of the table which comes along with TableColumnResizing as a bug. 
    removeLastColumn();

  }
  // function which make api call to get trailers data
  GetTrailers = async () => {
    let filter = {};
    if (this.props.uid) {
      filter["owner"] = this.props.uid;
    }
    let trailers = await GetTrailerList(filter);
    let MappedRows = trailers.map(item => {
      return {
        id: item.id,
        reg: item.reg_no,
        owner:
          item.ownerDetails &&
          (item.ownerDetails.first_name ||
            item.ownerDetails.middle_name ||
            item.ownerDetails.last_name)
            ? `${
                item.ownerDetails.first_name ? item.ownerDetails.first_name : ""
              } ${
                item.ownerDetails.middle_name
                  ? item.ownerDetails.middle_name
                  : ""
              } ${
                item.ownerDetails.last_name ? item.ownerDetails.last_name : ""
              }`
            : "Not Available", 
        driver:
        item.driverDetails &&
        (item.driverDetails.first_name ||
          item.driverDetails.middle_name ||
          item.driverDetails.last_name)
          ? `${
              item.driverDetails.first_name ? item.driverDetails.first_name : ""
            } ${
              item.driverDetails.middle_name
                ? item.driverDetails.middle_name
                : ""
            } ${
              item.driverDetails.last_name ? item.driverDetails.last_name : ""
            }`
          : "Not Available",
        type: item.trailer_type ? item.trailer_type.title : "Not Available",
        tonnage: item.tonnage,
        status: item.status.replace("_", " ").toUpperCase(),
        accountStatus: item.verified ? "Verified" : "Not Verified"
      };
    });
    this.setState({ rows: MappedRows });
  };
  // handle selection of trailer item, store selected items to selection state
  changeSelection = selection => {
    this.setState({ selection: selection });
  };
  // to enable table row click action
  TableRowClickable = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        window.location.assign(`/dashboard/trailer/${tableRow.row.id}`)
      }}
      className={"clickable"}
    />
  );
  // to make "Not verified" cell color different
  Cell = (props) => {
    const { row, column } = props;
    if ( column.name === "accountStatus" && row.accountStatus === "Not Verified") {
      return <TableCellAccountStatus {...props} />;
    }
    return <Table.Cell {...props} />;
  };

  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          return {
            ...item,
            index: index + 1,
            // action: (
            //   <TableActionButton url={`/dashboard/trailer/${item.id}`}>
            //     View Details
            //   </TableActionButton>
            // )
          };
        })}
        columns={[
          { name: "index", title: "S.No" },
          { name: "reg", title: "Reg.No" },
          { name: "owner", title: "Owner" },
          { name: "driver", title: "Driver" },
          { name: "type", title: "Type" },
          { name: "tonnage", title: "Tonnage" },
          { name: "status", title: "Status" },
          { name: "accountStatus", title: "Account Status" }
        ]}
      >
        <SelectionState
          selection={this.state.selection}
          onSelectionChange={selection => {
            // console.log(selection);
            let ids = [];
            for (var i of selection) {
              ids.push(this.state.rows[i].id);
            }
            this.props.setTrailerSelection &&
              this.props.setTrailerSelection(ids);
            this.changeSelection(selection);
          }}
        />
        <SearchState defaultValue="" onValueChange={() => resetPageNo(this, this.TRAILER_PAGE_NUMBER)} />
        <IntegratedFiltering />
        <PagingState 
        currentPage={this.state.trailerPageNo} 
          pageSize={10} 
          onCurrentPageChange={(page) => {
            const trailerPageNoParams = getUrlParamsValue(this.TRAILER_PAGE_NUMBER)
            if (trailerPageNoParams === null || (trailerPageNoParams !== 0 && !this.initialRender.current)) {
              handlePageChange(page, this, this.TRAILER_PAGE_NUMBER)
            }
            if (this.initialRender.current) this.initialRender.current = false
          }}  
        />
        <IntegratedPaging />
        <Table
          tableComponent={
            this.props.tableComponent
              ? this.props.tableComponent
              : TableComponent
          }
          cellComponent={this.Cell}
          messages={{ noData: "..." }}
        />
        <TableColumnResizing resizingMode="nextColumn" columnWidths={this.state.customColumnWidth} onColumnWidthsChange={(width) => this.setState({customColumnWidth: width})}/>
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel
          inputComponent={
            this.props.searchComponent
              ? this.props.searchComponent
              : GridSearchBox
          }
        />
        <IntegratedSelection />
        <TableSelection  rowComponent={this.TableRowClickable} showSelectAll={true} highlightRow={true} />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}

export default TrailerData;
