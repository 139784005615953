// set API base URL according to domain name
function ApiUrl() {
  let API_URL;
// window.location.origin === "https://admindashboard.ollylogistics.com" ||
    // window.location.origin === "https://master.dtcloi2339i1n.amplifyapp.com/login"
  if (window.location.origin === "https://www.admin.ollytrucks.com") {
    console.log("Running in Production Environment.");
    API_URL = "https://api.olly.scrapp.app";
  } else if (window.location.origin === "https://staging.admin.ollytrucks.com") {
    console.log("Running in staging Environment.");
    API_URL = "https://staging-api.olly.scrapp.app";
  } else {
    console.log("Running in Development Environment.");
    API_URL = "https://dev-api.olly.scrapp.app";
  }
  console.log(`Web URL: `, window.location.origin);
  console.log(`API Base URL: `, API_URL);
  return API_URL;
}

const BLOOD_GROUPS = [ "A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"]
const TRAILER_DISTANCE_RADIUS = [5, 25, 75, 125, 225, 360, 500]

module.exports = {
  MAPS_API_KEY: "AIzaSyCartQOqwMSbRGTnIT-N07ctOZ9thS8aHs",
  API_URL: ApiUrl(),
  // API_URL: "http://olly-dev-env.us-east-1.elasticbeanstalk.com",
  TOKEN_KEY: "fskjdahflkjshdfkjshadk",
  Colors: {
    body: "#353B50",
    body_hover: "#3d445c",
    background: "#262C40",
    primary: "#F95138",
    input_background: "#353B50",
    input_text: "#BBBCCD",
    table_text: "#F3F3F3",
  },
  BLOOD_GROUPS,
  TRAILER_DISTANCE_RADIUS
  //test
};
