import React, { Component } from "react";
import { NavigationBar, AlertBox } from "Sections";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import EagleEye from "../EagleEye";
import Bookings from "../Bookings";
import DataManagement from "../DataManagement";
import Driver from "../Driver";
import Owner from "../Owner";
import User from "../User";
import Messages from "../Messages";
import Notification from "../Notification";
import RouteManagement from "../RouteManagement";
import Trailer from "../Trailer";
import Settings from "../Settings";
import { TOKEN_KEY, API_URL } from "Constants.js";
import Booking from "../Booking";
import Cargo from "../Cargo";
import Journeys from "../Journeys";
import Journey from "../Journey";
import FinanceOverview from "../FinanceOverview";
import CashManagement from "../CashManagement";
import RoutesScreen from "../RoutesScreen";
import { NotFound } from "Sections";
import Loader from "Components/Loader";
import Notifications from "../Notifications";
// to setup routes for autherised user
class Dashboard extends Component {
  render() {
    // const { classes } = this.props;
    if (!localStorage.getItem(TOKEN_KEY)) {
      window.location.assign("/login");
      return (
        <div>
          <Loader />
        </div>
      );
    }
    return (
      <div>
        <NavigationBar />
        <AlertBox rootStyle={{}}></AlertBox>
        {/* <p>Dashboard</p> */}
        <Router>
          <Switch>
            <Route path="/" component={EagleEye} exact={true} />
            <Route path="/dashboard/" component={EagleEye} exact={true} />
            <Route
              path="/dashboard/eagleeye"
              component={EagleEye}
              exact={true}
            />
            <Route
              path="/dashboard/eagleeye/:tab"
              component={EagleEye}
              exact={true}
            />
            <Route
              path="/dashboard/bookings"
              component={Bookings}
              exact={true}
            />
            <Route
              path="/dashboard/bookings/:tab"
              component={Bookings}
              exact={true}
            />
            <Route
              path="/dashboard/journeys"
              component={Journeys}
              exact={true}
            />
            <Route
              path="/dashboard/journeys/:tab"
              component={Journeys}
              exact={true}
            />
            <Route
              path="/dashboard/datamgmt"
              component={DataManagement}
              exact={true}
            />
            <Route
              path="/dashboard/datamgmt/:tab"
              component={DataManagement}
              exact={true}
            />
            <Route
              path="/dashboard/driver/:id"
              component={Driver}
              exact={true}
            />{" "}
            <Route path="/dashboard/owner/:id" component={Owner} exact={true} />
            <Route path="/dashboard/user/:id" component={User} exact={true} />
            <Route
              path="/dashboard/messages/"
              component={Messages}
              exact={true}
            />
            <Route
              path="/dashboard/messages/:id"
              component={Messages}
              exact={true}
            />
            <Route
              path="/dashboard/notify"
              component={Notification}
              exact={true}
            />
            {/* <Route
              path="/dashboard/settings/routes"
              component={RouteManagement}
              exact={true}
            />{" "} */}
            <Route
              path="/dashboard/trailer/:id"
              component={Trailer}
              exact={true}
            />
            <Route
              path="/dashboard/settings/"
              component={Settings}
              exact={true}
            />
            <Route
              path="/dashboard/booking/:id"
              component={Booking}
              exact={true}
            />
            <Route path="/dashboard/cargo/:id" component={Cargo} exact={true} />
            <Route
              path="/dashboard/journey/:id"
              component={Journey}
              exact={true}
            />
            <Route
              path="/dashboard/finance/"
              component={FinanceOverview}
              exact={true}
            />
            <Route
              path="/dashboard/finance/lists/"
              component={CashManagement}
              exact={true}
            />
            <Route
              path="/dashboard/finance/lists/:tab"
              component={CashManagement}
              exact={true}
            />
            <Route
              path="/dashboard/routes/lists/"
              component={RoutesScreen}
              exact={true}
            />
            <Route
              path="/dashboard/routes/lists/:tab"
              component={RoutesScreen}
              exact={true}
            />
            <Route
              path="/dashboard/notifications"
              component={Notifications}
              exact={true}
            />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Dashboard;
