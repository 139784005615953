import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  FilteringState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import { GetAllSettlementList, SettlePayouts } from "logic";
import GridSearchBox from "Components/GridSearchBox";
import fileDownload from "js-file-download";
import TableComponent, { PagingContainer } from "Components/TableComponent";
import { ActionButton } from "Components/ActionButtons";
const filteringStateColumnExtensions = [
  { columnName: "index", filteringEnabled: false },
  { columnName: "driver_name", filteringEnabled: true },
  { columnName: "amount", filteringEnabled: false },
  { columnName: "bookingId", filteringEnabled: true },
];
// driver payout listing section in driver payout screen, listing is done using @devexpress/dx-react-grid library
class DriverSettlements extends Component {
  state = { rows: [], selection: [], selected_ids: [] };
  // function make api call to get all driver payout data
  getAll = async () => {
    let response = await GetAllSettlementList("drivers");
    if (!response.data) return;
    let rows = response.data.map((item) => {
      return {
        id: item.id,
        driver_name: `${item.driver.first_name ? item.driver.first_name : ""} ${
          item.driver.last_name ? item.driver.last_name : ""
        }`,
        amount: item.amount.toFixed(2),
        bookingId: item.journey.bookingDetails.bookingId,
      };
    });
    this.setState({ rows: rows });
  };
  // executes before component render
  // call the function which make api call to get all driver payout data
  componentDidMount() {
    this.getAll();
  }
  // function to set selected payout item to selection state
  changeSelection = (selection) => {
    this.setState({ selection: selection });
  };
  // function make api call to settle payout 
  Settle = async () => {
    console.log(this.state.selected_ids);
    let response = await SettlePayouts({ payouts: this.state.selected_ids });
    console.log(response);
    if (response && response.data) {
      fileDownload(response.data, `${new Date().toDateString()}.csv`);
      this.getAll();
    }
  };
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            height: "50px",
            boxSizing: "border-box",
            width: "60%",
            position: "relative",
            top: "58px",
            zIndex: "2",
          }}
        >
          {this.state.selection && this.state.selection.length > 0 && (
            <ActionButton
              iconsrc={require("Assets/Icons/Icons_Check.svg")}
              rootStyle={{ marginLeft: "8px" }}
              onClick={() => {
                this.Settle();
              }}
            >
              Settle
            </ActionButton>
          )}
        </div>

        <Grid
          rows={this.state.rows.map((item, index) => {
            const row = {
              ...item,
              index: index + 1,
            };
            return row;
          })}
          columns={[
            { name: "index", title: "S.No" },
            { name: "driver_name", title: "Name (First Middle Last)" },
            { name: "amount", title: "Amount" },
            { name: "bookingId", title: "Booking ID" },
          ]}
        >
          {" "}
          <SelectionState
            selection={this.state.selection}
            onSelectionChange={(selection) => {
              // console.log(selection);
              let ids = [];
              for (var i of selection) {
                ids.push(this.state.rows[i].id);
              }
              this.changeSelection(selection);
              this.setState({ selected_ids: ids });
            }}
          />
          <SearchState defaultValue="" />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={filteringStateColumnExtensions}
          />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
          <Table tableComponent={TableComponent} messages={{ noData: "..." }} />
          <TableHeaderRow />
          {/* <TableFilterRow /> */}
          <Toolbar />
          <SearchPanel inputComponent={GridSearchBox} />
          <IntegratedSelection />
          <TableSelection showSelectAll={true} highlightRow={true} />
          <PagingPanel containerComponent={PagingContainer} />
        </Grid>
      </React.Fragment>
    );
  }
}
export default DriverSettlements;
