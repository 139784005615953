import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
// import "./style.css";
import {
  GetRoutes,
  GetTrailerTypes,
  GetTrailerList,
  CreateJourney
} from "logic";
// currently not used
class NewJourneyDialog extends Component {
  state = {
    base_route: null,
    trailerId: null,
    start_date: null,
    type: "primary",
    trailer_type: null,
    end_date: null,
    routeOptions: [],
    trailerTypeOptions: [],
    trailerListOptions: []
  };
  GetRouteOptions = async () => {
    let routes = await GetRoutes().then(res => {
      return res.data.data;
    });
    let options = [];
    if (routes) {
      for (var rt of routes) {
        options.push({
          label: `${rt.name}`,
          value: rt.id
        });
      }
    }
    this.setState({ routeOptions: options });
  };
  GetTrailerTypeOptions = async () => {
    let options = await GetTrailerTypes();
    if (options) {
      this.setState({ trailerTypeOptions: options });
    }
  };
  GetTrailerOfType = async () => {
    if (this.state.trailer_type) {
      console.log(this.state.trailer_type);
      let trailers = await GetTrailerList({
        typeId: this.state.trailer_type,
        verified: true
      });
      console.log(trailers);
      let options = [];
      if (trailers) {
        for (var trailer of trailers) {
          options.push({ label: trailer.reg_no, value: trailer.id });
        }
        this.setState({ trailerListOptions: options });
      } else {
        this.setState({ trailerListOptions: [] });
      }
    }
  };
  CreateNewJourney = async () => {
    if (
      !(this.state.trailer_type,
      this.state.start_date,
      this.state.base_route,
      this.state.type)
    ) {
      alert("All fields are mandatory");
      return 0;
    }
    let createdJourney = await CreateJourney(this.state);
    if (createdJourney) {
      window.location.reload();
    }
  };
  componentDidMount() {
    this.GetRouteOptions();
    this.GetTrailerTypeOptions();
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#275bb0" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              New Listing
            </div>
          </DialogTitle>
          <DialogContent>
            {/* <p>Create Route Content Goes Here!</p> */}
            <div>
              {/* <div>RouteStepper Goes Here</div> */}
              <div style={{ padding: "10px", boxSizing: "border-box" }}>
                {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center"
                  }}
                >
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="First Name"
                    onChange={val => {
                      this.setState({ first_name: val });
                    }}
                    value={this.state.first_name}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Middle Name"
                    onChange={val => {
                      this.setState({ middle_name: val });
                    }}
                    value={this.state.middle_name}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Last Name"
                    onChange={val => {
                      this.setState({ last_name: val });
                    }}
                    value={this.state.last_name}
                  />
                </div> */}
                <Select
                  classNamePrefix="Route-Select"
                  // options={[
                  //   { value: "40fot", label: "40 Foot Open Trailer" },
                  //   { value: "40fct", label: "40 Foot Closed Trailer" }
                  // ]}
                  options={this.state.routeOptions}
                  placeholder={"Route"}
                  styles={{
                    valueContainer: (base, styles) => {
                      return { ...base, height: "40px" };
                    },
                    container: (base, styles) => {
                      return {
                        ...base,
                        position: "relative",
                        left: "5px",
                        height: "40px",
                        marginTop: "8px"
                      };
                    },
                    control: (base, styles) => {
                      return {
                        ...base,
                        borderRadius: "0px"
                      };
                    },
                    menu: (base, styles) => {
                      return {
                        ...base,
                        zIndex: "999"
                      };
                    }
                  }}
                  onChange={opt => {
                    this.setState({ base_route: opt.value });
                  }}
                />
                <Select
                  classNamePrefix="Route-Select"
                  // options={[
                  //   { value: "40fot", label: "40 Foot Open Trailer" },
                  //   { value: "40fct", label: "40 Foot Closed Trailer" }
                  // ]}
                  options={this.state.trailerTypeOptions}
                  placeholder={"Trailer Type"}
                  styles={{
                    valueContainer: (base, styles) => {
                      return { ...base, height: "40px" };
                    },
                    container: (base, styles) => {
                      return {
                        ...base,
                        position: "relative",
                        left: "5px",
                        height: "40px",
                        marginTop: "8px"
                      };
                    },
                    control: (base, styles) => {
                      return {
                        ...base,
                        borderRadius: "0px"
                      };
                    },
                    menu: (base, styles) => {
                      return {
                        ...base,
                        zIndex: "999"
                      };
                    }
                  }}
                  onChange={opt => {
                    this.setState({ trailer_type: opt.value }, () => {
                      this.GetTrailerOfType();
                    });
                  }}
                />
                <Select
                  classNamePrefix="Trailer-Select"
                  // options={[
                  //   { value: "40fot", label: "40 Foot Open Trailer" },
                  //   { value: "40fct", label: "40 Foot Closed Trailer" }
                  // ]}
                  options={this.state.trailerListOptions}
                  placeholder={"Select Trailer"}
                  styles={{
                    valueContainer: (base, styles) => {
                      return { ...base, height: "40px" };
                    },
                    container: (base, styles) => {
                      return {
                        ...base,
                        position: "relative",
                        left: "5px",
                        height: "40px",
                        marginTop: "8px"
                      };
                    },
                    control: (base, styles) => {
                      return {
                        ...base,
                        borderRadius: "0px"
                      };
                    },
                    menu: (base, styles) => {
                      return {
                        ...base,
                        zIndex: "999"
                      };
                    }
                  }}
                  onChange={opt => {
                    this.setState({ trailerId: opt.value });
                  }}
                />
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px",
                    color: "#999"
                  }}
                >
                  Start Date:
                </p>
                <input
                  className="DataInput"
                  type="date"
                  style={{
                    width: "200px",
                    border: "1px solid #ddd",
                    //   marginLeft: "5px",
                    padding: "0px 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "5px"
                  }}
                  placeholder="Start Date"
                  onChange={e => {
                    this.setState({ start_date: e.target.value });
                  }}
                />
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px",
                    color: "#999"
                  }}
                >
                  End Date:
                </p>
                <input
                  className="DataInput"
                  type="date"
                  style={{
                    width: "200px",
                    border: "1px solid #ddd",
                    //   marginLeft: "5px",
                    padding: "0px 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "5px"
                  }}
                  placeholder="End Date"
                  onChange={e => {
                    this.setState({ end_date: e.target.value });
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div
              onClick={this.props.handleClose}
              color="primary"
              style={{ cursor: "pointer" }}
            >
              Close
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#21458d",
                height: "50px",
                color: "#fff",
                width: "150px",
                margin: "5px 30px"
              }}
              className="RowCenter CreateRouteDialogAddWaypointButton"
              onClick={e => {
                this.CreateNewJourney();
              }}
            >
              Create
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default NewJourneyDialog;
