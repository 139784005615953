import React, { Component } from "react";
import { GetSingleDriver } from "logic";
// driver emergency contact section in driver screen
class DriverEmergencyContact extends Component {
  state = { emergency_contact_name: null, emergency_contact_number: null };
  // function make api call to get driver details corresponding to id passed
  async Fetch() {
    let data = await GetSingleDriver(this.props.uid);
    this.setState(data);
  }
  // executes before component render
  // call the function which make api call to get driver details
  componentDidMount() {
    this.Fetch();
  }
  render() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start"
          }}
        >
          <div className="DriverOverviewListItem">
            <div className="DriverOverviewTitle">Emergency Contact</div>
            <div>
              <p>Name : </p>
              <p>{this.state.emergency_contact_name || "-"}</p>
            </div>

            <div>
              <p>Phone Number: </p>
              <p>{this.state.emergency_contact_number || "-"}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DriverEmergencyContact;
