import React from "react";
//props: {text, onClick}
const EditButton = props => {
  console.log("Prop Log...", props);
  return (
    <div
      className="EditButtonWrapper"
      style={{
        height: "40px",
        width: "60px",
        backgroundColor: "rgba(238, 238, 238, 0.29)",
        color: "#fff",
        borderRadius: "0px 0px 0px 0px",
        cursor: "pointer",
        fontSize: "18px",
        // fontWeight: "lighter",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.style
      }}
      onClick={props.onClick}
    >
      {props.text ? props.text : "Edit"}
    </div>
  );
};
export default EditButton;
