import React, { Component } from "react";
import { CargoOverview, CargoPhotos, CargoDocuments } from "Sections";
import { GetCargo, VerifyCargo } from "logic";
import Loader from "Components/Loader";
import { confirmAlert } from "react-confirm-alert";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
class Cargo extends Component {
  state = {};
  // function which make api call to get cargo details
  getCargo = async () => {
    let cargo = await GetCargo(this.props.match.params.id);
    this.setState({ data: cargo.data.data });
  };

  // function which make api call get verify
  verifyCargo = async () => {
    let verifyResponse = await VerifyCargo(this.props.match.params.id);
    this.getCargo();
  };

  // function to render confirm verify cargo popup
  confirmVerifyCargo = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Are You Sure?"}
            message={"This Action Cannot Be Undone"}
            onConfirm={this.verifyCargo}
            onClose={onClose}
          />
        );
      }
    });
  };

  // executes before component render
  // call the function which make api call to get cargo data
  componentDidMount() {
    this.getCargo();
  }

  // to render cargo component
  render() {
    let item = this.state.data;
    console.log(item);
    if (!item) {
      return <Loader />;
    }
    return (
      <div>
        {/* to conditionally render "Reject Cargo", "Verify Cargo" buttons */}
        {item.bookingDetails.status != "cancelled" &&
          item.bookingDetails.status != "complete" && (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                {!item.verified ? (
                  <div
                    style={{
                      border: "2px solid #FF9800",
                      color: "#FF9800",
                      padding: "10px",
                      width: "150px",
                      margin: "10px 10px",
                      borderRadius: "40px",
                      cursor: "pointer"
                    }}
                  >
                    Reject Cargo
                  </div>
                ) : (
                  <div
                    style={{
                      border: "2px solid #ccc",
                      color: "#ccc",
                      padding: "10px",
                      width: "150px",
                      margin: "10px 10px",
                      borderRadius: "40px",
                      cursor: "pointer"
                    }}
                  >
                    Reject Cargo
                  </div>
                )}
                {!item.verified ? (
                  <div
                    style={{
                      border: "2px solid #4CAF50",
                      color: "#4CAF50",
                      padding: "10px",
                      width: "150px",
                      margin: "10px 10px",
                      borderRadius: "40px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.confirmVerifyCargo();
                    }}
                  >
                    Verify Cargo
                  </div>
                ) : (
                  <div
                    style={{
                      border: "2px solid #ccc",
                      color: "#ccc",
                      padding: "10px",
                      width: "150px",
                      margin: "10px 10px",
                      borderRadius: "40px",
                      cursor: "pointer"
                    }}
                  >
                    Verify Cargo
                  </div>
                )}
              </div>
            </div>
          )}
        <CargoOverview data={this.state.data} />
        <CargoDocuments uid={this.props.match.params.id} />
        <CargoPhotos data={this.state.data} />
      </div>
    );
  }
}
export default Cargo;
