import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import { GetRefundRecords } from "logic";
import GridSearchBox from "Components/GridSearchBox";
import TableComponent, { PagingContainer } from "Components/TableComponent";
// refunds tab in finances page
class RefundsList extends Component {
  state = { rows: [] };
  // function make api call to get refunds data
  GetRecords = async () => {
    let response = await GetRefundRecords();
    if (response && response.data) {
      let refunds_data = response.data.map((refund) => {
        return {
          amount: refund.amount,
          user_name: `${refund.user.first_name ? refund.user.first_name : ""} ${
            refund.user.last_name ? refund.user.last_name : ""
          }`,
          bookingId:
            refund.recordDetails.paymentDetails.bookingRequest.bookingId || "-",
          reason: refund.reason ? refund.reason : "Not Provided",
          status: refund.status.replace("_", " ").toUpperCase(),
        };
      });
      this.setState({ rows: refunds_data });
    }
  };
  // executes before component render
  // call the function which make api call to get refund data
  componentDidMount() {
    this.GetRecords();
  }
  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          const row = {
            ...item,
            index: index + 1,
          };
          return row;
        })}
        columns={[
          { name: "index", title: "S.No" },
          // { name: "user_type", title: "User Type (User / Owner)" },
          { name: "user_name", title: "Name (First Middle Last)" },
          { name: "reason", title: "Reason" },
          { name: "amount", title: "Amount" },
          { name: "bookingId", title: "Booking ID" },
          { name: "status", title: "STATUS" },
          // { name: "action", title: "Actions" }
        ]}
      >
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <IntegratedPaging />

        <Table tableComponent={TableComponent} messages={{ noData: "..." }} />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel inputComponent={GridSearchBox} />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}
export default RefundsList;
