import React, { Component } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import StepConnector from "@material-ui/core/StepConnector";
import "./style.css";

const connector = (
  <StepConnector
    classes={{
      line: "journey-stops-connector-line",
      completed: "journey-stops-connector-line-completed",
      active: "journey-stops-connector-line-active"
    }}
  />
);
// journey stops section in journey details screen, used material UI library
class JourneyStops extends Component {
  state = {
    activeStep: 0
  };
  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  render() {
    const { activeStep } = this.state;
    const data = this.props.data;
    console.log(data);
    if (
      data.journey.status != "ongoing" &&
      data.journey.status != "complete" &&
      data.journey.status != "cleared_to_start"
    ) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px"
          }}
        >
          <div style={{ fontSize: "33px", color: "#ccc" }}>
            Journey Not Dispatched Yet
          </div>
        </div>
      );
    }
    if (!data.stops) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px"
          }}
        >
          <div style={{ fontSize: "33px", color: "#ccc" }}>
            Stop Data Not Available{" "}
          </div>
        </div>
      );
    }
    return (
      <div
        className="RouteListItemExpansionWrapper"
        style={{ marginTop: "100px" }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="horizontal"
          alternativeLabel
          connector={connector}
        >
          {data.stops.map((item, index) => (
            <Step
              key={index}
              // completed={item.status == "visited" ? true : false}
              active={item.status == "visited" ? true : false}
            >
              {/* <StepLabel
                  onClick={e => {
                    this.setState({ activeStep: index });
                  }}
                >
                  {item.name}
                </StepLabel> */}
              <div
                style={
                  {
                    //   display: "flex",
                    //   justifyContent: "flex-start",
                    //   cursor: "pointer"
                  }
                }
                onClick={e => {
                  this.setState({ activeStep: index });
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    justifySelf: "center",
                    alignItems: "center",
                    // padding: "4px",
                    backgroundColor:
                      item.status == "visited" ? "#52ac7f" : "#ccc",
                    height: "30px",
                    width: "30px",
                    margin: "auto",
                    borderRadius: "100%",
                    color: "#fff"
                  }}
                  onClick={e => {
                    this.setState({ activeStep: index });
                  }}
                >
                  {index + 1}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "4px",
                    color: "#f3f3f3"
                  }}
                  onClick={e => {
                    this.setState({ activeStep: index });
                  }}
                >
                  {`${item.location_name} (${item.type.toUpperCase()})`}
                </div>
              </div>
              {/* <StepContent>
                <div>
                  <div style={{ textAlign: "left" }}>
                    <span style={{ fontSize: "12px", color: "#777" }}>
                      Name: &nbsp;
                    </span>
                    {item.name}
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <span style={{ fontSize: "12px", color: "#777" }}>
                      Type: &nbsp;
                    </span>
                    {item.type}
                  </div>
                </div>
              </StepContent> */}
            </Step>
          ))}
        </Stepper>
        {/* <hr /> */}
      </div>
    );
  }
}
export default JourneyStops;
const data = [
  {
    origin: "Dammam",
    destination: "Riyadh",
    name: "Route One",
    stopCount: 5,
    stops: [
      {
        name: "Dammam",
        type: "Origin"
      },
      {
        name: "Cafe",
        type: "Oases"
      },
      {
        name: "Some Other Place",
        type: "Other"
      },
      {
        name: "Riyadh",
        type: "Destination"
      },
      {
        name: "Dammam",
        type: "Origin"
      },
      {
        name: "Cafe",
        type: "Oases"
      },
      {
        name: "Some Other Place",
        type: "Other"
      },
      {
        name: "Riyadh",
        type: "Destination"
      },
      {
        name: "Dammam",
        type: "Origin"
      },
      {
        name: "Cafe",
        type: "Oases"
      },
      {
        name: "Some Other Place",
        type: "Other"
      },
      {
        name: "Riyadh",
        type: "Destination"
      }
    ]
  },
  {
    origin: "Dammam",
    destination: "Riyadh",
    name: "Route One",
    stopCount: 5,
    stops: [
      {
        name: "Dammam",
        type: "Origin"
      },
      {
        name: "Cafe",
        type: "Oases"
      },
      {
        name: "Some Other Place",
        type: "Other"
      },
      {
        name: "Riyadh",
        type: "Destination"
      }
    ]
  },
  {
    origin: "Dammam",
    destination: "Riyadh",
    name: "Route One",
    stopCount: 5,
    stops: [
      {
        name: "Dammam",
        type: "Origin"
      },
      {
        name: "Cafe",
        type: "Oases"
      },
      {
        name: "Some Other Place",
        type: "Other"
      },
      {
        name: "Riyadh",
        type: "Destination"
      }
    ]
  }
];
