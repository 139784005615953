import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "./overviewoverlay.css";
// import { constants } from "http2";
const BottomBar = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(233, 141, 88)",
        position: "absolute",
        bottom: "0px",
        left: "0px",
        right: "0px",
        height: "50px"
      }}
    >
      <img
        src={props.icon}
        style={{ height: "30px", width: "30px", marginRight: "15px" }}
      />
      <div style={{ fontSize: "16px", color: "#fff", fontWeight: "400" }}>
        {props.children}
      </div>
    </div>
  );
};
// currently not used
class TrailerOverviewOverlay extends Component {
  classes = this.props.classes;

  render() {
    let data = this.props.data;
    return (
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          right: "0px",
          height: "190px",
          backgroundColor: "rgba(255, 255, 255, 0.55)",
          boxShadow: "3px -2px 20px #999",
          justifyContent: "space-around",
          maxWidth: "100vw",
          flexWrap: "wrap"
        }}
        className="RowCenter"
      >
        <OverviewCard>
          <div
            className="TrailerOverviewOverlayCard"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            // style={{ position: "relative" }}
          >
            <h4 className="OverviewOverlayNumber">
              {data.active_journey_count || "-"}
            </h4>
          </div>
          <BottomBar icon={require("Assets/Icons/trailer.svg")}>
            Trailers In Transit
          </BottomBar>
        </OverviewCard>
        <OverviewCard>
          <div className="TrailerOverviewOverlayCard">
            <h4>{data.booking_count}</h4>
          </div>
          <BottomBar icon={require("Assets/Icons/user.svg")}>
            Bookings
          </BottomBar>
        </OverviewCard>
        <OverviewCard>
          <div className="TrailerOverviewOverlayCard">
            <h4>{data.active_journey_count || "-"}</h4>
          </div>
          <BottomBar icon={require("Assets/Icons/driver.svg")}>
            Drivers On The Road
          </BottomBar>
        </OverviewCard>
        <OverviewCard>
          <div className="TrailerOverviewOverlayCard">
            <h4>
              {data.total_cargo_weight ? data.total_cargo_weight / 1000 : "-"}
            </h4>
          </div>
          <BottomBar icon={require("Assets/Icons/package.svg")}>
            Tonnes Of Cargo
          </BottomBar>
        </OverviewCard>
      </div>
    );
  }
}
export default TrailerOverviewOverlay;
const styles = {
  card: {
    minWidth: "23vw",
    height: "160px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: "0px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 0px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

function SimpleCard(props) {
  const { classes } = props;
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.card}>
      <CardContent
        style={{
          padding: "0px",
          height: "160px",
          position: "relative",
          paddingTop: "40px"
        }}
      >
        {props.children}
      </CardContent>
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired
};
const OverviewCard = withStyles(styles)(SimpleCard);
