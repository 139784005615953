import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import { GetAllDues } from "logic";
import GridSearchBox from "Components/GridSearchBox";
import { TableActionButton } from "Components/ActionButtons";
import TableComponent, { PagingContainer } from "Components/TableComponent";
// dues listing section in driver finance screen, listing is done using @devexpress/dx-react-grid library
class DuesList extends Component {
  state = { rows: [] };
  // function make api call to get all dues data
  getData = async () => {
    let response = await GetAllDues();
    // setup due item row
    let mappedData = response.data.map((item, index) => {
      if (item.userId) {
        return {
          ...item,
          user_id: item.userId,
          user_name: `${item.user.first_name ? item.user.first_name : "-"} ${
            item.user.last_name ? item.user.last_name : "-"
          }`,
          user_type: "USER",
          type: item.type ? item.type.toUpperCase() : "-",
          bookingId: item.booking ? item.booking.bookingId : "-",
          // action: (
          //   <TableActionButton url={`/dashboard/user/${item.user.id}`}>
          //     View Profile
          //   </TableActionButton>
          // )
        };
      } else if (item.ownerId) {
        return {
          ...item,
          user_id: item.ownerId,
          user_name: `${item.owner.first_name ? item.owner.first_name : "-"} ${
            item.owner.last_name ? item.owner.last_name : "-"
          }`,
          user_type: "OWNER",
          type: item.type ? item.type.toUpperCase() : "-",
          bookingId: item.booking ? item.booking.bookingId : "-",
          // action: (
          //   <TableActionButton
          //     url={`/dashboard/owner/${item.owner.id}`}
          //   ></TableActionButton>
          // )
        };
      }
    });
    this.setState({ rows: mappedData });
  };
  TableRowClickable = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      // onClick={() => {
      //   let url;
      //   tableRow.row.user_type === "USER" ? url = `/dashboard/user/${tableRow.row.user_id}` : url = `/dashboard/user/${tableRow.row.user_id}`
      //   window.location.assign(url)
      // }}
      // className={"clickable"}
    />
  );
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          const row = {
            ...item,
            index: index + 1
          };
          return row;
        })}
        columns={[
          { name: "index", title: "S.No" },
          { name: "user_type", title: "User Type (User / Owner)" },
          { name: "user_name", title: "Name (First Middle Last)" },
          { name: "type", title: "Due Type" },
          { name: "amount", title: "Amount" },
          { name: "bookingId", title: "Booking ID" },
          // { name: "action", title: "Actions" }
        ]}
      >
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <IntegratedPaging />

        <Table tableComponent={TableComponent} rowComponent={this.TableRowClickable} messages={{ noData: "..." }} />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel inputComponent={GridSearchBox} />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}
export default DuesList;
