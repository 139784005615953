import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  // TableColumnResizing,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  TableColumnResizing 
} from "@devexpress/dx-react-grid";
import GridSearchBox from "Components/GridSearchBox";
import { GetUserList } from "logic";
import TableComponent, { PagingContainer } from "Components/TableComponent";
import { TableCellAccountStatus } from "../../Components/TableComponent";
import { getUrlParamsValue, handlePageChange, removeLastColumn, resetPageNo, setPageNoParams } from "../../Commons";

// to list users in user tab
class UserData extends Component {
  constructor(props) {
    super(props);
    this.initialRender = React.createRef();
    this.initialRender.current = true;
    this.USER_PAGE_NUMBER = "userPageNo"
  }
  state = { rows: [], userPageNo: 0,
    customColumnWidth: [
      { columnName: "index", width: 90 },
      { columnName: "name", width: "auto" },
      { columnName: "phone", width: "auto" },
      { columnName: "email", width: "auto" },
      { columnName: "address", width: "auto" },
      { columnName: "noOfBooking", width: 120 },
    ]
  };
    // executes before component render
  // call the function which make api call to get users data
  async componentDidMount() {
    await GetUserList(this);
    if (getUrlParamsValue(this.USER_PAGE_NUMBER) !== 0) {
      setPageNoParams(this, this.USER_PAGE_NUMBER)
    }

    // remove last column of the table which comes along with TableColumnResizing as a bug. 
    removeLastColumn();
  }
  // to enable table row click action
  TableRowClickable = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        window.location.assign(`/dashboard/user/${tableRow.row.id}`)
      }}
      className={"clickable"}
    />
  );
  // to make "Not verified" cell color different
  Cell = (props) => {
    const { row, column } = props;
    if ( column.name === "accountStatus" && row.accountStatus === "Not Verified") {
      return <TableCellAccountStatus {...props} />;
    }
    return <Table.Cell {...props} />;
  };

  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          return {
            ...item,
            index: index + 1,
          };
        })}
        columns={[
          { name: "index", title: "S.No" },
          { name: "name", title: "Name" },
          { name: "phone", title: "Phone Number" },
          { name: "email", title: "Email" },
          { name: "address", title: "Address" },
          { name: "noOfBooking", title: "No. of booking"}
        ]}
      >
        {/* <SelectionState /> */}
        <SearchState defaultValue="" onValueChange={() => resetPageNo(this, this.USER_PAGE_NUMBER)}/>
        <IntegratedFiltering />
        <PagingState 
          currentPage={this.state.userPageNo} 
          pageSize={10} 
          onCurrentPageChange={(page) => {
            const userPageNoParams = getUrlParamsValue(this.USER_PAGE_NUMBER)
            if (userPageNoParams === null || (userPageNoParams !== 0 && !this.initialRender.current)) {
              handlePageChange(page, this, this.USER_PAGE_NUMBER)
            }
            if (this.initialRender.current) this.initialRender.current = false
          }} 
        />        
        <IntegratedPaging />
        <Table tableComponent={TableComponent} rowComponent={this.TableRowClickable} cellComponent={this.Cell} messages={{ noData: "..." }} />
        <TableColumnResizing  resizingMode="nextColumn" columnWidths={this.state.customColumnWidth} onColumnWidthsChange={(width) => this.setState({customColumnWidth: width})}/>
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel inputComponent={GridSearchBox} />
        {/* <IntegratedSelection /> */}
        {/* <TableSelection showSelectAll={true} highlightRow={true} /> */}
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}

export default UserData;
