import React, { Component } from "react";
import { DocumentListing } from "Sections";
class CargoDocuments extends Component {
  render() {
    return (
      <div>
        <div className="CargoPhotosHeader">
          <p>Documents</p>
        </div>
        <DocumentListing type={"cargo"} uid={this.props.uid} />
      </div>
    );
  }
}

export default CargoDocuments;
