import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import  { fMessaging, getFBToken } from "./firebase";
import { getMessaging, getToken,onMessage } from "firebase/messaging";
import "./App.css";
import { Login, Dashboard } from "Screens";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { NotFound } from "Sections";
import { NotificationManager } from "react-notifications";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios"
// firebase topic subscription function
const subscribeToTopic = (topicName, handler = () => {}) =>
  getFBToken().then(async (currentToken) => {
    if (currentToken) {
      const FIREBASE_API_KEY = `AAAADtHLn9w:APA91bGJOL3dLD1JfpGJ1lcHJzedZ7L1aK4bsjDnMQW0C3M5Rwo78q7msZvuwV4HyI0Y65l_NLkyc4g9RFoJIBH6EolcDvZhu0HAllol28wLOAtaAv5HojtQa1EBbzZ2BRlzTSguexme`;
      // Subscribe to the topic
      const topicURL = `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/superadmin`;
      await axios.post(topicURL, {},
        {headers: {
          'Content-Type':'application/json',
        'Content-Length': 0,
          Authorization: `key=${FIREBASE_API_KEY}`,
        },}
        )
        .then((response) => {
          const messaging = getMessaging();
          onMessage(messaging,
            (payload) => {
              console.log(payload, "payload")
              handler(payload);
            },
            (error) => {
              console.log(error);
            }
          );
        })
        .catch((e) => {
          console.log(`Can't subscribe to ${topicName} topic`);
        });
    }
  });

function topicOnMessageHandler(message) {
  NotificationManager.success(message.notification.body, message.notification.title, 5000, () => {window.location.assign("/dashboard/bookings/0")})
}

class App extends Component {
  componentDidMount() {
    subscribeToTopic("superadmin", topicOnMessageHandler).then();
    // const messaging = getMessaging();
    // FirebaseMessaging.getInstance().subscribeToTopic("news");
    // onMessage(messaging, payload => {
    //   // NotificationManager.info(true);
    //   // console.log("Message received. ", payload);
    //   // ...
    // });
  }
  render() {
    return (
      <div className="App">
        <Router basename="/">
          <Switch>
            <Route path="/" exact={true} component={Dashboard} />
            <Route path="/login/" exact={true} component={Login} />
            <Route path="/dashboard" exact={false} component={Dashboard} />
            <Route component={NotFound} />
          </Switch>
        </Router>
        <NotificationContainer />
      </div>
    );
  }
}

export default App;
