import React, { Component } from "react";
import "./driveroverview.css";
import { GetAgeFromDate } from "logic";
import EditButton from "Components/EditButton";
import { timestampFormatter } from "../../Commons";
// driver overview section in driver details screen
class DriverOverview extends Component {
  state = {};

  render() {
    let data = this.props.data;
    return (
      <div style={{ width: "100%" }}>
        <div
          className="RowCenter SectionDriverOverview"
          style={{ width: "100%", justifyContent: "space-around" }}
        >
          <div style={{ width: "60%" }}>
            <img
              src={data.profile_picture}
              className="DriverOverviewProfilePicture"
            />
          </div>
          <div className="DriverOverviewLists">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <EditButton
                style={{
                  width: "100px",
                  height: "40px",
                  borderRadius: "0px",
                  fontSize: "18px",
                  backgroundColor: "#f95138",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0px"
                }}
                onClick={this.props.openEditDialog}
              />
            </div>
            <div className="DriverOverviewListItem">
              <div className="DriverOverviewTitle">Name</div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div className="DriverOverviewName">
                  <p className="DriverOverviewNameLabel">First Name: </p>
                  <p className="DriverOverviewNameData">{data.first_name}</p>
                </div>
                <div className="DriverOverviewName">
                  <p className="DriverOverviewNameLabel">Middle Name: </p>
                  <p className="DriverOverviewNameData">
                    {data.middle_name || "-"}
                  </p>
                </div>
                <div className="DriverOverviewName">
                  <p className="DriverOverviewNameLabel">Last Name: </p>
                  <p className="DriverOverviewNameData">{data.last_name}</p>
                </div>
              </div>
            </div>
            <div className="DriverOverviewListItem">
              <div className="DriverOverviewTitle">Contact</div>
              <div className="DriverOverviewListRow">
                <p>Phone Number: </p>
                <p>{data.phone || "-"}</p>
              </div>
              <div className="DriverOverviewListRow">
                <p>Email Address: </p>
                <p>{data.email || "-"}</p>
              </div>
            </div>
            <div className="DriverOverviewListItem">
              <div className="DriverOverviewTitle">Owner</div>
              <div className="DriverOverviewListRow">
                <p>Name: </p>
                <p>
                  {data.ownerDetails
                    ? `${data.ownerDetails.first_name} ${data.ownerDetails.last_name}`
                    : ""}
                </p>
              </div>
            </div>
            <div className="DriverOverviewListItem">
              <div className="DriverOverviewTitle">Truck Plate Number</div>
              <div className="DriverOverviewListRow">
                <p>Truck Plate Number: </p>
                <p>
                  {data.truck_plate_number}
                </p>
              </div>
            </div>
            <div className="DriverOverviewListItem">
              <div className="DriverOverviewTitle">Account Verification</div>
              <div className="DriverOverviewListRow">
                <p>Verification Status: </p>
                <p>{data.verified ? "Verified" : "Not Verified"}</p>
              </div>
              <div className="DriverOverviewListRow">
                <p>Registered On: </p>
                <p>{timestampFormatter(data.createdAt)}</p>
              </div>
            </div>
            <div className="RowCenter" style={{ width: "100%" }}>
              <div className="DriverOverviewListItem">
                <div className="DriverOverviewTitle">Passport Details</div>
                <div className="DriverOverviewListRow">
                  <p>Passport #: </p>
                  <p>{data.passport_number}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Date Of Issue: </p>
                  <p>{data.passport_date_of_issue || "-"}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Date Of Expiry: </p>
                  <p>{data.passport_expiry || "-"}</p>
                </div>
              </div>
              <div className="DriverOverviewListItem">
                <div className="DriverOverviewTitle">License Details</div>
                <div className="DriverOverviewListRow">
                  <p>License #: </p>
                  <p>{data.license_number}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Date Of Issue: </p>
                  <p>{data.license_date_of_issue}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Date Of Expiry: </p>
                  <p>{data.license_expiry}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="RowCenter SectionDriverOverview"
          style={{ width: "100%", justifyContent: "space-around" }}
        >
          <div className="DriverOverviewLists">
            <div className="RowCenter" style={{ width: "100%" }}>
              <div className="DriverOverviewListItem">
                <div className="DriverOverviewTitle">Address Details</div>
                <div>
                  <p>{data.address}</p>
                </div>
              </div>
              <div className="DriverOverviewListItem">
                <div className="DriverOverviewTitle">Iqama Details</div>
                <div className="DriverOverviewListRow">
                  <p>Iqama #: </p>
                  <p>{data.iqama_number}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Date Of Issue: </p>
                  <p>{data.iqama_date_of_issue}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Date Of Expiry: </p>
                  <p>{data.iqama_expiry}</p>
                </div>
              </div>
              <div className="DriverOverviewListItem">
                <div className="DriverOverviewTitle">Personal Info</div>
                <div className="DriverOverviewListRow">
                  <p>Date Of Birth : </p>
                  <p>{data.dob}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Sex: </p>
                  <p>{(data.sex && data.sex.toUpperCase()) || "-"}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Age: </p>
                  <p>{data.dob ? GetAgeFromDate(this.state.dob) : "-"}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Nationality: </p>
                  <p>{data.nationalityDetails.name}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Blood Group: </p>
                  <p>{data.blood_group}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Insurance Number: </p>
                  <p>{data.insurance_number}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="RowCenter SectionDriverOverview"
          style={{ width: "100%", justifyContent: "space-around" }}
        >
          <div className="DriverOverviewLists">
            <div className="RowCenter" style={{ width: "50%" }}>
              <div className="DriverOverviewListItem">
                <div className="DriverOverviewTitle">Bank Account Details</div>
                <div className="DriverOverviewListRow">
                  <p>Bank beneficiary name: </p>
                  <p>
                    {data.bank_beneficiary ? data.bank_beneficiary : ""}
                  </p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>IBAN Number: </p>
                  <p>{data.iban_number ? data.iban_number : ""}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Swift Code: </p>
                  <p>{data.bank_swift_code ? data.bank_swift_code : ""}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Bank Name: </p>
                  <p>{data.bank_name ? data.bank_name : ""}</p>
                </div>
                <div className="DriverOverviewListRow">
                  <p>Bank Address: </p>
                  <p>{data.bank_address ? data.bank_address : ""}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DriverOverview;
