import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging
} from "@devexpress/dx-react-grid";
import TableComponent, { PagingContainer } from "Components/TableComponent";

import "./style.css";

// to display cargo listing
class CargoList extends Component {
  state = { rows: [] };
  componentDidMount() {
    this.setState({ rows: this.props.data.cargo });
  }

  render() {
    return (
      <div style={{ backgroundColor: "#353b50" }}>
        <div className="CargoListHeader">
          <p style={{ margin: "0px" }}>Cargos</p>
        </div>
        <div>
          <Grid
            rows={this.state.rows.map(item => {
              return {
                ...item,
                status: item.verified ? "Verified" : "Not Verified",
                category: item.cargoType
                  ? item.cargoType.type_name
                  : "Not Provided",
                action: (
                  <a href={`/dashboard/cargo/${item.id}`}>
                    <div
                      style={{
                        height: "40px",
                        width: "100px",
                        borderRadius: "20px",
                        backgroundColor: "#275bb0",
                        color: "#fff",
                        display: "flex",
                        fontSize: "12px",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Montserrat"
                      }}
                    >
                      Details
                    </div>
                  </a>
                )
              };
            })}
            columns={[
              { name: "id", title: "Cargo ID" },
              { name: "dimensions", title: "Dimensions" },
              { name: "category", title: "Category" },
              { name: "content", title: "Content Description" },
              { name: "weight", title: "Weight" },
              { name: "quantity", title: "Quantity" },
              { name: "status", title: "Status" },
              { name: "action", title: "Action" }
            ]}
          >
            {/* <SelectionState /> */}
            <PagingState defaultCurrentPage={0} pageSize={10} />
            <IntegratedPaging />
            <Table
              tableComponent={TableComponent}
              messages={{ noData: "..." }}
            />
            <TableHeaderRow />
            {/* <IntegratedSelection />
            <TableSelection showSelectAll={true} highlightRow={true} /> */}
            <PagingPanel containerComponent={PagingContainer} />
          </Grid>
        </div>
      </div>
    );
  }
}
export default CargoList;
