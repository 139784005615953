let SelectStyle = {
  valueContainer: (base, styles) => {
    return {
      ...base,
      height: "50px",
      backgroundColor: "#262c40",
      border: "none"
    };
  },
  container: (base, styles) => {
    return {
      ...base,
      position: "relative",
      left: "5px",
      height: "50px",
      marginTop: "5px",
    };
  },
  control: (base, styles) => {
    return {
      ...base,
      borderRadius: "0px",
      backgroundColor: "#262c40",
      border: "0px"
    };
  },
  menu: (base, styles) => {
    return {
      ...base,
      zIndex: "999",
      backgroundColor: "#262c40",
      color: "#f3f3f3",
      padding: "8px",
      border: "1px solid #353b50"
    };
  },
  dropdownIndicator: (base, styles) => {
    if(styles.isClicked){
      return {
        ...base,
        color: "#fff"
      };
    }
    return {
      ...base,
      color: "#f3f3f3"
    };
  },
  option: (base, styles) => {
    return {
      ...base,
      backgroundColor: styles.isFocused ? "#353b50" : "#f3f3f310",
      color: "#f3f3f3",
      margin: "8px 0px"
    };
  },
  singleValue: (base, styles) => {
    return {
      ...base,
      color: "#f3f3f3"
    };
  },
  placeholder: (base, styles) => {
    return {
      ...base,
      color: "#f3f3f390"
    };
  },
  input: (base, state) => {
    return {
      ...base,
      color: '#f3f3f390'
    }
  }
};
export { SelectStyle };
