import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LocationPicker from "Components/LocationPicker";
import InputBox from "Components/InputBox";
import Select from "react-select";
import { CreateWaypoint } from "logic";
// import "./style.css";
const waypointTypes = [
  {
    value: "oasis",
    label: "Oasis"
  },
  {
    value: "substation",
    label: "Sub Station"
  },
  {
    value: "hub",
    label: "Hub"
  }
];

// currently not using
class CreateWaypointDialog extends Component {
  state = {
    type: null,
    name: "",
    lat: "",
    lng: "",
    address: null,
    description: null
  };
  setNewLatLng = (lat, lng) => {
    this.setState({ lat: lat, lng: lng });
  };
  addWaypoint = wp => {
    this.state.waypoints.push(wp);
    this.setState({ showWaypointInput: false });
  };
  createWaypoint = async () => {
    let { type, name, address, description, lat, lng } = this.state;
    let response = await CreateWaypoint(
      type,
      lat,
      lng,
      name,
      address,
      description
    );
    if (response) {
      window.location.reload();
    }
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#275bb0" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              Create Route Waypoint
            </div>
          </DialogTitle>
          <DialogContent>
            {/* <p>Create Route Content Goes Here!</p> */}
            <div>
              {/* <div>RouteStepper Goes Here</div> */}
              <div
                style={{
                  padding: "10px",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "space-around"
                }}
              >
                <div>
                  <div style={{ width: "100%" }}>
                    <Select
                      options={waypointTypes}
                      placeholder="Waypoint Type"
                      styles={{
                        container: (base, state) => {
                          return {
                            ...base,
                            margin: "8px 0px"
                          };
                        },
                        valueContainer: (base, state) => {
                          return {
                            ...base,
                            height: "50px"
                          };
                        }
                      }}
                      onChange={e => {
                        this.setState({ type: e.value });
                      }}
                    />
                  </div>
                  <InputBox
                    className="CreateRouteDialogTextInputBox"
                    placeholder="Name"
                    onChange={val => {
                      this.setState({ name: val });
                    }}
                    value={this.state.name}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center"
                    }}
                  >
                    <InputBox
                      className="CreateRouteDialogTextInputBox"
                      placeholder="Lat"
                      onChange={val => {
                        this.setState({ lat: val });
                      }}
                      value={this.state.lat}
                    />
                    <InputBox
                      className="CreateRouteDialogTextInputBox"
                      placeholder="Lng"
                      onChange={val => {
                        this.setState({ lng: val });
                      }}
                      value={this.state.lng}
                    />
                  </div>
                  <textarea
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      resize: "none",
                      height: "100px"
                    }}
                    // className="CreateRouteDialogTextInputBox"
                    className="DataTextarea"
                    placeholder="Address"
                    onChange={e => {
                      this.setState({ address: e.target.value });
                    }}
                    value={this.state.address}
                  />
                  <textarea
                    className="DataTextarea"
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      resize: "none",
                      height: "100px"
                    }}
                    // className="CreateRouteDialogTextInputBox"
                    placeholder="Description"
                    onChange={e => {
                      this.setState({ description: e.target.value });
                    }}
                    value={this.state.description}
                  />
                </div>
                <div style={{ width: "450px" }}>
                  <LocationPicker setLocation={this.setNewLatLng} />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div
              onClick={this.props.handleClose}
              color="primary"
              style={{ cursor: "pointer" }}
            >
              Close
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#21458d",
                height: "50px",
                color: "#fff",
                width: "150px",
                margin: "5px 30px"
              }}
              className="RowCenter CreateRouteDialogAddWaypointButton"
              onClick={() => {
                this.createWaypoint();
              }}
            >
              Save
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default CreateWaypointDialog;
