import React, { Component } from "react";
import "./style.css";

// search input field in messages received screen
class SearchBox extends Component {
  render() {
    return (
      <div className="SearchBoxWrapper" style={{ ...this.props.rootStyle }}>
        <input
          className="DataInput"
          type="text"
          placeholder="Search..."
          style={{ ...this.props.style }}
          onChange={e => {
            this.props.setQuery(e.target.value);
          }}
        />
      </div>
    );
  }
}
export default SearchBox;
