import React from "react";

// props: [type ,url , iconsrc, rootStyle, iconContainerStyle, textColor, onClick]
const ActionButton = props => {
  return (
    <div
      onClick={props.onClick ? props.onClick : () => {}}
      style={{
        height: "47px",
        width: "246px",
        display: "flex",
        backgroundColor: "#262C40",
        cursor: "pointer",
        ...props.rootStyle
      }}
    >
      <div
        style={{
          backgroundColor: "#BBBCCD",
          height: "47px",
          width: "47px",
          minWidth: "47px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          ...props.iconContainerStyle
        }}
      >
        <img
          src={props.iconsrc}
          alt="icon"
          style={{ height: "13px", width: "13px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "17px",
            fontWeight: "bold",
            color: "#fff",
            margin: "0px"
          }}
        >
          {props.children}
        </p>
      </div>
    </div>
  );
};
const TableActionButton = props => {
  let backgroundColor = "#F95138";
  // choose background color according to type of TableActionButton
  if (props.type) {
    switch (props.type) {
      case "one": {
        backgroundColor = "#F95138";
        break;
      }
      case "two": {
        backgroundColor = "#61955A";
        break;
      }
      case "three": {
        backgroundColor = "#262c40";
        break;
      }
      default: {
      }
    }
  }
  return (
    <div
      onClick={
        props.onClick
          ? props.onClick
          : () => {
              if (props.url) {
                window.location.assign(props.url);
              }
            }
      }
      style={{
        height: "36px",
        width: "112px",
        display: "flex",
        backgroundColor: backgroundColor,
        cursor: "pointer",
        margin: "auto",
        ...props.rootStyle
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#fff"
          }}
        >
          {props.children}
        </p>
      </div>
    </div>
  );
};

const ProfileActionButton = props => {
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : "#f95138",
        color: props.textColor ? props.textColor : "#f3f3f3",
        width: props.width ? props.width : "150px",
        height: "50px",
        margin: "10px 15px",
        cursor: !props.disabled && "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "15px"
      }}
      onClick={!props.disabled ? props.onClick : () => {}}
    >
      <p style={{ margin: "0px", verticalAlign: "middle" }}>{props.children}</p>
    </div>
  );
};
export { ActionButton, TableActionButton, ProfileActionButton };
