import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import GridSearchBox from "Components/GridSearchBox";
import { GetPaymentRecords } from "logic";
import TableComponent, { PagingContainer } from "Components/TableComponent";
import { timestampFormatter } from "../../Commons";
// payments tab in finances page
class PaymentsList extends Component {
  state = { rows: [] };
  // function make api call to get payment data
  GetData = async () => {
    let data = await GetPaymentRecords();
    console.log(data);
    let data_rows = data.data.map((item, index) => {
      if (item.type === "booking") {
        return {
          index: index,
          type: item.type ? item.type.toUpperCase() : "-",
          user_name: `${
            item.paymentDetails && item.paymentDetails.user.first_name
              ? item.paymentDetails.user.first_name
              : "-"
          } ${
            item.paymentDetails && item.paymentDetails.user.last_name
              ? item.paymentDetails.user.last_name
              : "-"
          }`,
          amount: item.paymentDetails
            ? item.paymentDetails.amount.toFixed(2)
            : "-",
          bookingId: item.paymentDetails
            ? item.paymentDetails.bookingRequest.bookingId
            : "-",
          date:
            item.paymentDetails &&
            item.paymentDetails.bookingRequest.booking_date
              ? new Date(
                  item.paymentDetails.bookingRequest.booking_date
                ).toDateString()
              : "",
        };
      } else if (item.type === "credit") {
        return {
          index: index,
          type: item.type ? item.type.toUpperCase() : "-",
          user_name: `${
            item.user && item.user.first_name ? item.user.first_name : "-"
          } ${item.user && item.user.last_name ? item.user.last_name : "-"}`,
          amount: item ? item.amount.toFixed(2) : "-",
          bookingId: item.booking ? item.booking.bookingId : "-",
          date:
            item.booking && item.booking.booking_date
              ? timestampFormatter(item.booking.booking_date)
              // ? new Date(item.booking.booking_date).toDateString()
              : "",
        };
      }
    });
    this.setState({ rows: data_rows });
  };
  // executes before component render
  // call the function which make api call to get payment data
  componentDidMount() {
    this.GetData();
  }
  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          const row = {
            ...item,
            index: index + 1,
          };
          return row;
        })}
        columns={[
          { name: "index", title: "S.No" },
          { name: "type", title: "Payment Type (Booking / Due)" },
          { name: "user_name", title: "User Name (First Last)" },
          { name: "amount", title: "Amount" },
          { name: "date", title: "Booking Timestamp" },
          { name: "bookingId", title: "Booking ID" },
        ]}
      >
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <IntegratedPaging />
        <Table tableComponent={TableComponent} messages={{ noData: "..." }} />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel inputComponent={GridSearchBox} />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}
export default PaymentsList;
