import axios from "axios";
import { TOKEN_KEY, API_URL } from "Constants.js";
import { NotificationManager } from "react-notifications";
import { timestampFormatter } from "./Commons";

// convert date to age
function GetAgeFromDate(dateString) {
  if (!dateString) return false;
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
//Bookings API Calls
async function GetCurrentBookings() {
  let response = await axios.post(
    `${API_URL}/bookings/get_active`,
    {},
    { headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) } }
  );
  if (response) return response.data.data;
}
async function GetCancelledBookings() {
  let response = await axios.post(
    `${API_URL}/bookings/get_cancelled`,
    {},
    { headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) } }
  );
  if (response) return response.data.data;
}
function GetBookingsHistory(context) {
  axios
    .post(
      `${API_URL}/bookings/get_history`,
      {},
      { headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) } }
    )
    .then(res => {
      let bookings = res.data.data;
      let MappedRows = bookings.map(item => {
        return {
          id: item.id,
          booking_id: item.bookingId,
          date: item.booking_date
            ? timestampFormatter(item.booking_date)
            : "-",
          // startPoint: item.pickup_location
          //   ? `${item.pickup_location.coordinates[0]},${
          //       item.pickup_location.coordinates[1]
          //     }`
          //   : "NA",
          // dropPoint: item.drop_location
          //   ? `${item.drop_location.coordinates[0]},${
          //       item.drop_location.coordinates[1]
          //     }`
          //   : "NA",
          startPoint: item.pickup_location_name.split(",")[0] || "-",
          dropPoint: item.drop_location_name.split(",")[0] || "-",
          status: item.status ? item.status.toUpperCase() : "",
          duration: item.transit_duration,
          cost: item.total_cost
        };
      });
      context.setState({ rows: MappedRows });
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}
async function RejectApplication(id, type) {
  await axios
    .get(`${API_URL}/admin/${type}/reject/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .then(res => {
      console.log("Application rejection res:", res);
      return res
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}
function GetOwnerList(context, filter) {
  axios
    .get(`${API_URL}/admin/owner`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .then(res => {
      let owners = res.data.data;
      console.log(owners);
      let MappedRows = owners.map(item => {
        return {
          // firstName: item.first_name || "-",
          // middleName: item.middle_name || "-",
          // lastName: item.last_name || "-",
          name: `${item.first_name || "-"} ${item.last_name || "-"}`,
          address: item.address,
          phone: item.phone,
          id: item.id,
          accountStatus: item.rejected ? "Rejected" : item.verified ? "Verified" : "Not Verified",
          registeredOn: timestampFormatter(item.createdAt)
        };
      });
      context.setState({ rows: MappedRows });
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}

async function GetTrailerList(filter) {
  console.log("trailer Filter :: ", filter);
  let response = await axios
    .post(
      `${API_URL}/trailers/filter`,
      { filter: { ...filter } },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data;
}

function GetDriverList(context, filter) {
  axios
    .post(
      `${API_URL}/drivers/filter`,
      { filter: { ...filter } },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .then(res => {
      let drivers = res.data;
      let MappedRows = drivers.map(item => {
        return {
          id: item.id,
          name:
            ((item.first_name || item.last_name) &&
              `${item.first_name} ${item.middle_name ? item.middle_name : ""} ${
                item.last_name
              }`) ||
            "Not Available",
          age: GetAgeFromDate(item.dob) || "Not Available",
          licenseNumber: item.license_number || "Not Available",
          nationality: item.nationality,
          nationality: item.nationalityDetails && item.nationalityDetails.name ,
          // emergencyContact: item.emergency_contact_number,
          registeredOn: timestampFormatter(item.createdAt),
          phone: item.phone || "-",
          accountStatus: item.rejected ? "Rejected" : item.verified ? "Verified" : "Not Verified"
        };
      });
      context.setState({ rows: MappedRows });
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}

function GetUserList(context, filter, noCallback) {
  axios
    .post(
      `${API_URL}/admin/user`,
      {},
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .then(res => {
      let users = res.data.data;
      console.log(res);
      let MappedRows = users.map(item => {
        return {
          id: item.id,
          name: `${item.first_name ? item.first_name : ""} ${
            item.last_name ? item.last_name : ""
          }`,
          phone: item.phone || "Not Available",
          email: item.email || "Not Available",
          address: item.address || "Not Available",
          // accountStatus: item.verified ? "Verified" : "Not Verified",
          // noOfBooking: item.number_of_bookings || "Not Available",
          noOfBooking: item.number_of_bookings >= 0 ? item.number_of_bookings - 1 : "Not Available",
        };
      });
      if (noCallback) return MappedRows;
      context.setState({ rows: MappedRows });
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}
async function GetUserList_2(filter) {
  let response = await axios
    .post(
      `${API_URL}/admin/user`,
      {},
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response && response.data) {
    let users = response.data.data;
    let MappedRows = users.map(item => {
      return {
        id: item.id,
        name: `${item.first_name ? item.first_name : ""} ${
          item.last_name ? item.last_name : ""
        }`,
        phone: item.phone || "Not Available",
        email: item.email || "Not Available",
        status: item.verfied ? "Verified" : "Not Verified",
        address: item.address || "Not Available"
      };
    });
    return MappedRows;
  }
}
function CreateNewOwner(context, data, callback) {
  axios
    .post(`${API_URL}/admin/owner`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .then(res => {
      console.log(res);
      callback();
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}
function CreateOwnerDropdownOptions(context, key, filter) {
  axios
    .get(`${API_URL}/admin/owner`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .then(res => {
      let owners = res.data.data;
      let options = owners.map(item => {
        return {
          value: item.id,
          label: `${item.first_name ? item.first_name : ""} ${
            item.middle_name ? item.middle_name : ""
          } ${item.last_name ? item.last_name : ""}`
        };
      });
      context.state[key] = options;
      context.setState({});
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}
function CreateNationalityDropdownOptions(context, key, filter) {
  axios
    .get(`${API_URL}/countries`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .then(res => {
      let countries = res.data.data;
      let options = countries.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
      context.state[key] = options;
      context.setState({});
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}
// function CreateCompanyTypeDropdownOptions(context, key, filter) {
//   axios
//     .get(`${API_URL}/company_types`, {
//       headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
//     })
//     .then(res => {
//       let companyTypes = res.data.data;
//       let options = companyTypes.map(item => {
//         return {
//           value: item.id,
//           label: item.title
//         };
//       });
//       context.state[key] = options;
//       context.setState({});
//     })
//     .catch(e => {
//       console.log(e);
//       console.log(JSON.stringify(e));
//       NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
//     });
// }
function GetDriversOfOwner(context, key, data) {
  axios
    .post(`${API_URL}/drivers/not_assigned`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    }).then(res => {
      let drivers = res.data.data
      let options = drivers.map( item => {
        return {
          value: item.id,
          label: `${item.first_name ? item.first_name : ""} ${
            item.middle_name ? item.middle_name : ""
          } ${item.last_name ? item.last_name : ""}`
        };
      })
      if (options.length < 1) {
        console.log("No unassigned drivers");
      }
      context.state[key] = options;
      context.setState({});
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}
async function GetTrailerTypes() {
  let res = await axios.get(`${API_URL}/trailer_types`, {
    headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
  });
  let types = res.data;
  let options = types.map(item => {
    return {
      value: item.id,
      label: item.title
    };
  });
  return options;
}
async function CreateTrailerTypesDropdownOptions(context, key) {
  let options = await GetTrailerTypes();
  context.state[key] = options;
  context.setState({});
}

function CreateNewTrailer(context, data, callback) {
  let { reg_no, tonnage, typeId, owner } = data;
  console.log(data);
  axios
    .post(`${API_URL}/trailers`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .then(res => {
      console.log(res);
      // callback();
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}

async function GetOwnerProfile(uid) {
  let profile = await axios
    .post(
      `${API_URL}/admin/get_owner/${uid}`,
      {},
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (profile) return profile.data.data;
}
async function GetDocuments(type, uid) {
  switch (type) {
    case "user": {
      // console.log("ownerrrr");
      let docs = await axios
        .get(`${API_URL}/users/get_docs_by_type/${uid}`, {
          headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
        })
        .catch(e => {
          console.log(e);
          console.log(JSON.stringify(e));
          // alert(e.response.data.error);
          NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
        });
      if (docs) {
        let docFormatted = [];
        for (var data of docs.data.data) {
          console.log(data);
          let docItem = {};
          docItem["type"] = data.type;
          for (var page of data.pages) {
            docItem["url"] = page.url;
            docItem["status"] = page.status;
            docItem["page"] = page.title;
            docItem["id"] = page.id;
            docFormatted.push(docItem);
            docItem = {};
            docItem["type"] = data.type;
          }
          if (data.pages.length == 0) {
            docItem["status"] = "Not Uploaded";
            docItem["page"] = "Not Applicable";
            docFormatted.push(docItem);
          }
        }
        return docFormatted;
      }
    }
    case "owner": {
      // console.log("ownerrrr");
      let docs = await axios
        .get(`${API_URL}/owners/get_docs_by_type/${uid}`, {
          headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
        })
        .catch(e => {
          console.log(e);
          console.log(JSON.stringify(e));
          // alert(e.response.data.error);
          NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
        });
      if (docs) {
        let docFormatted = [];
        for (var data of docs.data.data) {
          console.log(data);
          let docItem = {};
          docItem["type"] = data.type;
          for (var page of data.pages) {
            docItem["url"] = page.url;
            docItem["status"] = page.status;
            docItem["page"] = page.title;
            docItem["id"] = page.id;
            docFormatted.push(docItem);
            docItem = {};
            docItem["type"] = data.type;
          }
          if (data.pages.length == 0) {
            docItem["status"] = "Not Uploaded";
            docItem["page"] = "Not Applicable";
            docFormatted.push(docItem);
          }
        }
        return docFormatted;
      }
    }
    case "driver": {
      // console.log("driverrrr");
      let docs = await axios
        .get(`${API_URL}/drivers/get_docs_by_type/${uid}`, {
          headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
        })
        .catch(e => {
          console.log(e);
          console.log(JSON.stringify(e));
          // alert(e.response.data.error);
          NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
        });
      if (docs) {
        let docFormatted = [];
        for (var data of docs.data.data) {
          console.log(data);
          let docItem = {};
          docItem["type"] = data.type;
          for (var page of data.pages) {
            docItem["url"] = page.url;
            docItem["status"] = page.status;
            docItem["page"] = page.title;
            docItem["id"] = page.id;
            docFormatted.push(docItem);
            docItem = {};
            docItem["type"] = data.type;
          }
          if (data.pages.length == 0) {
            docItem["status"] = "Not Uploaded";
            docItem["page"] = "Not Applicable";
            docFormatted.push(docItem);
          }
        }
        return docFormatted;
      }
    }
    case "trailer": {
      // console.log("trailerrr");
      let docs = await axios
        .get(`${API_URL}/trailers/get_docs_by_type/${uid}`, {
          headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
        })
        .catch(e => {
          console.log(e);
          console.log(JSON.stringify(e));
          // alert(e.response.data.error);
          NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
        });
      if (docs) {
        let docFormatted = [];
        for (var data of docs.data.data) {
          console.log(data);
          let docItem = {};
          docItem["type"] = data.type;
          for (var page of data.pages) {
            docItem["url"] = page.url;
            docItem["status"] = page.status;
            docItem["page"] = page.title;
            docItem["id"] = page.id;
            docFormatted.push(docItem);
            docItem = {};
            docItem["type"] = data.type;
          }
          if (data.pages.length == 0) {
            docItem["status"] = "Not Uploaded";
            docItem["page"] = "Not Applicable";
            docFormatted.push(docItem);
          }
        }
        return docFormatted;
      }
    }
    case "cargo": {
      // console.log("trailerrr");
      let docs = await axios
        .get(`${API_URL}/bookings/cargo/get_docs_by_type/${uid}`, {
          headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
        })
        .catch(e => {
          console.log(e);
          console.log(JSON.stringify(e));
          // alert(e.response.data.error);
          NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
        });
      if (docs) {
        let docFormatted = [];
        for (var data of docs.data.data) {
          console.log(data);
          let docItem = {};
          docItem["type"] = data.type;
          for (var page of data.pages) {
            docItem["url"] = page.url;
            docItem["status"] = page.status;
            docItem["page"] = page.title;
            docItem["id"] = page.id;
            docFormatted.push(docItem);
          }
          if (data.pages.length == 0) {
            docItem["status"] = "Not Uploaded";
            docItem["page"] = "Not Applicable";
            docFormatted.push(docItem);
          }
        }
        return docFormatted;
      }
    }
  }
}
async function GetDocumentTypes(requestee) {
  console.log("getting Types");
  let types = null;
  if (requestee) {
    types = await axios
      .get(`${API_URL}/document_types/${requestee}`, {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      })
      .catch(e => {
        console.log(e);
        console.log(JSON.stringify(e));
        // alert(e.response.data.error);
        NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
      });
  } else {
    types = await axios
      .get(`${API_URL}/document_types/`, {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      })
      .catch(e => {
        console.log(e);
        console.log(JSON.stringify(e));
        // alert(e.response.data.error);
        NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
      });
  }
  if (types) {
    return types;
  }
}
function CreateNewDriver(context, data, callback) {
  // let { reg_no, tonnage, typeId, owner } = data;
  console.log(data);
  axios
    .post(`${API_URL}/drivers`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .then(res => {
      console.log(res);
      // callback();
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}
function UploadDocument(context, data, requestee) {
  // console.log("Uploading Documents");
  // console.log(data);
  let uploadData = new FormData();
  uploadData.set("documentType", data.type);
  uploadData.set("title", data.title);
  uploadData.append("document_file", data.file);
  context.setState({ uploadInProgress: true });
  axios
    .post(
      `${API_URL}/admin/${requestee}/upload_document/${context.props.uid}`,
      uploadData,
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .then(res => {
      // console.log(res);
      // callback();
      window.location.reload();
      context.props.handleClose();
      context.setState({ uploadInProgress: false });
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      context.setState({ uploadInProgress: false });
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
}
async function GetSingleDriver(uid) {
  let profile = await axios
    .post(
      `${API_URL}/admin/get_driver/${uid}`,
      {},
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
    });
  // console.log("from logic", profile.data.data);
  if (profile) return profile.data.data;
}
async function GetSingleTrailer(uid) {
  let profile = await axios
    .get(`${API_URL}/admin/trailer/${uid}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  // console.log("from logic", profile.data.data);
  return profile.data.data;
}
async function BulkDeleteOwners(uids) {
  let response = await axios
    .post(
      `${API_URL}/admin/delete_owners`,
      { uids: uids },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  return response;
}
async function BulkDeleteTrailers(uids) {
  let response = await axios
    .post(
      `${API_URL}/admin/delete_trailers`,
      { uids: uids },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  return response;
}
async function BulkDeleteDrivers(uids) {
  let response = await axios
    .post(
      `${API_URL}/admin/delete_drivers`,
      { uids: uids },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  return response;
}
async function GetChatList() {
  let response = await axios
    .get(`${API_URL}/admin/get_chat_list`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  if (response) return response;
}
async function GetMessageThreadByUser(uid) {
  let response = await axios
    .get(`${API_URL}/admin/chat/get_messages/${uid}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  return response;
}
async function SendMessageToUserById(uid, message) {
  let response = await axios
    .post(
      `${API_URL}/admin/send_message/${uid}`,
      { message: message },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  return response;
}

async function GetWaypointList(type) {
  let response = await axios
    .get(`${API_URL}/routes/checkpoints/${type}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  return response;
}
async function CreateRoute(route) {
  console.log(route);
  let response = await axios
    .post(`${API_URL}/routes/create`, route, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  return response;
}
async function GetRoutes() {
  let response = await axios
    .get(`${API_URL}/routes`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  return response;
}
async function CreateWaypoint(type, lat, lng, name, address, description) {
  let data = {
    name: name,
    address: address,
    description: description,
    location: { type: "point", coordinates: [lat, lng] }
  };
  console.log(data);
  let response = await axios
    .post(`${API_URL}/routes/checkpoints/${type}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  return response;
}
async function GetSingleBooking(id) {
  let response = await axios
    .get(`${API_URL}/bookings/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  return response;
}
async function GetCargo(id) {
  let response = await axios
    .get(`${API_URL}/bookings/cargo/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  return response;
}
async function GetJourneysList() {
  let response = await axios
    .get(`${API_URL}/journey/list/`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  return response.data.data;
}
async function GetJourneyById(id) {
  let response = await axios
    .get(`${API_URL}/journey/get/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  return response.data.data;
}
async function GetBookingsByJourneyId(id) {
  let response = await axios
    .get(`${API_URL}/journey/get_bookings/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  return response.data.data;
}
async function GetStopsByJourneyId(id) {
  let response = await axios
    .get(`${API_URL}/journey/get_stops_of_dispatched/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
    });
  if (response) return response.data.data;
}
async function PrepareForDispatch(id) {
  let response = await axios
    .get(`${API_URL}/journey/prepare/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function CloseBookings(id) {
  let response = await axios
    .get(`${API_URL}/journey/close_bookings/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  return response.data.data;
}
async function CreateJourney(data) {
  let response = await axios
    .post(`${API_URL}/journey/create/`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function GetTrailerJourneyHistory(id) {
  let response = await axios
    .get(`${API_URL}/trailers/journey/history/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      alert(e.response.data.error);
    });
  return response.data.data;
}
async function VerifyTrailer(id) {
  let response = await axios
    .get(`${API_URL}/trailers/verify/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function VerifyOwner(id) {
  let response = await axios
    .get(`${API_URL}/owners/verify/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function VerifyDriver(id) {
  let response = await axios
    .get(`${API_URL}/drivers/verify/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function VerifyCargo(id) {
  let response = await axios
    .get(`${API_URL}/bookings/cargo/verify/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function VerifyBooking(id) {
  let response = await axios
    .get(`${API_URL}/bookings/verify/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function VerifyUser(id) {
  let response = await axios
    .get(`${API_URL}/users/verify/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function VerifyDocument(id) {
  let response = await axios
    .get(`${API_URL}/admin/verify_docs/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function RejectDocument(id, message) {
  let response = await axios
    .post(
      `${API_URL}/admin/reject_docs/${id}`,
      { message: message },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      // alert(e.response.data.error);
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function GetOverview() {
  let response = await axios
    .get(`${API_URL}/admin/get_overview`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      if (e.response) {
        NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
      }
      window.location.assign("/login");
    });
  return response.data.data;
}
async function GetUserProfile(id) {
  let response = await axios
    .get(`${API_URL}/admin/user/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) return response.data.data;
}
async function GetTrailerQR(id) {
  let response = await axios
    .get(`${API_URL}/admin/trailer/get_qr/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) {
    return response.data;
  }
}
async function GetBookingCancellationEstimate(id) {
  let response = await axios
    .get(`${API_URL}/bookings/cancellation/estimate/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) {
    return response.data;
  }
}
async function ConfirmBookingCancellation(id) {
  let response = await axios
    .get(`${API_URL}/bookings/cancellation/confirm/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) {
    return response.data;
  }
}
async function CancelJourney(id) {
  let response = await axios
    .get(`${API_URL}/journey/cancel/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(`${e.response.data.error}`, "Error", 3000);
    });
  if (response) {
    return response.data;
  }
}
async function UpdateDriver(id, data) {
  let response = await axios
    .put(`${API_URL}/drivers/${id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Updating Driver",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function UpdateTrailer(id, data) {
  let response = await axios
    .put(`${API_URL}/trailers/${id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Updating Trailer",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function UpdateOwner(id, data) {
  let response = await axios
    .put(`${API_URL}/admin/owner/${id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Updating Owner",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetBookableTrailerListInCity(city_Id, data) {
  let response = await axios
    .post(`${API_URL}/trailers/in_city/${city_Id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Fetching Trailers",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function AssignTrailerToBooking(booking_id, data) {
  let response = await axios
    .post(`${API_URL}/bookings/trailer/assign/${booking_id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Assigning Trailer",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function AllotBookingToOwner(booking_id, data) {
  let response = await axios
    .post(`${API_URL}/bookings/owner/assign/${booking_id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Allotting Owner",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetCityList() {
  let response = await axios
    .get(`${API_URL}/cities`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Fetching Cities",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetActiveCityList() {
  let response = await axios
    .get(`${API_URL}/cities?active=true`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Fetching Cities",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetPaymentRecords() {
  let response = await axios
    .post(
      `${API_URL}/payments/records`,
      {},
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Fetching Records",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetCreditListing() {
  let response = await axios
    .get(
      `${API_URL}/credit/get_all`,

      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Fetching Records",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function ToggleCreditEligibility(id, status) {
  let response = await axios
    .put(
      `${API_URL}/credit/toggle_status/${id}`,
      { status: status },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Updating Status",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetAllDues(id, status) {
  let response = await axios
    .post(
      `${API_URL}/dues/`,
      {},

      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Fetching Dues",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function ListRoutes() {
  let response = await axios
    .get(`${API_URL}/routes/`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Fetching Routes",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function NewRoute(data) {
  let response = await axios
    .post(`${API_URL}/routes/create`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Creating Route",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function NewCity(data) {
  let response = await axios
    .post(`${API_URL}/cities`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Creating City",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function MakeUserCreditEligible(id) {
  let response = await axios
    .get(`${API_URL}/credit/upgrade/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error Upgrading User",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetRefundRecords() {
  let response = await axios
    .get(`${API_URL}/payments/refunds`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting records",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function UpdateTrailerBookingAvailability(id, status, coordinates) {
  let response = await axios
    .put(
      `${API_URL}/drivers/trailer/update_booking_availability/${id}?nohash=true`,
      { available: status, location: coordinates },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting records",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function InitiateRefund(id, amount) {
  let response = await axios
    .post(
      `${API_URL}/payments/refunds/initiate/${id}`,
      { amount: amount },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error reaching server",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function UpdateCity(id, data) {
  let response = await axios
    .put(`${API_URL}/cities/${id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error updating city information",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function SetIntracityFare(id, data) {
  let response = await axios
    .put(`${API_URL}/cities/set_fare/${id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error updating fare",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function UpdateRoute(id, data) {
  let response = await axios
    .put(`${API_URL}/routes/update/${id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error updating city information",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function SetRouteFare(id, data) {
  let response = await axios
    .put(`${API_URL}/routes/update_fare/${id}`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error updating fare",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetOwnerSettlementList(id) {
  let response = await axios
    .get(`${API_URL}/payouts/fetch/owner/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetDriverSettlementList(id) {
  let response = await axios
    .get(`${API_URL}/payouts/fetch/driver/${id}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function GetAllSettlementList(type) {
  let response = await axios
    .get(`${API_URL}/payouts/fetch_all/?type=${type}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response.data;
  }
}
async function SettlePayouts(data) {
  let response = await axios
    .post(`${API_URL}/payouts/settle/`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function CreateBooking(data) {
  let response = await axios
    .post(`${API_URL}/bookings/new`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function MarkPricing(data, id) {
  let response = await axios
  .post(`${API_URL}/bookings/payment/mark/${id}`, data, {
    headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
  })
  .catch(e => {
    console.log(e);
    console.log(JSON.stringify(e));
    NotificationManager.error(
      `${e.response.data.error}`,
      "Error getting data",
      3000
    );
  });
if (response) {
  return response;
}
}
async function MarkEmergencyJourneyCancellation(id) {
  let response = await axios
    .put(
      `${API_URL}/journey/emergency/cancellation/mark_cancelled/${id}`,
      {},
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function ToggleRouteServiceStatus(id, status) {
  let response = await axios
    .put(
      `${API_URL}/routes/service/toggle/${id}?status=${status}`,
      {},
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function ToggleCityServiceStatus(id, status) {
  let response = await axios
    .put(
      `${API_URL}/cities/service/toggle/${id}?status=${status}`,
      {},
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function ChangeCreditLimitForUser(id, amount) {
  let response = await axios
    .put(
      `${API_URL}/credit/increase_limit/${id}`,
      { amount: amount },
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function MarkMessageSeen(id) {
  let response = await axios
    .put(
      `${API_URL}/admin/messages/mark_seen/${id}`,
      {},
      {
        headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
      }
    )
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function GetAlerts() {
  let response = await axios
    .get(`${API_URL}/admin/alerts`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function GetFinanceOverview() {
  let response = await axios
    .get(`${API_URL}/payments/overview`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function SendGenericNotification(data) {
  let response = await axios
    .post(`${API_URL}/notification/generic`, data, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response;
  }
}
async function GetAdminNotificationList(limit) {
  let response = await axios
    .get(`${API_URL}/notification/admin_history/${limit}`, {
      headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
    })
    .catch(e => {
      console.log(e);
      console.log(JSON.stringify(e));
      NotificationManager.error(
        `${e.response.data.error}`,
        "Error getting data",
        3000
      );
    });
  if (response) {
    return response.data.data;
  }
}

// async function GetCountries() {
//   let response = await axios.get(`${API_URL}/countries`, {
//     headers: { "x-access-token": localStorage.getItem(TOKEN_KEY) }
//   }).then((res) => {
//     console.log("GetCountries: respsone", res);
//     return res.data.data
//   }).catch((e) => {
//     console.log("GetCountries: error", e);
//     console.log(JSON.stringify(e));
//     NotificationManager.error(
//       `${e.response.data.error}`,
//       "Error getting Countries",
//       3000
//     );
//   })
//   if (response) {
//     return response;
//   }
// }

export {
  GetCurrentBookings,
  GetCancelledBookings,
  GetBookingsHistory,
  RejectApplication,
  GetOwnerList,
  GetTrailerList,
  GetDriverList,
  GetUserList,
  CreateNewOwner,
  CreateOwnerDropdownOptions,
  CreateNationalityDropdownOptions,
  // CreateCompanyTypeDropdownOptions,
  GetDriversOfOwner,
  CreateTrailerTypesDropdownOptions,
  CreateNewTrailer,
  GetOwnerProfile,
  GetDocuments,
  CreateNewDriver,
  GetDocumentTypes,
  UploadDocument,
  GetSingleDriver,
  GetAgeFromDate,
  GetSingleTrailer,
  BulkDeleteOwners,
  BulkDeleteTrailers,
  BulkDeleteDrivers,
  GetChatList,
  GetMessageThreadByUser,
  SendMessageToUserById,
  GetWaypointList,
  CreateRoute,
  GetRoutes,
  CreateWaypoint,
  GetSingleBooking,
  GetCargo,
  GetJourneysList,
  GetJourneyById,
  GetBookingsByJourneyId,
  GetStopsByJourneyId,
  PrepareForDispatch,
  CloseBookings,
  GetTrailerTypes,
  CreateJourney,
  GetTrailerJourneyHistory,
  VerifyBooking,
  VerifyCargo,
  VerifyDriver,
  VerifyOwner,
  VerifyTrailer,
  VerifyUser,
  VerifyDocument,
  RejectDocument,
  GetOverview,
  GetUserProfile,
  GetTrailerQR,
  GetBookingCancellationEstimate,
  ConfirmBookingCancellation,
  CancelJourney,
  UpdateDriver,
  UpdateTrailer,
  UpdateOwner,
  GetBookableTrailerListInCity,
  AssignTrailerToBooking,
  AllotBookingToOwner,
  GetCityList,
  GetActiveCityList,
  GetPaymentRecords,
  GetCreditListing,
  ToggleCreditEligibility,
  GetAllDues,
  ListRoutes,
  NewRoute,
  NewCity,
  MakeUserCreditEligible,
  GetRefundRecords,
  UpdateTrailerBookingAvailability,
  InitiateRefund,
  UpdateCity,
  SetIntracityFare,
  UpdateRoute,
  SetRouteFare,
  GetOwnerSettlementList,
  GetDriverSettlementList,
  GetAllSettlementList,
  SettlePayouts,
  CreateBooking,
  GetUserList_2,
  MarkPricing,
  MarkEmergencyJourneyCancellation,
  ToggleRouteServiceStatus,
  ToggleCityServiceStatus,
  ChangeCreditLimitForUser,
  MarkMessageSeen,
  GetAlerts,
  GetFinanceOverview,
  SendGenericNotification,
  GetAdminNotificationList
  // GetCountries,
};
