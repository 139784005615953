import { GetAdminNotificationList } from "./logic";

  // convert data to HH : MM : AM/PM format
export default function get12HrTimeString(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime.toUpperCase();
  }

// return URL params
export const getUrlParamsValue = (params) => {
  return new URLSearchParams(window.location.search).get(params)
}
// set page number as URL parameter
export const setPageNoParams = (context, params) => {
  const urlParamsValue = getUrlParamsValue(params)
  if (urlParamsValue) {
    context.state[params] = urlParamsValue;
    context.setState({});
    console.log("setPageNoParams", context);
  }
}
// set page number to 0
export const resetPageNo = (context, params) => {
  if (context.state[params] === 0 ) return
  context.state[params] = 0;
  context.setState({});
  window.history.replaceState(null, null, `?${params}=${0}`);
}
// set new page to state and URL parameter
export const handlePageChange = (page, context, params) => {
  context.state[params] = page;
  context.setState({});
  window.history.replaceState(null, null, `?${params}=${page}`);
}

// format timestamp, return formatted date and time
export const dateTimeformatter = (date, time = true) => {
  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const months = ["Jan", "Feb", "March", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const join_date = new Date(date)
  let month = months[join_date.getMonth()];
  let year = join_date.getFullYear();
  let day = join_date.getDate()
  let converted = day + ' ' + month + ' ' + year + ' ';
  if (time === true) {
      let time = tConvert(join_date);
      converted += time;
  }
  return converted;
}

// format time to HH : MM : AM/PM
function tConvert(date){
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; //  the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

// return unseen notifications count
export async function notificationCount() {
  let notifications = await GetAdminNotificationList(100)
  if (notifications) {
    if (localStorage.getItem("notification_timeStamp")) {
      for (let i = 0; i < notifications.length; i++) {
        if (
          notifications[i].createdAt ===
          localStorage.getItem("notification_timeStamp")
        ) {
          return i;
        }
      }
    }
    return notifications.length;
  }
  return 0;
}

// remove last column of the table which comes along with TableColumnResizing as a bug. 
export const removeLastColumn = () => {
  // since we use "TableColumnResizing" it add empty column at last, this function is a workaround of TableColumnResizing bug
  const allRows = Array.from(document.querySelectorAll("tr"))
  if (allRows) {
    allRows.map(item => {
      item.lastChild.style.width = 0
    })
  }
}

export const timestampFormatter = (timestamp) => {
  return (new Date(timestamp).toDateString() +
    " " +
    get12HrTimeString(new Date(timestamp)))
}

// export default get12HrTimeString;
