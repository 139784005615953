import React, { Component } from "react";
import "./style.css";
import { Colors } from "Constants";
// to display chat list item in messages received screen
// props: [data, selected]
class ChatListItem extends Component {
  render() {
    return (
      <div
        className="ChatListItemWrapper"
        onClick={() => {
          window.location.assign(
            `/dashboard/messages/${this.props.data.sender}`
          );
        }}
        style={{
          backgroundColor: this.props.selected
            ? Colors.background
            : Colors.body,
          cursor: "pointer",
        }}
      >
        <div className="ChatListItemSenderDataWrapper">
          <p>{this.props.data.senderName}</p>
          <p>{this.props.data.time}</p>
        </div>
        {/* {this.props.data.notificationCount > 0 && (
          <div className="ChatListItemNotificationWrapper">
            <div>{this.props.data.notificationCount}</div>
          </div>
        )} */}
      </div>
    );
  }
}
export default ChatListItem;
