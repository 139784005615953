import React, { Component } from "react";
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import GoogleMapReact from "google-map-react";
import Marker from "Components/Marker";
// import "./eagleeye.css";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
// setup google map in eagle eye screen 
class EagleEyeMap extends Component {
  state = { center: null, zoom: null };

  componentWillReceiveProps() {
    this.setState({});
  }
  static defaultProps = {
    center: {
      lat: 26.3881805,
      lng: 49.9720875,
    },
    zoom: 2,
  };
  componentWillUnmount() {
    // https://developers.google.com/maps/documentation/javascript/events#removing
    // google.maps.event.clearInstanceListeners(this.searchBox);
  }
  setFocus = (center, zoom) => {
    console.log(center, zoom);
    this.setState({ center: center, zoom: zoom });
  };
  render() {
    let data = this.props.data;
    console.log(data);
    return (
      <div
        style={{
          height: "95vh",
          width: "100%",
          position: "relative",
        }}
        className="EagleEyeMapWrapper"
      >
        {/* <Map
          google={this.props.google}
          zoom={7}
          style={{ height: "600px", width: "900px", ...this.props.style }}
          initialCenter={{
            lat: 24.7286403,
            lng: 46.3945644
          }}
        /> */}
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCartQOqwMSbRGTnIT-N07ctOZ9thS8aHs" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={{
            styles: mapStyle,
          }}
          onChildMouseEnter={(e) => {
            console.log(e);
          }}
          center={this.state.center}
          zoom={this.state.zoom}
        >
          {data.map((item) => {
            let location =
              item && item.trailer && item.trailer.last_known_location
                ? item.trailer.last_known_location.coordinates
                : null;
            console.log(location);
            if (location)
              return (
                <Marker
                  lat={location[0]}
                  lng={location[1]}
                  key={"ka1"}
                  text={"Kreyser Avrora"}
                  data={item}
                  setFocus={this.setFocus}
                />
              );
          })}
          {/* <Marker
            lat={26.3481155}
            lng={49.9061695}
            key={"ka1"}
            text={"Kreyser Avrora"}
          />
          <Marker
            lat={26.2651155}
            lng={49.4061695}
            key={"ka1"}
            text={"Kreyser Avrora"}
          />
          <Marker
            lat={26.1851155}
            lng={49.9071695}
            key={"ka1"}
            text={"Kreyser Avrora"}
          />
          <Marker
            lat={26.8451155}
            lng={49.3061695}
            key={"ka1"}
            text={"Kreyser Avrora"}
          /> */}
        </GoogleMapReact>
      </div>
    );
  }
}
export default EagleEyeMap;

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyApB4NeBXofJVbKI_GL553Zz24kLJMW-aQ"
// })(EagleEyeMap);
const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#ebe3cd",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#523735",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f1e6",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9b2a6",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#dcd2be",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ae9e90",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#93817c",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#a5b076",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#447530",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f1e6",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#fdfcf8",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#f8c967",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#e9bc62",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#e98d58",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#db8555",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#806b63",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8f7d77",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ebe3cd",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#dfd2ae",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#b9d3c2",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#92998d",
      },
    ],
  },
];
