import React from "react";
import { GetAlerts } from "logic";
import { Colors } from "Constants";

// this component is not currently used
class AlertBox extends React.Component {
  state = { alerts: null };
  getAlerts = async () => {
    let alerts = await GetAlerts();
    if (alerts) {
      this.setState({ alerts: alerts.data.data });
    }
  };
  componentDidMount() {
    this.getAlerts();
  }
  render() {
    console.log(this.state.alerts);
    let data = this.state.alerts;
    return (
      <div
        style={{
          backgroundColor: Colors.background,
          width: "100%",

          ...this.props.rootStyle
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            borderTop: "1px solid #ffffff90",
            alignItems: "center",
            width: "100%",
            height: "77px"
          }}
        >
          {/* comment */}
          <AlarmItem
            title={"Pending Trailer Assignment(s)"}
            count={data ? data.unAssignedTrailers.count : "-"}
            rootStyle={{
              borderRight: "1px solid #ffffff90",
              height: "77px",
              cursor:
                data &&
                data.unAssignedTrailers.bookings &&
                data.unAssignedTrailers.bookings.length > 0
                  ? "pointer"
                  : "initial"
            }}
            onClick={() => {
              if (
                data.unAssignedTrailers.bookings &&
                data.unAssignedTrailers.bookings.length > 0
              ) {
                window.location.assign(
                  `/dashboard/booking/${data.unAssignedTrailers.bookings[0].id}`
                );
              }
            }}
          ></AlarmItem>
          <AlarmItem
            title={"Pickup Delayed Alert(s)"}
            count={data ? data.pickupNotReached.count : "-"}
            rootStyle={{
              height: "77px",
              cursor:
                data &&
                data.pickupNotReached.bookings &&
                data.pickupNotReached.bookings.length > 0
                  ? "pointer"
                  : "initial"
            }}
            onClick={() => {
              if (
                data.pickupNotReached.bookings &&
                data.pickupNotReached.bookings.length > 0
              ) {
                window.location.assign(
                  `/dashboard/booking/${data.pickupNotReached.bookings[0].id}`
                );
              }
            }}
          ></AlarmItem>
        </div>
      </div>
    );
  }
}
export default AlertBox;
const AlarmItem = props => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-around",
        color: "#f3f3f3",
        width: "100%",
        fontSize: "17px",
        fontWeight: "bold",
        ...props.rootStyle
      }}
      onClick={props.onClick}
    >
      <div style={{ color: "#BBBCCD" }}>{props.title}</div>

      <div style={{ textAlign: "center" }}>{props.count}</div>
    </div>
  );
};
class Cycler extends React.Component {
  state = { current: null };
  componentDidMount() {
    let i = 0;
    let current = null;
    setInterval(() => {
      current = this.props.items[i].bookingId;
      i = (1 + i) % this.props.items.length;
      this.setState({ current: current });
    }, 3000);
  }
  render() {
    return <div style={{ ...this.props.rootStyle }}>{this.state.current}</div>;
  }
}
