import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
import { GetDocumentTypes, UploadDocument } from "logic";
import "./style.css";
import Driver from "../../Screens/Driver";
import SpinnerTextSwitch from "../../Components/SpinnerTextSwitch";
import { TableActionButton } from "Components/ActionButtons";
import { SelectStyle } from "componentstyle";
// popup appears on click document upload button in driver, owner, trailer, user details screens 
class UploadDocumentDialog extends Component {
  state = {
    title: "",
    type: "",
    file: "",
    documentTypes: [],
    pageOptions: [],
    uploadInProgress: false
  };
  // executes before component render
  // call the function which make api call to get required document types
  componentDidMount() {
    // console.log("Document Upload Dialog");
    this.getDocumentTypes();
  }
  // function make api call to get required document types
  getDocumentTypes = async () => {
    let types = await GetDocumentTypes(this.props.type);
    let options = [];
    for (let type of types.data) {
      options.push({
        label: type.type_name,
        value: type.id,
        pages: type.page_titles ? type.page_titles : []
      });
    }
    this.state["documentTypes"] = options;
    this.setState({});
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              Upload Document
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            <div>
              <div
                style={{
                  padding: "10px",
                  boxSizing: "border-box",
                  minHeight: "500px"
                }}
              >
                <Select
                  classNamePrefix="DocTypeSelect"
                  options={this.state.documentTypes}
                  placeholder={"Document Type"}
                  styles={SelectStyle}
                  onChange={sel => {
                    console.log("Sel", sel);
                    this.setState({
                      title: null,
                      type: sel.value,
                      pageOptions:
                        sel.pages.length > 0
                          ? sel.pages.map(item => {
                              return { label: item, value: item };
                            })
                          : [{ label: sel.label, value: sel.label }]
                    });
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "8px",
                    marginRight: "8px"
                  }}
                >
                  <Select
                    classNamePrefix="DocTypeSelect"
                    options={this.state.pageOptions}
                    placeholder={"Page"}
                    styles={{
                      ...SelectStyle,
                      container: (base, styles) => {
                        return {
                          ...base,
                          position: "relative",
                          left: "5px",
                          height: "40px",
                          width: "400px"
                        };
                      }
                    }}
                    onChange={sel => {
                      this.setState({ title: sel.value });
                    }}
                  />
                  <div
                    className="ColumnCenter"
                    style={{ height: "50px", marginLeft: "8px" }}
                  >
                    {" "}
                    <input
                      type="file"
                      onChange={e => {
                        this.setState({ file: e.target.files[0] });
                      }}
                      style={{ marginLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                onClick={this.props.handleClose}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={() => {
                  let requestee = null;
                  switch (this.props.type) {
                    case "drivers":
                      requestee = "driver";
                      break;
                    case "trailers":
                      requestee = "trailer";
                      break;
                    case "owners":
                      requestee = "owner";
                      break;
                    default: {
                    }
                  }
                  UploadDocument(this, this.state, requestee);
                }}
              >
                <SpinnerTextSwitch
                  spin={this.state.uploadInProgress}
                  color={"#fff"}
                >
                  Upload
                </SpinnerTextSwitch>
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default UploadDocumentDialog;
