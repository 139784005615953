import React from "react";

// olly logo in login screen and navigation bar
const SquareLogo = (props) => {
  return (
    <div>
      <img
        src={require("Assets/Logos/OllyLogo.png")}
        style={{ width: "150px", ...props.style }}
        alt="olly_logo"
      />
    </div>
  );
};
export default SquareLogo;
