import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import { GetCreditListing, ToggleCreditEligibility } from "logic";
import GridSearchBox from "Components/GridSearchBox";
import Switch from "@material-ui/core/Switch";
import { TableActionButton } from "Components/ActionButtons";
import TableComponent, { PagingContainer } from "Components/TableComponent";
// credit listing section component in finances screen, listing is done using @devexpress/dx-react-grid library
class CreditsList extends Component {
  state = { rows: [] };

  // function make api call to get credits data and set row state
  GetData = async () => {
    let data = await GetCreditListing();
    console.log(data);
    let data_rows = data.data.map((item, index) => {
      return {
        index: index,
        user: `${item.user.first_name ? item.user.first_name : "-"} ${
          item.user.last_name ? item.user.last_name : "-"
        }`,
        current: item.current,
        max: item.max,
        active: item.active ? "Active" : "Inactive",
        user_id: item.user.id,
        active_toggle: item.active,
        id: item.id,
      };
    });
    this.setState({ rows: data_rows });
  };
  toggleCredit = async (id, status) => {
    await ToggleCreditEligibility(id, status);
    this.GetData();
  };
  // to enable table row click action
  TableRowClickable = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      // onClick={() => {
      //   window.location.assign(`/dashboard/user/${tableRow.row.user_id}`)
      // }}
      // className={"clickable"}
    />
  );
  componentDidMount() {
    this.GetData();
  }
  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          const row = {
            ...item,
            index: index + 1,
            toggle: (
              <Switch
                checked={item.active_toggle ? true : false}
                inputProps={{ "aria-label": "primary checkbox" }}
                onClick={(e) => {
                  //   this.state.rows[index].active_toggle = !this.state.rows[index]
                  //     .active_toggle;
                  //   this.setState({});
                  this.toggleCredit(
                    item.user_id,
                    !this.state.rows[index].active_toggle
                  );
                }}
              />
            ),
            action: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TableActionButton
                  rootStyle={{ margin: "0px 1px" }}
                  onClick={() => {
                    this.props.openEdit(item.user_id, item.max);
                  }}
                >
                  Edit
                </TableActionButton>
                {/* <TableActionButton
                  rootStyle={{ margin: "0px 1px" }}
                  url={`/dashboard/user/${item.user_id}`}
                  type="two"
                >
                  View Details
                </TableActionButton> */}
              </div>
            ),
          };
          return row;
        })}
        columns={[
          { name: "index", title: "S.No" },
          { name: "user", title: "Name (First Middle Last)" },
          { name: "current", title: "Credit Used" },
          { name: "max", title: "Maximum Credit Allotted" },
          { name: "active", title: "Current Credit Eligibility" },
          { name: "toggle", title: "Change Eligibility" },
          { name: "action", title: "Actions" },
        ]}
      >
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <IntegratedPaging />

        <Table tableComponent={TableComponent} rowComponent={this.TableRowClickable} messages={{ noData: "..." }} />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel inputComponent={GridSearchBox} />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}
export default CreditsList;
