import React from "react";
import { ClipLoader } from "react-spinners";

// spinner inside button
// props: {color}
const SpinnerTextSwitch = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {props.spin ? (
        <ClipLoader
          //   css={override}
          sizeUnit={"px"}
          size={20}
          color={props.color ? props.color : "#275bb0"}
          loading={true}
        />
      ) : (
        props.children
      )}
    </div>
  );
};
export default SpinnerTextSwitch;
