import React from "react";

// popup displayed on click view button corresponding to document, show the uploaded document
// props: {url, showActionButtons, onReject, onClose, onConfirm, confirmButtonText, cancelButtonText}
const ViewDocumentDialog = props => {
  return (
    <div
      style={{
        height: "auto",
        maxHeight: "600px",
        width: "600px",
        border: "3px solid #262c40",
        borderRadius: "5px",
        // padding: "10px",
        paddingBottom: "40px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        backgroundColor: ""
      }}
    >
      <img
        src={props.url}
        style={{ width: "100%", objectFit: "contain", maxHeight: "600px" }}
        alt="document image"
      />

      {props.showActionButtons && (
        <div
          style={{
            display: "flex",
            width: "100%",
            position: "absolute",
            bottom: "0px",
            left: "0px",
            right: "0px",
            justifyContent: "flex-end"
          }}
        >
          {" "}
          <div
            onClick={() => {
              props.onReject && props.onReject();
              props.onClose && props.onClose();
            }}
            style={{
              height: "45px",
              width: "100%",
              backgroundColor: "#262c40",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // margin: "5px",
              color: "#fff",
              // borderRadius: "40px",
              cursor: "pointer"
            }}
          >
            {props.cancelButtonText ? props.cancelButtonText : "Reject"}
          </div>
          {props.type !== "user" && 
            <div
              onClick={() => {
                props.onConfirm && props.onConfirm();
                props.onClose && props.onClose();
              }}
              style={{
                height: "45px",
                width: "100%",
                backgroundColor: "#f95138",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // margin: "5px",
                color: "#fff",
                // borderRadius: "40px",
                cursor: "pointer"
              }}
            >
              {props.confirmButtonText ? props.confirmButtonText : "Accept"}
            </div>
          } 
        </div>
      )}
    </div>
  );
};

export default ViewDocumentDialog;
