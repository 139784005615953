import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SpinnerTextSwitch from "../../Components/SpinnerTextSwitch";
import {
  DriverOverview,
  DriverDocumentManagement,
  DriverEmergencyContact,
  DriverAccountActions,
  UploadDocumentDialog,
  NewDriverDialog
} from "Sections";
import { GetSingleDriver, VerifyDriver, UpdateDriver, RejectApplication } from "logic";
import Loader from "Components/Loader";
import { confirmAlert } from "react-confirm-alert";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import { ProfileActionButton } from "Components/ActionButtons";
const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});
class Driver extends Component {
  state = {
    expanded: "overview",
    showUploadDocumentDialog: false,
    uid: this.props.match.params.id ? this.props.match.params.id : 0,
    profile: null,
    showEditDriverModal: false,
    showVerifyDriverSpinner: false,
    rejectDriverSpin: false,
    updateInProgress: false
  };

  // handle corresponding popup actions
  openUploadDocumentDialog = () => {
    this.setState({ showUploadDocumentDialog: true });
  };
  closeUploadDocumentDialog = () => {
    this.setState({ showUploadDocumentDialog: false, updateAvailable: true });
  };
  openEditDriverDialog = () => {
    this.setState({ showEditDriverModal: true });
  };
  closeEditDriverDialog = () => {
    this.setState({ showEditDriverModal: false, updateInProgress: false });
  };
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  // function which make api call to get details of a driver
  GetDriver = async () => {
    let driver = await GetSingleDriver(this.props.match.params.id);
    this.setState({ profile: driver });
  }

  // executes on click confirm button in verify driver confirmation modal
  // function which make api call to verify driver
  verifyDriver = async () => {
    this.setState({ showVerifyDriverSpinner: true });
    let response = await VerifyDriver(this.props.match.params.id);
    await this.GetDriver();
    this.setState({ showVerifyDriverSpinner: false });
  };

  // executes on click update button in edit driver's profile
  // function which make api call to update driver's profile
  updateDriver = async data => {
    console.log(data);
    this.setState({ updateInProgress: true });
    let response = await UpdateDriver(this.props.match.params.id, data);
    this.GetDriver();
    this.closeEditDriverDialog();
  };

  // executes on click confirm rejection button
  // make api call to reject application
  RejectDriver = async () => {
    this.setState({ rejectDriverSpin: true });
    await RejectApplication(this.props.match.params.id, "driver");
    await this.GetDriver();
    this.setState({ rejectDriverSpin: false });
  };

  // to render confirm verify driver modal
  confirmVerifyDriver = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Are You Sure?"}
            message={"This action cannot be undone"}
            onConfirm={this.verifyDriver}
            onClose={onClose}
          />
        );
      }
    });
  };
  ConfirmRejectDriver = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Confirm Rejection"}
            message={"Are you sure you want to reject this user's application?"}
            cancelButtonText={"No"}
            confirmButtonText={"Yes"}
            onConfirm={this.RejectDriver}
            onClose={onClose}
          />
        );
      }
    });
  };

  // executes before component render
  // call the function which make api call to get a driver's details
  componentDidMount() {
    this.GetDriver();
  }

  // to render driver details component
  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    const driverProfile = this.state.profile
    if (!this.state.profile) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    const disableApplicaionRejection = this.state.profile.verified ? true : this.state.profile.rejected ? true : this.state.profile.documentRejected ? false : true 
    const disableVerifyDriver = !!! (driverProfile.iqama_number && driverProfile.truck_plate_number && driverProfile.license_expiry && driverProfile.passport_number )
    // console.log("disableVerifyDriver>>", disableVerifyDriver);
    // console.log("driverProfile.iqama_number >> ", driverProfile.iqama_number);
    // console.log("driverProfile.truck_plate_number >> ", driverProfile.truck_plate_number);
    // console.log("driverProfile.license_expiry >> ", driverProfile.license_expiry);
    // console.log("driverProfile.passport_number >> ", driverProfile.passport_number);
    // console.log("driverProfile.bank_name >> ", driverProfile.bank_name);
    // console.log("driverProfile.bank_address >> ", driverProfile.iqama_number);
    // console.log("driverProfile.iban_number >> ", driverProfile.iban_number);
    // console.log("driverProfile.bank_beneficiary >> ", driverProfile.bank_beneficiary);
    return (
      <div
        style={{
          padding: "0px 20px 20px 20px",
          backgroundColor: "#353b50",
          minHeight: "100vh"
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <ProfileActionButton
            backgroundColor={ disableApplicaionRejection  ? "#a1a1a1" : "#ccc"}
            textColor={"#454545"}
            onClick={() => {
              this.ConfirmRejectDriver();
            }}
            disabled={disableApplicaionRejection}
          >
            <SpinnerTextSwitch
              spin={this.state.rejectDriverSpin}
              color={"#454545"}
            >
              {this.state.profile.rejected ? "Rejected" : "Reject"}
            </SpinnerTextSwitch>
          </ProfileActionButton>
          {!this.state.profile.verified ? (
            <ProfileActionButton
              backgroundColor={ disableVerifyDriver  ? "#a1a1a1" : "#ccc"}
              textColor={"#454545"}
              onClick={() => {
                this.confirmVerifyDriver();
              }}
              disabled={disableVerifyDriver}
            >
              <SpinnerTextSwitch
                color={"#454545"}
                spin={this.state.showVerifyDriverSpinner}
              >
                Verify Driver
              </SpinnerTextSwitch>
            </ProfileActionButton>
          ) : (
            <ProfileActionButton
              backgroundColor="rgb(76, 175, 80,0.8)"
              textColor={"#fff"}
              disabled
            >
              Verified
            </ProfileActionButton>
          )}
        </div>
        <div className={classes.root} style={{ backgroundColor: "#262C40" }}>
          <ExpansionPanel
            expanded={expanded === "overview"}
            onChange={this.handleChange("overview")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#f3f3f3" }} />}
              style={{ backgroundColor: "#262C40" }}
            >
              <h6 style={{ color: "#f3f3f3" }}>Overview</h6>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{
                backgroundColor: "#353b50",
                border: "1px solid #262c40"
              }}
            >
              <DriverOverview
                uid={this.state.uid}
                data={this.state.profile}
                openEditDialog={this.openEditDriverDialog}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel2"}
            onChange={this.handleChange("panel2")}
            style={{ backgroundColor: "#262C40" }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#f3f3f3" }} />}
            >
              <h6 style={{ color: "#f3f3f3" }}>Document Management</h6>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{
                backgroundColor: "#353b50",
                border: "1px solid #262c40"
              }}
            >
              <DriverDocumentManagement
                openUploadDocumentDialog={this.openUploadDocumentDialog}
                uid={this.state.uid}
                fetchDriverOverview={this.GetDriver}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === "panel3"}
            onChange={this.handleChange("panel3")}
            style={{ backgroundColor: "#262C40" }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#f3f3f3" }} />}
            >
              <h6 style={{ color: "#f3f3f3" }}>
                Emergency Contact Information
              </h6>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{
                backgroundColor: "#353b50",
                border: "1px solid #262c40"
              }}
            >
              <DriverEmergencyContact uid={this.state.uid} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {/* <ExpansionPanel
            expanded={expanded === "panel4"}
            onChange={this.handleChange("panel4")}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h6>Account Actions</h6>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <DriverAccountActions />
            </ExpansionPanelDetails>
          </ExpansionPanel> */}
        </div>
        <UploadDocumentDialog
          open={this.state.showUploadDocumentDialog}
          handleClose={this.closeUploadDocumentDialog}
          type={"drivers"}
          uid={this.state.uid}
        />{" "}
        <NewDriverDialog
          open={this.state.showEditDriverModal}
          handleClose={this.closeEditDriverDialog}
          type={"edit"}
          onSave={this.updateDriver}
          preloaded={this.state.profile}
          driverid={this.props.match.params.id}
          spin={this.state.updateInProgress}
        />
      </div>
    );
  }
}
Driver.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Driver);
