import React, { Component } from "react";
import MessageBox from "Components/MessageBox";
import MessageInputBox from "Components/MessageInputBox";
import {
  GetMessageThreadByUser,
  SendMessageToUserById,
  MarkMessageSeen
} from "logic";

// message thread section in Messages Received Page
class MessageThread extends Component {
  state = { mThread: [] };
  // executes before component render
  // call the function which make api call to get messages send by user 
  componentDidMount() {
    // let uid = this.props.match.params.uid;
    let uid = this.props.uid;
    // executes in every 5 seconds
    window.setInterval(() => {
      (async () => {
        // call the function which make api call to get messages send by user having id that passed
        let mThread = await GetMessageThreadByUser(uid);
        mThread = mThread.data.data;
        if (mThread.length !== this.state.mThread.length) {
          this.setState({ mThread: mThread });
          var container = document.getElementById("MessageThreadHolder");
          console.log("marking", container.scrollHeight, container.scrollTop);
          MarkMessageSeen(uid);
        }
      })();
    }, 5000);
    (async () => {
      let mThread = await GetMessageThreadByUser(uid);
      mThread = mThread.data.data;
      this.setState({ mThread: mThread });
    })();
  }
  // Called immediately after updating occurs. Not called for the initial render
  // to auto scroll to bottom of container when a message is received
  componentDidUpdate() {
    var container = document.getElementById("MessageThreadHolder");
    container.scrollTop = container.scrollHeight;
  }
  // execute on click send button
  // function make api call to send message 
  sendMessage = async message => {
    let uid = this.props.uid;
    let mStatus = await SendMessageToUserById(uid, message);
    // if message send successfully, make api call to get messages and set state nThread
    if (mStatus) {
      (async () => {
        let mThread = await GetMessageThreadByUser(uid);
        mThread = mThread.data.data;
        this.setState({ mThread: mThread });
      })();
    }
  };
  render() {
    console.log(this.props);
    this.props.setSelected(this.props.uid);
    return (
      <div
        style={{
          width: "100%",
          margin: "0px 50px",
          position: "relative",
          maxHeight: "75vh",
          overflowY: "scroll"
        }}
        id={"MessageThreadHolder"}
      >
        {this.state.mThread.map((item, i, arr) => {
          // console.log(arr);
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent:
                  item.sender_type == "admin" ? "flex-end" : "flex-start"
              }}
            >
              <MessageBox
                data={item}
                rootStyle={{
                  backgroundColor:
                    item.sender_type == "admin" ? "#BBBCCD" : "#262c40",
                  color: item.sender_type == "admin" ? "#000" : "#bbbccd"
                }}
              />
            </div>
          );
        })}
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "380px",
            right: "20px"
          }}
        >
          <MessageInputBox sendMessage={this.sendMessage} />
        </div>
      </div>
    );
  }
}
export default MessageThread;
