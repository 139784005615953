import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "react-select";
// import "./style.css";
// import { GetCityList, NewRoute, UpdateRoute, SetRouteFare } from "logic";
import { TableActionButton } from "Components/ActionButtons";
import { SelectStyle } from "componentstyle";
import { MarkPricing } from "../../logic";

// this component will displayed on click "Mark Pricing" button, to mark price for service 
class CreatePricingDialog extends Component {
    state = {
        type: "none",
        cost: "",
        olly_percentage: "",
        driver_percentage: "",
        owner_percentage: "",
        tax_percentage: "",
        insurance: "",
        types: [{label: "Choose default pricing", value: "default"}, {label: "Enter pricing manually", value: "custom"}]
    };
    // executes on click save button 
    // function makes api call to set pricing
    SetPricing= async () => {
        let response = await MarkPricing({
            pricing: this.state.type.value,
            cost: this.state.type.value ==="custom" ? this.state.cost : this.props.fare.cost === 0 ? this.state.cost : this.props.fare.cost,
            olly_percentage: this.state.type.value ==="custom" ? this.state.olly_percentage : this.props.fare.ollys_cut,
            driver_percentage: this.state.type.value ==="custom" ? this.state.driver_percentage : this.props.fare.drivers_cut,
            owner_percentage: this.state.type.value ==="custom" ? this.state.owner_percentage : this.props.fare.owners_cut,
            tax_percentage: this.state.type.value ==="custom" ? this.state.tax_percentage : this.props.fare.tax,
            insurance: this.state.type.value ==="custom" ? this.state.insurance : this.props.fare.insurance
        }, this.props.id);
        if (response) {
            window.location.reload();
        }
    };

    // executes when component receive new prop
    componentWillReceiveProps = props => {
        console.log(props);
        if (props.data) {
            this.setState(props.data);
        }
    };
    render() {
        {console.log(this.state)}
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle
                        id="max-width-dialog-title"
                        style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
                    >
                        <div
                            style={{
                                width: "100%",
                                backgroundColor: "#none",
                                height: "100%",
                                color: "#fff"
                            }}
                        >Set Pricing
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ backgroundColor: "#353b50", paddingTop: "20px" }}>
                        <div style={{}}>
                            <React.Fragment>
                                <Select
                                    classNamePrefix="Route-Select"
                                    options={this.state.types}
                                    placeholder={"Choose Pricing"}
                                    styles={SelectStyle}
                                    onChange={opt => {
                                        this.setState({ type: opt });
                                    }}
                                    value={this.state.type}
                                />
                            </React.Fragment>
                        </div>
                        <React.Fragment>
                            {" "}
                            <p
                                style={{
                                    marginBottom: "0px",
                                    marginLeft: "5px",
                                    color: "#999"
                                }}
                            >
                                Cost:
                            </p>
                            <input
                                className="DataInput"
                                type="number"
                                style={{
                                    width: "200px",
                                    border: "1px solid #ddd",
                                    //   marginLeft: "5px",
                                    padding: "0px 10px",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    margin: "5px"
                                }}
                                placeholder="Cost"
                                disabled={this.state.type.value !== "custom" && this.props.fare.cost !== 0}
                                onChange={e => {console.log("changing", e.target.value);
                                    this.setState({ cost: e.target.value });
                                }}
                                value={this.state.type.value === "default" && this.props.fare.cost !== 0 ? this.props.fare.cost.toFixed(2) : this.state.cost}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div style={{ marginRight: "2px" }}>
                                    <p
                                        style={{
                                            marginBottom: "0px",
                                            marginLeft: "5px",
                                            color: "#999"
                                        }}
                                    >
                                        Ollys %:
                                    </p>
                                    <input
                                        className="DataInput"
                                        type="number"
                                        style={{
                                            width: "200px",
                                            border: "1px solid #ddd",
                                            //   marginLeft: "5px",
                                            padding: "0px 10px",
                                            width: "100%",
                                            boxSizing: "border-box",
                                            margin: "5px"
                                        }}
                                        placeholder="Olly's %"
                                        disabled={this.state.type.value !== "custom"}
                                        onChange={e => {
                                            this.setState({ olly_percentage: e.target.value });
                                        }}
                                        value={this.state.type.value === "default" ? this.props.fare.ollys_cut : this.state.olly_percentage}
                                    />
                                </div>
                                <div>
                                    {" "}
                                    <p
                                        style={{
                                            marginBottom: "0px",
                                            marginLeft: "5px",
                                            color: "#999"
                                        }}
                                    >
                                        Owners's %:
                                    </p>
                                    <input
                                        className="DataInput"
                                        type="number"
                                        style={{
                                            width: "200px",
                                            border: "1px solid #ddd",
                                            //   marginLeft: "5px",
                                            padding: "0px 10px",
                                            width: "100%",
                                            boxSizing: "border-box",
                                            margin: "5px"
                                        }}
                                        placeholder="Owner's %"
                                        disabled={this.state.type.value !== "custom"}
                                        onChange={e => {
                                            this.setState({ owner_percentage: e.target.value });
                                        }}
                                        value={this.state.type.value === "default" ? this.props.fare.owners_cut : this.state.owner_percentage}
                                    />
                                </div>
                                <div style={{ marginLeft: "2px" }}>
                                    <p
                                        style={{
                                            marginBottom: "0px",
                                            marginLeft: "5px",
                                            color: "#999"
                                        }}
                                    >
                                        Drivers's %:
                                    </p>
                                    <input
                                        className="DataInput"
                                        type="number"
                                        style={{
                                            width: "200px",
                                            border: "1px solid #ddd",
                                            //   marginLeft: "5px",
                                            padding: "0px 10px",
                                            width: "100%",
                                            boxSizing: "border-box",
                                            margin: "5px"
                                        }}
                                        placeholder="Driver's %"
                                        disabled={this.state.type.value !== "custom"}
                                        onChange={e => {
                                            this.setState({ driver_percentage: e.target.value });
                                        }}
                                        value={this.state.type.value === "default" ? this.props.fare.drivers_cut : this.state.driver_percentage}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div style={{ width: "100%" }}>
                                    <p
                                        style={{
                                            marginBottom: "0px",
                                            marginLeft: "5px",
                                            color: "#999"
                                        }}
                                    >
                                        Tax %
                                    </p>
                                    <input
                                        className="DataInput"
                                        type="number"
                                        style={{
                                            width: "200px",
                                            border: "1px solid #ddd",
                                            //   marginLeft: "5px",
                                            padding: "0px 10px",
                                            width: "100%",
                                            boxSizing: "border-box",
                                            margin: "5px"
                                        }}
                                        placeholder="Tax %"
                                        disabled={this.state.type.value !== "custom"}
                                        onChange={e => {
                                            this.setState({ tax_percentage: e.target.value });
                                        }}
                                        value={this.state.type.value === "default" ? this.props.fare.tax : this.state.tax_percentage}
                                    />
                                </div>

                                <div style={{ marginLeft: "2px", width: "100%" }}>
                                    <p
                                        style={{
                                            marginBottom: "0px",
                                            marginLeft: "5px",
                                            color: "#999"
                                        }}
                                    >
                                        Insurance Cost:
                                    </p>
                                    <input
                                        className="DataInput"
                                        type="number"
                                        style={{
                                            width: "200px",
                                            border: "1px solid #ddd",
                                            //   marginLeft: "5px",
                                            padding: "0px 10px",
                                            width: "100%",
                                            boxSizing: "border-box",
                                            margin: "5px"
                                        }}
                                        placeholder="Insurance"
                                        disabled={this.state.type.value !== "custom"}
                                        onChange={e => {
                                            this.setState({ insurance: e.target.value });
                                        }}
                                        value={this.state.type.value === "default" ? this.props.fare.insurance : this.state.insurance}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
                        <div style={{ display: "flex", padding: "20px" }}>
                            <TableActionButton
                                rootStyle={{ margin: "2px" }}
                                type="three"
                                onClick={this.props.handleClose}
                            >
                                Close
                            </TableActionButton>
                            <TableActionButton
                                rootStyle={{ margin: "2px" }}
                                onClick={this.state.type.value === "none" ? () => {} : e => {
                                        this.SetPricing();
                                }}
                            >
                                Save
                            </TableActionButton>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
export default CreatePricingDialog;
