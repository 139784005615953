import React from "react";
import "./style.css";

// search component
// props: {value, onValueChange}
const GridSearchBox = props => {
  return (
    <div
      style={{
        height: "47px",
        width: "360px",
        backgroundColor: "#262c40",
        position: "relative"
      }}
    >
      <input
        className="GridSearchBoxInput DataInput"
        type="text"
        onChange={e => {
          props.onValueChange(e.target.value);
        }}
        value={props.value}
        placeholder={"Search"}
        style={{
          borderBottom: "none",
          fontSize: "15px",
          lineHeight: "22px",
          paddingLeft: "14px",
          paddingRight: "48px",
          boxSizing: "border-box",
          margin: "0px",
          height: "47px",
          color: "#fff"
        }}
      />
      <div
        style={{
          height: "47px",
          width: "47px",
          position: "absolute",
          top: "0px",
          bottom: "0px",
          right: "0px",
          backgroundColor: "#BBBCCD",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img
          src={require("Assets/Icons/search.svg")}
          style={{
            height: "21px",
            width: "21px",
            margin: "auto"
          }}
          alt="search icon"
        />
      </div>
    </div>
  );
};
export default GridSearchBox;

// currently not used
const GridSearchBoxLight = props => {
  return (
    <div
      style={{
        height: "47px",
        width: "360px",
        backgroundColor: "#fff",
        border: "1px solid #262c40",
        position: "relative"
      }}
    >
      <input
        className="GridSearchBoxInput DataInput"
        type="text"
        onChange={e => {
          props.onValueChange(e.target.value);
        }}
        value={props.value}
        placeholder={"Search"}
        style={{
          borderBottom: "none",
          fontSize: "15px",
          lineHeight: "22px",
          paddingLeft: "14px",
          paddingRight: "48px",
          boxSizing: "border-box",
          margin: "0px",
          height: "47px",
          color: "#000"
        }}
      />
      <div
        style={{
          height: "47px",
          width: "47px",
          position: "absolute",
          top: "0px",
          bottom: "0px",
          right: "0px",
          backgroundColor: "#BBBCCD",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img
          src={require("Assets/Icons/search.svg")}
          style={{
            height: "21px",
            width: "21px",
            margin: "auto"
          }}
          alt="search icon"
        />
      </div>
    </div>
  );
};
export { GridSearchBoxLight };
