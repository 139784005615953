import axios from "axios";
import { API_URL, TOKEN_KEY } from "Constants.js";

// to store access tocken in local storage after successfull authentication
let setAccessToken = token => {
  console.log("setting token", token, TOKEN_KEY);
  return localStorage.setItem(TOKEN_KEY, token);
};
export default {
  // authentication api setup
  login: async (phone, password) => {
    console.log(phone, password, API_URL);
    let res = await axios
      .post(`${API_URL}/auth/admin/login`, {
        phone: phone,
        password: password
      })
      .catch(err => {
        return { data: { status: 401 } };
      });
    if (res.data.status == 200) {
      setAccessToken(res.data.data.access_token);
      return true;
    } else {
      return false;
    }
  }
};
