import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
import { GetWaypointList, CreateRoute } from "logic";
import "./style.css";
const waypointTypes = [
  {
    value: "oasis",
    label: "Oasis"
  },
  {
    value: "substation",
    label: "Sub Station"
  },
  {
    value: "hub",
    label: "Hub"
  }
];
//Depricated New Route Dialog Implemented
class CreateRouteDialog extends Component {
  state = {
    showWaypointInput: false,
    waypointList: [],
    routeName: "",
    waypoints: [],
    newWaypointName: "",
    newWaypointType: "",
    newWaypointId: "",
    newWaypointLat: "",
    newWaypointLng: "",
    origin: null,
    destination: null,
    allWaypoints: []
  };
  setNewLatLng = (lat, lng) => {
    this.setState({ newWaypointLat: lat, newWaypointLng: lng });
  };
  addWaypoint = wp => {
    this.state.waypoints.push(wp);
    this.setState({ showWaypointInput: false });
  };
  setWaypointList = async type => {
    // console.log(type);
  };
  componentDidMount() {
    // this.fetchWaypoints();
    this.fetchAllWaypoints();
  }
  fetchWaypoints = async () => {
    let waypoints = await GetWaypointList(this.state.newWaypointType);
    // console.log(waypoints);
    if (waypoints) {
      waypoints = waypoints.data.data;
      let options = waypoints.map(item => {
        return {
          label: item.name,
          value: item.id,
          lat: item.location.coordinates[0],
          lng: item.location.coordinates[1],
          id: item.id
        };
      });
      this.setState({ waypointList: options });
    }
  };
  fetchAllWaypoints = async () => {
    let waypoints = await GetWaypointList("all");
    // console.log(waypoints);
    if (waypoints) {
      waypoints = waypoints.data.data;
      let options = waypoints.map(item => {
        return {
          label: item.name,
          value: item.id,
          lat: item.location.coordinates[0],
          lng: item.location.coordinates[1],
          id: item.id
        };
      });
      this.setState({ allWaypoints: options });
    }
  };
  componentWillUpdate() {}
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#275bb0" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              Create New Route
            </div>
          </DialogTitle>
          <DialogContent>
            {/* <p>Create Route Content Goes Here!</p> */}
            <div>
              {/* <div>RouteStepper Goes Here</div> */}
              <div style={{ padding: "10px", boxSizing: "border-box" }}>
                <InputBox
                  className="CreateRouteDialogTextInputBox"
                  placeholder="Route Name"
                  onChange={val => {
                    this.setState({ routeName: val });
                  }}
                  value={this.state.routeName}
                />
                <div style={{ width: "100%" }}>
                  <Select
                    options={this.state.allWaypoints}
                    placeholder="Origin"
                    styles={{
                      container: (base, state) => {
                        return {
                          ...base,
                          margin: "8px 0px"
                        };
                      },
                      valueContainer: (base, state) => {
                        return {
                          ...base,
                          height: "50px"
                        };
                      }
                    }}
                    onChange={e => {
                      const wpdata = {
                        name: e.label,
                        type: "origin",
                        lat: e.lat,
                        lng: e.lng,
                        id: e.id
                      };
                      this.setState({ origin: wpdata });
                    }}
                  />
                  <Select
                    options={this.state.allWaypoints}
                    placeholder="Destination"
                    styles={{
                      container: (base, state) => {
                        return {
                          ...base,
                          margin: "8px 0px"
                        };
                      },
                      valueContainer: (base, state) => {
                        return {
                          ...base,
                          height: "50px"
                        };
                      }
                    }}
                    onChange={e => {
                      const wpdata = {
                        name: e.label,
                        type: "destination",
                        lat: e.lat,
                        lng: e.lng,
                        id: e.id
                      };
                      this.setState({ destination: wpdata });
                    }}
                  />
                </div>
                <div>
                  {this.state.waypoints.map(item => {
                    return (
                      <div className="CreateRouteDialogWaypointListWrapper">
                        <p>
                          <span>Type:</span>
                          {item.type}
                        </p>
                        <p>
                          <span>Name:</span>
                          {item.name}
                        </p>
                        <p>
                          <span>Lat:</span>
                          {item.lat}
                          <span>Lng:</span>
                          {item.lng}
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#21458d",
                    height: "50px",
                    color: "#fff",
                    width: "150px",
                    margin: "5px 0px"
                  }}
                  className="RowCenter CreateRouteDialogAddWaypointButton"
                  onClick={e => {
                    this.setState({ showWaypointInput: true });
                  }}
                >
                  Add Waypoint
                </div>
                {this.state.showWaypointInput && (
                  <div className="RowCenter">
                    <div style={{ width: "100%" }}>
                      <Select
                        options={waypointTypes}
                        placeholder="Waypoint Type"
                        styles={{
                          container: (base, state) => {
                            return {
                              ...base,
                              margin: "8px 0px"
                            };
                          },
                          valueContainer: (base, state) => {
                            return {
                              ...base,
                              height: "50px"
                            };
                          }
                        }}
                        onChange={e => {
                          this.setState({ newWaypointType: e.value }, () => {
                            this.fetchWaypoints(e.value);
                          });
                        }}
                      />
                      <Select
                        options={this.state.waypointList}
                        placeholder="Select Waypoint"
                        styles={{
                          container: (base, state) => {
                            return {
                              ...base,
                              margin: "8px 0px"
                            };
                          },
                          valueContainer: (base, state) => {
                            return {
                              ...base,
                              height: "50px"
                            };
                          }
                        }}
                        onChange={e => {
                          this.setState({
                            newWaypointName: e.label,
                            newWaypointLat: e.lat,
                            newWaypointLng: e.lng,
                            newWaypointId: e.id
                          });
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#21458d",
                            height: "50px",
                            color: "#fff",
                            width: "150px",
                            margin: "5px 5px"
                          }}
                          className="RowCenter CreateRouteDialogAddWaypointButton"
                          onClick={e => {
                            this.setState({ showWaypointInput: false });
                          }}
                        >
                          Cancel
                        </div>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#21458d",
                            height: "50px",
                            color: "#fff",
                            width: "150px",
                            margin: "5px 0px"
                          }}
                          className="RowCenter CreateRouteDialogAddWaypointButton"
                          onClick={e => {
                            const wpdata = {
                              name: this.state.newWaypointName,
                              type: this.state.newWaypointType,
                              lat: this.state.newWaypointLat,
                              lng: this.state.newWaypointLng,
                              id: this.state.newWaypointId
                            };
                            this.addWaypoint(wpdata);
                          }}
                        >
                          Save Waypoint
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ width: "100%" }}>
                      <LocationPicker setLocation={this.setNewLatLng} />
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div
              onClick={this.props.handleClose}
              color="primary"
              style={{ cursor: "pointer" }}
            >
              Close
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#21458d",
                height: "50px",
                color: "#fff",
                width: "150px",
                margin: "5px 30px"
              }}
              className="RowCenter CreateRouteDialogAddWaypointButton"
              onClick={async e => {
                let route = {};
                route["name"] = this.state.routeName;
                let wps = this.state.waypoints;
                wps.unshift(this.state.origin);
                wps.push(this.state.destination);
                route["waypoints"] = wps;
                // console.log(route);
                let response = await CreateRoute(route);
                if (response) {
                  this.props.handleClose();
                  window.location.reload();
                }
                // console.log(response);
              }}
            >
              Save Route
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default CreateRouteDialog;
