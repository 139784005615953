import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
import "./style.css";
import { CreateNewOwner, GetOwnerProfile } from "logic";
import SpinnerTextSwitch from "../../Components/SpinnerTextSwitch";
import { TableActionButton } from "Components/ActionButtons";
import { SelectStyle } from "componentstyle";

// popup appears on click "Add New Owner" button in Owners screen and "Edit" button in Owner details screen
class NewOwnerDialog extends Component {
  state = {
    first_name: null,
    middle_name: null,
    last_name: null,
    address: null,
    phone: null,
    vat_reg_no: null,
    commercial_reg_no: null,
    website: null,
    number_of_trucks: null,
    trade_license_no: null,
    chamber_membership_no: null,
    email: null,
    password: null,
    bank_name: null,
    bank_address: null,
    iban_number: null,
    bank_beneficiary: null,
    bank_swift_code: null,
    company_name: null,
    // company_type: null,
    // other_text: null,
    // selected_company_type: null,
    // company_type_list: []
  };
  // executes before component render
  // call the function which make api call to get owners data 
  componentDidMount() {
    this.getOwner();
    // CreateCompanyTypeDropdownOptions(this, "company_type_list")
  }
  // function makes api call to get owner data in case of editing and have a owner id
  getOwner = async () => {
    if (this.props.type && this.props.type == "edit" && this.props.ownerid) {
      let owner = await GetOwnerProfile(this.props.ownerid);
      this.setState(owner);
    }
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="md"
          // fullScreen
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              {this.props.type
                ? this.props.type == "edit"
                  ? "Edit Owner Details"
                  : "Create New Owner"
                : "Create New Owner"}
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            {/* <p>Create Route Content Goes Here!</p> */}
            <div>
              {/* <div>RouteStepper Goes Here</div> */}
              <div style={{ padding: "10px", boxSizing: "border-box" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center"
                  }}
                >
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="First Name"
                    onChange={val => {
                      this.setState({ first_name: val });
                    }}
                    value={this.state.first_name}
                    style={{ marginRight: "5px" }}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Middle Name"
                    onChange={val => {
                      this.setState({ middle_name: val });
                    }}
                    value={this.state.middle_name}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Last Name"
                    onChange={val => {
                      this.setState({ last_name: val });
                    }}
                    value={this.state.last_name}
                    style={{ marginLeft: "5px" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center"
                  }}
                >
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Email"
                    onChange={val => {
                      this.setState({ email: val });
                    }}
                    value={this.state.email}
                    style={{ marginRight: "5px" }}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Phone Number"
                    onChange={val => {
                      this.setState({ phone: val });
                    }}
                    value={this.state.phone}
                  />
                </div>
                <div>
                  <InputBox
                    className="NewOwnerModalInputBox"
                    type="password"
                    placeholder="Password"
                    onChange={val => {
                      this.setState({ password: val });
                    }}
                    value={this.state.password}
                    autoComplete="new-password"
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Company Name"
                    onChange={val => {
                      this.setState({ company_name: val });
                    }}
                    value={this.state.company_name}
                  />
                  {/* <Select
                    classNamePrefix="CompanyType Select"
                    className="NewOwnerModalDropDown"
                    options={this.state.company_type_list}
                    placeholder={this.state.companyDetails ? this.state.companyDetails.title : "Company Type"}
                    styles={SelectStyle}
                    onChange={(e) => {
                      this.setState({ company_type: e.value, selected_company_type: e });
                    }}
                    value={this.state.selected_company_type}
                  />
                  { ((this.state.selected_company_type && this.state.selected_company_type.label === "Other") || 
                      (this.state.companyDetails && this.state.companyDetails.title === "Other")
                    ) && 
                    <InputBox
                      className="NewOwnerModalInputBox"
                      placeholder="Other"
                      onChange={val => {
                        this.setState({ other_text: val });
                      }}
                      value={this.state.other_text}
                    />
                  } */}
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Company Website"
                    onChange={val => {
                      this.setState({ website: val });
                    }}
                    value={this.state.website}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Number of Trucks"
                    type={"number"}
                    onChange={val => {
                      this.setState({ number_of_trucks: val });
                    }}
                    value={this.state.number_of_trucks}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Trade License Number"
                    onChange={val => {
                      this.setState({ trade_license_no: val });
                    }}
                    value={this.state.trade_license_no}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Commercial Registration Number"
                    onChange={val => {
                      this.setState({ commercial_reg_no: val });
                    }}
                    value={this.state.commercial_reg_no}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="VAT Registration Number"
                    onChange={val => {
                      this.setState({ vat_reg_no: val });
                    }}
                    value={this.state.vat_reg_no}
                  />
                  <InputBox
                    className="NewOwnerModalInputBox"
                    placeholder="Chamber Membership Number (Optional)"
                    onChange={val => {
                      this.setState({ chamber_membership_no: val });
                    }}
                    value={this.state.chamber_membership_no}
                  />
                </div>
                <div>
                  {" "}
                  <textarea
                    className="NewOwnerModalInputBox DataTextarea"
                    placeholder="Address..."
                    style={{ resize: "none", height: "100px" }}
                    onChange={e => {
                      this.setState({ address: e.target.value });
                    }}
                    value={this.state.address}
                  />
                </div>
                <div>
                  <h6
                    style={{
                      margin: "0px",
                      marginTop: "16px",
                      marginLeft: "8px",
                      color: "#777",
                    }}
                  >
                    Bank Account Details
                  </h6>
                  <div
                    className="ColumnCenter"
                    style={{
                      alignItems: "flex-start",
                      border: "1px solid #262c40",
                      padding: "8px",
                      margin: "8px 0 8px",
                    }}
                  >
                    <InputBox
                      className="NewOwnerModalInputBox"
                      placeholder="Bank Name"
                      onChange={val => {
                        this.setState({ bank_name: val });
                      }}
                      value={this.state.bank_name}
                    />
                    <div style={{width: "100%"}}>
                      <textarea
                        className="NewOwnerModalInputBox DataTextarea"
                        placeholder="Bank Address"
                        style={{ resize: "none", height: "100px",  }}
                        onChange={e => {
                          this.setState({ bank_address: e.target.value });
                        }}
                        value={this.state.bank_address}
                      />
                    </div>
                    <InputBox
                        className="NewOwnerModalInputBox"
                        placeholder="Bank Beneficiary Name"
                        onChange={val => {
                          this.setState({ bank_beneficiary: val });
                        }}
                        value={this.state.bank_beneficiary}
                        style={{ marginRight: "2px" }}
                      />
                    <div
                      style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
                    >
                      <InputBox
                        className="NewOwnerModalInputBox"
                        placeholder="IBAN Number"
                        onChange={val => {
                          this.setState({ iban_number: val });
                        }}
                        value={this.state.iban_number}
                        style={{ marginRight: "2px" }}
                      />
                      <InputBox
                        className="NewOwnerModalInputBox"
                        placeholder="Bank Swift Code (Optional)"
                        onChange={val => {
                          this.setState({ bank_swift_code: val });
                        }}
                        value={this.state.bank_swift_code}
                        style={{ marginLeft: "2px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                onClick={this.props.handleClose}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={e => {
                  this.props.onSave
                    ? this.props.onSave(this.state)
                    : CreateNewOwner(this, this.state, this.props.handleClose);
                  // this.props.handleClose();
                }}
              >
                {this.props.type ? (
                  this.props.type == "edit" ? (
                    <SpinnerTextSwitch spin={this.props.spin} color={"#fff"}>
                      Update
                    </SpinnerTextSwitch>
                  ) : (
                    "Create"
                  )
                ) : (
                  "Create"
                )}
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default NewOwnerDialog;
