import React, { Component } from "react";
import { Colors } from "Constants";
import { GetFinanceOverview } from "logic";
import Loader from "Components/Loader";
class FinanceOverview extends Component {
  state = { overview: null };

  // function calls api to get finance overview data
  getOvervew = async () => {
    let response = await GetFinanceOverview();
    if (response) {
      this.setState({ overview: response.data.data });
    }
  };

  // executes before component render
  componentDidMount() {
    this.getOvervew();
  }

  // to render finance overview component
  render() {
    let { overview } = this.state;
    if (!overview) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    return (
      <div
        style={{
          backgroundColor: Colors.body,
          minHeight: "100vh",
          paddingTop: "20px"
        }}
      >
        {/* <p>Finance Overview Screen</p> */}
        {this.state.overview && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <FinanceCard
              data={overview.total_received.booking.toFixed(2)}
              title={"Received For Booking"}
              url={"/dashboard/finance/lists/2"}
            />
            <FinanceCard
              data={overview.total_received.due.toFixed(2)}
              title={"Due Settled"}
              url={"/dashboard/finance/lists/1"}
            />
            <FinanceCard
              data={overview.total_pending.due.toFixed(2)}
              title={"Pending Due Payment"}
              url={"/dashboard/finance/lists/1"}
            />
            <FinanceCard
              data={overview.settlements.settled.toFixed(2)}
              title={"Payed Out"}
              url={"/dashboard/finance/lists/4"}
            />
            <FinanceCard
              data={overview.settlements.pending.toFixed(2)}
              title={"Pending Payment"}
              url={"/dashboard/finance/lists/4"}
            />
            <FinanceCard
              data={overview.credits.total_used.toFixed(2)}
              title={"Total Credit Used"}
              url={"/dashboard/finance/lists/0"}
            />
          </div>
        )}
      </div>
    );
  }
}
export default FinanceOverview;

const FinanceCard = props => {
  return (
    <div
      style={{
        height: "200px",
        width: "312px",
        backgroundColor: Colors.background,
        boxSizing: "border-box",
        // padding: "20px",
        margin: "8px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "60px"
      }}
    >
      <p
        style={{
          color: "#FFFFFF",
          fontSize: "30px",
          fontWeight: "bold",
          lineHeight: "33px"
        }}
      >
        {props.data}
      </p>
      {/* <div
        style={{
          position: "absolute",
          bottom: "8px",
          right: "8px",
          backgroundColor: "#f3f3f350",
          height: "20px",
          width: "20px"
        }}
      >
        <img
          src={require("Assets/Icons/Icons_Back.svg")}
          style={{ transform: "rotate(180deg)" }}
          alt="icon"
        ></img>
      </div> */}
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          right: "0px",
          height: "60px",
          width: "312px",
          backgroundColor: "#F95138",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingRight: "60px"
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "#ffffff",
            margin: "0px",
            fontSize: "17px",
            fontWeight: "bold",
            lineHeight: "22px"
          }}
        >
          {props.title}
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          right: "0px",
          height: "60px",
          width: "60px",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={() => {
          if (props.url) window.location.assign(props.url);
        }}
      >
        <img
          src={require("Assets/Icons/Orange_Right_Arrow.svg")}
          style={{ height: "17px", width: "17px" }}
          alt="icon"
        ></img>
      </div>
    </div>
  );
};
