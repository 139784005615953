import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {
  CitiesList,
  RoutesList,
  NewRouteDialog,
  NewCityDialog
} from "Sections";
import Tabs, { ThemedTab as Tab } from "../../Components/ThemedTabs";
import { ActionButton } from "Components/ActionButtons";
import { Colors } from "Constants";

// to render tab button component
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

// component for routes and cities
class RoutesScreen extends Component {
  state = {
    tab: this.props.match.params.tab ? Number(this.props.match.params.tab) : 0,
    showRouteEditDialog: false,
    showCityEditDialog: false
  };

  // to handle switching between routes and cities tab
  handleChange = (event, value) => {
    this.setState({ tab: value });
  };
  openRouteEdit = () => {
    this.setState({ showRouteEditDialog: true });
  };
  closeRouteEdit = () => {
    this.setState({ showRouteEditDialog: false });
  };
  openCityEdit = (id, type, data) => {
    if (!id || !type) {
      this.setState({
        showCityEditDialog: true,
        cityEditType: null,
        cityEditId: null,
        cityEditData: null
      });
    } else {
      this.setState({
        showCityEditDialog: true,
        cityEditType: type,
        cityEditId: id,
        cityEditData: data
      });
    }
  };
  openRouteEdit = (id, type, data) => {
    if (!id || !type) {
      this.setState({
        showRouteEditDialog: true,
        routeEditType: null,
        routeEditId: null,
        routeEditData: null
      });
    } else {
      this.setState({
        showRouteEditDialog: true,
        routeEditType: type,
        routeEditId: id,
        routeEditData: data
      });
    }
  };
  closeCityEdit = () => {
    this.setState({ showCityEditDialog: false });
  };

  render() {
    const { tab } = this.state;
    console.log(this.props.match.params.tab);
    return (
      <div style={{ backgroundColor: Colors.body, minHeight: "100vh" }}>
        <Tabs
          value={tab}
          onChange={this.handleChange}
          centered
          style={{ width: "100%", padding: "20px 18px" }}
        >
          <Tab label="Routes" className="MainTab" />
          <Tab label="Cities" className="MainTab" />
        </Tabs>
        {/* render either of routes or cities section according to tab value */}
        {tab === 0 && (
          <TabContainer>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "18px",
                position: "relative",
                top: "54px",
                zIndex: "2"
              }}
            >
              <ActionButton
                iconsrc={require("Assets/Icons/Icons_Plus.svg")}
                rootStyle={{ marginLeft: "8px" }}
                onClick={e => {
                  this.openRouteEdit();
                }}
              >
                Add New Route
              </ActionButton>
            </div>
            <RoutesList openEdit={this.openRouteEdit} />
          </TabContainer>
        )}
        {tab === 1 && (
          <TabContainer>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "18px",
                position: "relative",
                top: "54px",
                zIndex: "2"
              }}
            >
              {/* <ActionButton
                iconsrc={require("Assets/Icons/Icons_Plus.svg")}
                rootStyle={{ marginLeft: "8px" }}
                onClick={e => {
                  this.openCityEdit();
                }}
              >
                Add City
              </ActionButton> */}
            </div>
            <CitiesList openEdit={this.openCityEdit} />
          </TabContainer>
        )}
        <NewRouteDialog
          open={this.state.showRouteEditDialog}
          handleClose={this.closeRouteEdit}
          edit={this.state.routeEditType ? this.state.routeEditType : false}
          uid={
            this.state.routeEditType && this.state.routeEditId
              ? this.state.routeEditId
              : null
          }
          data={this.state.routeEditData}
        />
        <NewCityDialog
          open={this.state.showCityEditDialog}
          handleClose={this.closeCityEdit}
          edit={this.state.cityEditType ? this.state.cityEditType : false}
          uid={
            this.state.cityEditType && this.state.cityEditId
              ? this.state.cityEditId
              : null
          }
          data={this.state.cityEditData}
        />
      </div>
    );
  }
}
export default RoutesScreen;
