import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
import { TableActionButton } from "Components/ActionButtons";
// popup appears on click edit button in credits tab of finances screen
class UpdateCreditDialog extends Component {
  state = { new_max: null, edited: false };
  close = () => {
    this.props.handleClose(() => {
      this.setState({ edited: false, new_max: null });
    });
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.close}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              Change Credit Limit
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            <div>
              <div style={{ padding: "10px", boxSizing: "border-box" }}>
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px"
                  }}
                  className="DataInputLabel"
                >
                  New Credit Limit:
                </p>
                <input
                  className="DataInput"
                  type="number"
                  style={{
                    border: "1px solid #ddd",
                    padding: "0px 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "5px"
                  }}
                  value={
                    this.state.new_max || this.state.edited
                      ? this.state.new_max
                      : this.props.current_max
                  }
                  placeholder="Limit"
                  onChange={e => {
                    this.setState({ new_max: e.target.value, edited: true });
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                onClick={this.close}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={e => {
                  this.props.save(
                    this.props.uid,
                    this.state.new_max
                      ? this.state.new_max
                      : this.props.current_max
                  );
                }}
              >
                Update
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default UpdateCreditDialog;
