import React, { Component } from "react";
// import { Dropdown, Button } from "react-materialize";
import Dropdown from "Components/Dropdown";
import SquareLogo from "Components/SquareLogo";
import { Link } from "react-router-dom";
import "./nav.css";
import { TOKEN_KEY, Colors } from "Constants.js";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import { confirmAlert } from "react-confirm-alert";
import { GetAdminNotificationList, GetTrailerJourneyHistory } from "../../logic";
import NotificationListPopup from "../../Components/NotificationListPopup";
import { notificationCount } from "../../Commons";
// navigation bar is displayed in all the screens after login
class NavigationBar extends Component {
  constructor(props) {
    super(props)
    this.timer = null;
  }
  state = {
    focused: null,
    notifications: [],
    no_of_unseen_notifications: 0,
  };
  // execute on mouseEnter over dropdown
  // set focused state with dropdown index
  revealDropdown = (index) => {
    this.setState({ focused: index });
  };
  // execute on mouseEnter over notification item
  // show notiifcation list, set unseen notification count
  revealNotification = async (status) => {
    this.setState({ focused: status });
    let response = await GetAdminNotificationList(10)
    if(response.length > 0) {
      localStorage.setItem(
        "notification_timeStamp",
        response[0].createdAt
      )
      this.setUnseenNotificationCount();
    } else {
      console.log("no notificaiton found", this.state.notifications);
      localStorage.setItem(
        "notification_timeStamp",
        "no notification found"
      )
    }
  }
  // Fetch admin notification list
  componentDidMount = async () => {
    let response = await GetAdminNotificationList(10)
    this.setState({ notifications: response });
    this.setUnseenNotificationCount();

    this.timer = setInterval(() => {
      this.setUnseenNotificationCount();
    }, 10000);
  }


  componentWillUnmount() {
    console.log("unmounting");
    clearInterval(this.timer)
  }

  setUnseenNotificationCount = async() => {
    console.log("started", this.state.no_of_unseen_notifications);
    this.setState({no_of_unseen_notifications: await notificationCount()})
    console.log("Unseen notification count:", this.state.no_of_unseen_notifications);
  }

  // to display confirm logout modal on click logout
  ShowConfirmLogoutDialog = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            onClose={onClose}
            onConfirm={() => {
              localStorage.removeItem(TOKEN_KEY);
              window.location.assign("/");
            }}
            title={"Are you sure ?"}
            message={"Do you want to logout ?"}
          />
        );
      },
    });
  };
  // menus stored as array of objects
  menus = [
    {
      name: "Eagle Eye",
      type: "link",
      url: "/dashboard/eagleeye/",
    },
    {
      name: "Journey",
      type: "dropdown",
      links: [
        { name: "Active Journeys", url: "/dashboard/journeys/0" },
        { name: "Journey History", url: "/dashboard/journeys/1" },
      ],
    },
    {
      name: "Bookings",
      type: "dropdown",
      links: [
        { name: "Current Bookings", url: "/dashboard/bookings/0" },
        { name: "Cancelled Bookings", url: "/dashboard/bookings/1" },
        { name: "Booking History", url: "/dashboard/bookings/2" },
      ],
    },
    {
      name: "Finances",
      type: "dropdown",
      links: [
        { name: "Overview", url: "/dashboard/finance" },
        { name: "Credit", url: "/dashboard/finance/lists/0" },
        { name: "Dues", url: "/dashboard/finance/lists/1" },
        { name: "Payments", url: "/dashboard/finance/lists/2" },
        { name: "Refunds", url: "/dashboard/finance/lists/3" },
        { name: "Owner Payout", url: "/dashboard/finance/lists/4" },
        { name: "Driver Payout", url: "/dashboard/finance/lists/5" },
      ],
    },
    {
      name: "Inventory",
      type: "dropdown",
      links: [
        { name: "Owners", url: "/dashboard/datamgmt/0" },
        { name: "Trailers", url: "/dashboard/datamgmt/1" },
        { name: "Drivers", url: "/dashboard/datamgmt/2" },
        { name: "Users", url: "/dashboard/datamgmt/3" },
      ],
    },
    {
      name: "Routes And Cities",
      type: "dropdown",
      links: [
        { name: "Routes", url: "/dashboard/routes/lists/0" },
        { name: "Cities", url: "/dashboard/routes/lists/1" },
      ],
    },
    {
      name: "Messaging",
      type: "dropdown",
      links: [
        { name: "Send Notification", url: "/dashboard/notify" },
        { name: "Messages Received", url: "/dashboard/messages" },
      ],
    },
    // {
    //   name: "Settings",
    //   type: "dropdown",
    //   links: [
    //     { name: "General Settings", url: "/dashboard/settings/" }
    //     // { name: "Routes and Checkpoints", url: "/dashboard/settings/routes" },
    //     // { name: "Backup" }
    //   ]
    // },
    // {
    //   name: "Reports",
    //   type: "dropdown",
    //   links: [{ name: "Financials" }, { name: "Analytics" }]
    // },
    {
      name: "Search",
      type: "text",
    },
  ];
  render() {
    return (
      <div style={{ position: "relative", zIndex: 3 }}>
        <nav
          style={{ boxShadow: "none", backgroundColor: "#262c40" }}
          id={"NavigationBar"}
        >
          <div className="nav-wrapper">
            <a href="/" className="brand-logo left">
              <SquareLogo
                style={{
                  width: "80px",
                  // height: "50px",
                  // border: "2px solid rgba(255, 255, 255, 0.25)",
                  margin: "8px",
                  // borderRadius: "100%"
                }}
              />
            </a>
            <ul style={{display: "flex", gap: "10px"}} id="nav-mobile" className="right hide-on-med-and-down">
              {this.menus.map((item, index, arr) => {
                return (
                  <li
                    key={index}
                    onBlur={() => {
                      // this.setState({ focused: null });
                      // console.log("navbar blurred");
                    }}
                  >
                    {item.type === "dropdown" ? (
                      <Dropdown
                        triggerName={item.name}
                        show={index === this.state.focused ? true : false}
                        index={index}
                        trigger={this.revealDropdown}
                        // horizontalOffset={"-30%"}
                        style={{
                          right: index == arr.length - 1 ? "50px" : "0px",
                        }}
                      >
                        {item.links.map((link, link_index, arr) => {
                          return (
                            <div
                              style={{ width: "100%" }}
                              className="NavigationBarDropdownChild"
                            >
                              {" "}
                              <Link
                                to={link.url ? link.url : "/"}
                                onClick={(e) => {
                                  window.location.assign(
                                    link.url ? link.url : "/"
                                  );
                                }}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <p
                                  style={{
                                    margin: "0px",
                                    fontSize: "14px",
                                    width: "100%",
                                  }}
                                  className="NavigationBarDropdownChildName"
                                >
                                  {link.name}
                                </p>
                              </Link>
                            </div>
                          );
                        })}
                      </Dropdown>
                    ) : (
                      ""
                    )}
                    {item.type === "link" ? (
                      <a href={item.url ? item.url : "/"}>{item.name}</a>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
              <li
                style={{
                  marginRight: "20px",
                  fontWeight: "400",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  position: "relative"
                }}
                onMouseEnter={() => {
                  this.revealNotification(true);
                }}
                onMouseLeave={() => {
                  this.revealNotification(false);
                }}
              >
                Notifications
                <span style={{marginLeft: "6px", display: "flex", position: "relative"}}>
                  <img
                    src={require("Assets/Icons/notification.svg")}
                    style={{
                      height: "19px",
                      width: "19px",
                    }}
                    alt="Notification-icon"
                  />
                  {this.state.no_of_unseen_notifications > 0 && (
                    <span style={{backgroundColor: Colors.primary}} className={"notificaion-indicator"}>{this.state.no_of_unseen_notifications}</span>
                  )}
                </span>
                <NotificationListPopup
                  show={this.state.focused === true ? true : false}
                  data={this.state.notifications}
                />
              </li>
              <li
                style={{
                  marginRight: "20px",
                  fontWeight: "400",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  this.ShowConfirmLogoutDialog();
                }}
              >
                Logout
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default NavigationBar;
