import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  TableFixedColumns,
  PagingPanel,
  Toolbar,
  SearchPanel,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import { GetCityList, ToggleCityServiceStatus } from "logic";
import GridSearchBox from "Components/GridSearchBox";
import { TableActionButton } from "Components/ActionButtons";
import TableComponent, { PagingContainer } from "Components/TableComponent";
import Switch from "@material-ui/core/Switch";
// cities listing section component, listing is done using @devexpress/dx-react-grid library
class CitiesList extends Component {
  state = {
    rows: [],
  };
  // to handle toggle in city service status
  ToggleService = async (id, status) => {
    // api call to change service status of the city
    let response = await ToggleCityServiceStatus(id, status);
    if (response) this.GetCities();
  };
  // function makes api call to get cities data and set rows state 
  GetCities = async () => {
    let response = await GetCityList();
    let table_data = response.data.map((item, index) => {
      return {
        name: null,
        // center: null,
        // radius: null,
        // extension_radius: null,
        min_cost: null,
        free_kms: null,
        rate_per_km: null,
        ollys_cut: null,
        drivers_cut: null,
        owners_cut: null,
        tax: null,
        insurance: null,
        ...item,
        ...item.fare,
        center: item.center.coordinates.toString(),
        toggle: (
          <Switch
            checked={item.active ? true : false}
            inputProps={{ "aria-label": "primary checkbox" }}
            onClick={(e) => {
              this.ToggleService(item.id, !this.state.rows[index].active);
            }}
          />
        ),
        action: (
          <div style={{ display: "flex" }}>
            {/* <TableActionButton
              rootStyle={{ margin: "0px 1px" }}
              onClick={() => {
                this.props.openEdit(item.id, "details", {
                  ...item,
                  ...item.fare,
                });
              }}
            >
              Edit Details
            </TableActionButton> */}

            <TableActionButton
              type="two"
              onClick={() => {
                this.props.openEdit(item.id, "fare", { ...item, ...item.fare });
              }}
            >
              Edit Fare
            </TableActionButton>
          </div>
        ),
      };
    });
    console.log(table_data);
    this.setState({ rows: table_data });
  };
  // executes before component render
  // call the function which make api call to get a cities details
  componentDidMount() {
    this.GetCities();
  }
  columns = [
    { name: "index", title: "S.No" },
    { name: "name", title: "City Name" },
    // { name: "center", title: "Center Coordinates" },
    // { name: "radius", title: "Radius" },
    // { name: "extension_radius", title: "Extended Radius" },
    { name: "toggle", title: "Service Active" },
    { name: "min_cost", title: "Minimum Fare" },
    { name: "free_kms", title: "Free KMs On Minimum Fare" },
    { name: "rate_per_km", title: "Rate Per KM" },
    { name: "ollys_cut", title: "Olly's %" },
    { name: "owners_cut", title: "Owner's %" },
    { name: "drivers_cut", title: "Drivers %" },
    { name: "tax", title: "Tax Cut %" },
    { name: "insurance", title: "Insurance Cost" },
    { name: "action", title: "Actions" },
  ];
  // defaultColumnWidths = this.columns.map((item) => {
  //   if (item.name === "action") return { columnName: item.name, width: 240 };
  //   return { columnName: item.name, width: 100 };
  // });
  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          const row = {
            ...item,
            index: index + 1,
          };
          return row;
        })}
        columns={this.columns}
      >
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={10} />

        <IntegratedPaging />
        <Table tableComponent={TableComponent} messages={{ noData: "..." }} />
        {/* <TableColumnResizing defaultColumnWidths={this.defaultColumnWidths} /> */}
        <TableHeaderRow />
        <TableFixedColumns rightColumns={["action"]} />
        <Toolbar />
        <SearchPanel inputComponent={GridSearchBox} />
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}
export default CitiesList;
