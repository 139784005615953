import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "react-select";
import { SelectStyle } from "componentstyle";

// this popup displays when "Allot owner" button in booking details screen is clicked
class AllotOwnerDialog extends Component {
  state = {
    loading: false,
  };

  donothing = async () => {};

  // check an owner is selected and if yes, open confirmation modal else show alert
  confirmAllotOwner = async () => {
    if (!this.props.owner) {
      alert("Please Select An Owner");
      return 0;
    }
    this.props.openConfirmaion()
    this.props.handleClose()
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff",
              }}
            >
              Allot to Owner
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              backgroundColor: "#353b50",
              borderRadius: "0px",
              position: "relative",
            }}
          >
            <div
              style={{
                padding: "10px",
                boxSizing: "border-box",
                minHeight: "420px"
              }}
              className="NewTrailerModal"
            >
              <p
                style={{
                  marginBottom: "0px"
                }}
                className="DataInputLabel"
              >
                Owner:
              </p>
              <Select
                classNamePrefix="OwnerSelect"
                options={this.props.ownerList}
                styles={SelectStyle}
                placeholder={
                  this.props.owner
                    ? this.props.owner.label
                    : "Owner.."
                }
                onChange={owner => {
                  this.props.selectOwner({id: owner.value, label: owner.label})
                }}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div
              onClick={this.props.handleClose}
              color="primary"
              className="RowCenter"
              style={{
                cursor: "pointer",
                width: "150px",
                height: "50px",
                marginRight: "4px",
                backgroundColor: "#262c40",
                color: "#f3f3f3",
              }}
            >
              Close
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#f95138",
                height: "50px",
                color: "#fff",
                width: "150px",
                margin: "0px 0px",
                cursor: "pointer",
              }}
              className="RowCenter"
              onClick={(e) => {
                this.state.loading ? this.donothing() : this.confirmAllotOwner();
              }}
            >
              Confirm
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default AllotOwnerDialog;
