import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBox from "Components/InputBox";
import Select from "react-select";
import { TableActionButton } from "Components/ActionButtons";
// import "./style.css";
import { GetUserList_2 as GetUserList } from "logic";
import Geosuggest from "react-geosuggest";
import { SelectStyle } from "componentstyle";
// popup appears on click "Create New Booking" button in bookings screens
class NewBookingDialog extends Component {
  state = {
    userId: null,
    pickup_lat: null,
    pickup_lng: null,
    drop_lat: null,
    drop_lng: null,
    pickup_contact: null,
    drop_contact: null,
    pickup_datetime: null,
    userOptions: [],
    showPickupSuggests: false,
    showDropSuggests: false
  };
  // function make api call to get user's data
  getUserOptions = async () => {
    let response = await GetUserList();

    if (!response) return;
    // map through response array and return object with label and value keys
    let userOptions = response.map(item => {
      return { label: `${item.name} (${item.phone})`, value: item.id };
    });
    this.setState({ userOptions: userOptions });
  };
  // executes before component render
  // call the function which make api call to get user's data
  componentDidMount() {
    this.getUserOptions();
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
          fullWidth
          maxWidth="sm"
          // style={{ backgroundColor: "#262c40" }}
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ backgroundColor: "#262c40", borderRadius: "0px" }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#none",
                height: "100%",
                color: "#fff"
              }}
            >
              New Booking
            </div>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#353b50" }}>
            <div>
              <div style={{ padding: "10px", boxSizing: "border-box" }}>
                <Select
                  classNamePrefix="User-Select"
                  options={this.state.userOptions}
                  //   options={this.state.routeOptions}
                  placeholder={"User (Phone)"}
                  styles={SelectStyle}
                  onChange={opt => {
                    this.setState({ userId: opt.value });
                  }}
                />
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px"
                  }}
                  className="DataInputLabel"
                >
                  Pickup Location:
                </p>
                <Geosuggest
                  // types={['(cities)']}
                  // country={'sa'}
                  ref={el => (this._geoSuggest = el)}
                  placeholder="Search Places"
                  highlightMatch={true}
                  style={{
                    input: {
                      border: "none",
                      padding: "0px 10px",
                      color: "#fff",
                      backgroundColor: "#262c40",
                      boxSizing: "border-box",
                      margin: "5px"
                    },
                    suggests: {
                      color: "#fff",
                      display: this.state.showPickupSuggests ? "block" : "none",
                      marginTop: "0px",
                      marginBottom: "0px"
                    },
                    suggestItem: {
                      backgroundColor: "#262c40",
                      padding: "10px 10px",
                      cursor: "pointer"
                    }
                  }}
                  onBlur={() => {
                    this.setState({ showPickupSuggests: false });
                  }}
                  onFocus={() => {
                    this.setState({ showPickupSuggests: true });
                  }}
                  onChange={() => {
                    this.setState({ showPickupSuggests: true });
                  }}
                  onSuggestSelect={suggest => {
                    if (suggest && suggest.location) {
                      this.setState({
                        showPickupSuggests: false,
                        pickup_lat: suggest.location.lat,
                        pickup_lng: suggest.location.lng
                      });
                    }
                  }}
                />
                <div style={{ display: "flex" }}>
                  <input
                    className="DataInput"
                    type="text"
                    style={{
                      width: "200px",
                      border: "1px solid #ddd",
                      //   marginLeft: "5px",
                      padding: "0px 10px",
                      width: "100%",
                      boxSizing: "border-box",
                      margin: "5px"
                    }}
                    placeholder="lat"
                    onChange={e => {
                      this.setState({ pickup_lat: e.target.value });
                    }}
                    value={this.state.pickup_lat}
                  />
                  <input
                    className="DataInput"
                    type="text"
                    style={{
                      width: "200px",
                      border: "1px solid #ddd",
                      //   marginLeft: "5px",
                      padding: "0px 10px",
                      width: "100%",
                      boxSizing: "border-box",
                      margin: "5px"
                    }}
                    placeholder="lng"
                    onChange={e => {
                      this.setState({ pickup_lng: e.target.value });
                    }}
                    value={this.state.pickup_lng}
                  />
                </div>
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px"
                  }}
                  className="DataInputLabel"
                >
                  Drop Location
                </p>
                <Geosuggest
                  ref={el => (this._geoSuggest = el)}
                  placeholder="Search Places"
                  highlightMatch={true}
                  style={{
                    input: {
                      border: "none",
                      padding: "0px 10px",
                      color: "#fff",
                      backgroundColor: "#262c40",
                      boxSizing: "border-box",
                      margin: "5px"
                    },
                    suggests: {
                      color: "#fff",
                      display: this.state.showDropSuggests ? "block" : "none",
                      marginTop: "0px",
                      marginBottom: "0px"
                    },
                    suggestItem: {
                      backgroundColor: "#262c40",
                      padding: "10px 10px",
                      cursor: "pointer"
                    }
                  }}
                  onBlur={() => {
                    this.setState({ showDropSuggests: false });
                  }}
                  onFocus={() => {
                    this.setState({ showDropSuggests: true });
                  }}
                  onChange={() => {
                    this.setState({ showDropSuggests: true });
                  }}
                  onSuggestSelect={suggest => {
                    if (suggest && suggest.location) {
                      this.setState({
                        showDropSuggests: false,
                        drop_lat: suggest.location.lat,
                        drop_lng: suggest.location.lng
                      });
                    }
                  }}
                />
                <div style={{ display: "flex" }}>
                  <input
                    className="DataInput"
                    type="text"
                    style={{
                      width: "200px",
                      border: "1px solid #ddd",
                      //   marginLeft: "5px",
                      padding: "0px 10px",
                      width: "100%",
                      boxSizing: "border-box",
                      margin: "5px"
                    }}
                    placeholder="lat"
                    onChange={e => {
                      this.setState({ drop_lat: e.target.value });
                    }}
                    value={this.state.drop_lat}
                  />
                  <input
                    className="DataInput"
                    type="text"
                    style={{
                      width: "200px",
                      border: "1px solid #ddd",
                      //   marginLeft: "5px",
                      padding: "0px 10px",
                      width: "100%",
                      boxSizing: "border-box",
                      margin: "5px"
                    }}
                    placeholder="lng"
                    onChange={e => {
                      this.setState({ drop_lng: e.target.value });
                    }}
                    value={this.state.drop_lng}
                  />
                </div>
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px"
                  }}
                  className="DataInputLabel"
                >
                  Pickup Contact
                </p>

                <input
                  className="DataInput"
                  type="text"
                  style={{
                    width: "200px",
                    border: "1px solid #ddd",
                    //   marginLeft: "5px",
                    padding: "0px 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "5px"
                  }}
                  placeholder="+21325345435"
                  onChange={e => {
                    this.setState({ pickup_contact: e.target.value });
                  }}
                />
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px"
                  }}
                  className="DataInputLabel"
                >
                  Drop Contact
                </p>

                <input
                  className="DataInput"
                  type="text"
                  style={{
                    width: "200px",
                    border: "1px solid #ddd",
                    //   marginLeft: "5px",
                    padding: "0px 10px",
                    width: "100%",
                    boxSizing: "border-box",
                    margin: "5px"
                  }}
                  placeholder="+21325345435"
                  onChange={e => {
                    this.setState({ drop_contact: e.target.value });
                  }}
                />
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "5px"
                  }}
                  className="DataInputLabel"
                >
                  Pickup Date & Time:
                </p>
                <div style={{ display: "flex" }}>
                  <input
                    className="DataInput"
                    type="datetime-local"
                    style={{
                      width: "200px",
                      border: "1px solid #ddd",
                      //   marginLeft: "5px",
                      padding: "0px 10px",
                      width: "100%",
                      boxSizing: "border-box",
                      margin: "5px"
                    }}
                    placeholder="Pickup Date And Time"
                    onChange={e => {
                      this.setState({ pickup_datetime: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#353b50", margin: "0px" }}>
            <div style={{ display: "flex", padding: "20px" }}>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                type="three"
                onClick={this.props.handleClose}
              >
                Close
              </TableActionButton>
              <TableActionButton
                rootStyle={{ margin: "2px" }}
                onClick={e => {
                  if (this.props.confirmButton) {
                    this.props.create({
                      userId: this.state.userId,
                      pickup_location: {
                        type: "Point",
                        coordinates: [
                          this.state.pickup_lat,
                          this.state.pickup_lng
                        ]
                      },
                      drop_location: {
                        type: "Point",
                        coordinates: [this.state.drop_lat, this.state.drop_lng]
                      },
                      pickup_contact: this.state.pickup_contact,
                      drop_contact: this.state.drop_contact,
                      pickup_datetime: new Date(
                        this.state.pickup_datetime
                      ).toISOString()
                    });
                  } else {
                    this.props.toggleConfirmButton(true);
                  }
                }}
              >
                {this.props.confirmButton === false ? "Create" : "Confirm"}
              </TableActionButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default NewBookingDialog;
