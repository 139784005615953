import React, { Component } from "react";
import { GetWaypointList } from "logic";
import "./style.css";
const data = [
  { name: "Generic Oases Name", Location: "20.3423,45.43534" },
  { name: "Generic Oases Name", Location: "20.3423,45.43534" },
  { name: "Generic Oases Name", Location: "20.3423,45.43534" },
  { name: "Generic Oases Name", Location: "20.3423,45.43534" },
  { name: "Generic Oases Name", Location: "20.3423,45.43534" },
  { name: "Generic Oases Name", Location: "20.3423,45.43534" }
];
// not currently using
class WaypointListing extends Component {
  state = {};
  fetchWaypoints = async type => {
    let pts = await GetWaypointList(type);
    if (pts) {
      this.state[type] = pts.data.data.map(item => {
        return {
          name: item.name,
          id: item.id,
          Location: item.location.coordinates.toString()
        };
      });
      this.setState({});
    }
  };
  componentDidMount() {
    if (this.props.type) {
      this.fetchWaypoints(this.props.type);
    }
  }
  render() {
    let data = this.state[this.props.type] || [];
    return (
      <div>
        <div className="RouteListingTitle">{this.props.title}</div>
        <div
          style={{ width: "100%", border: "2px solid #275bb0" }}
          className="RouteListingContentWrapper"
        >
          {data.map(item => {
            return (
              <div className="OasesListingItemWrapper">
                <div>
                  <span style={{ fontSize: "12px", color: "#777" }}>
                    Name: &nbsp;
                  </span>
                  {item.name}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <span style={{ fontSize: "12px", color: "#777" }}>
                    Loc: &nbsp;
                  </span>
                  {item.Location}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default WaypointListing;
