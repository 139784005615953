import React, { Component } from "react";
import { GetUserProfile, MakeUserCreditEligible } from "logic";
import Loader from "Components/Loader";
import {
  UserProfile,
  CurrentBookings,
  // BookingHistory,
  UploadDocumentDialog
} from "Sections";
import Tabs, { ThemedTab as Tab } from "../../Components/ThemedTabs";
import Typography from "@material-ui/core/Typography";
import { GridSearchBoxLight } from "Components/GridSearchBox";
import { TableComponentLight } from "Components/TableComponent";
import { TableActionButton } from "Components/ActionButtons";

// to render tab button component
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}
// function to format time
function getTimeString(currentdate) {
  var datetime =
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();
  return datetime;
}

// component for user details screen
class User extends Component {
  state = {
    tab: this.props.match.params.tab ? Number(this.props.match.params.tab) : 0,
    showUploadDocumentDialog: false,
    profile: null
  };
  openUploadDocumentDialog = () => {
    this.setState({ showUploadDocumentDialog: true });
  };
  closeUploadDocumentDialog = () => {
    this.setState({ showUploadDocumentDialog: false });
  };
  // to handle switching between "Current Bookings" and "Bookings History" tab
  handleChange = (event, value) => {
    this.setState({ tab: value });
  };
  // executes on click "Make Credit Eligible" button
  // function make call to make user credit eligible
  UpgradeUser = async () => {
    let response = await MakeUserCreditEligible(this.props.match.params.id);
    if (response) this.fetchUserData();
  };

  // function to make api call to fetch user details and set mappedRows variable
  fetchUserData = async () => {
    let response = await GetUserProfile(this.props.match.params.id);
    let active_bookings = [];
    let booking_history = [];
    let MappedRows = response.bookings.map((item, index) => {
      return {
        id: item.id,
        bookingId: item.bookingId,
        index: index + 1,
        journey: item.journey
          ? item.journey.status.toString().toUpperCase()
          : "",
        booking_date: item.booking_date
          ? new Date(item.booking_date).toDateString()
          : "-",
        startPoint: item.pickup_location_name
          ? `${item.pickup_location_name}`
          : "NA",
        dropPoint: item.drop_location_name
          ? `${item.drop_location_name}`
          : "NA",
        status: item.status,
        pickup_date: item.pickup_datetime
          ? new Date(item.pickup_datetime).toDateString()
          : "-",
        pickup_time: item.pickup_datetime
          ? getTimeString(new Date(item.pickup_datetime))
          : "-",
        verified: item.verified ? "Verified" : "Not Verified",
        // action: (
        //   <TableActionButton url={`/dashboard/booking/${item.id}`}>
        //     Details
        //   </TableActionButton>
        // )
      };
    });
    for (var rw of MappedRows) {
      console.log(rw);
      if (rw.status != "delivered" && rw.status != "cancelled") {
        active_bookings.push(rw);
      } else {
        booking_history.push(rw);
      }
    }
    this.setState({
      profile: response,
      active_bookings: active_bookings,
      booking_history: booking_history
    });
  };
  // executes before component render
  // call user details fetching function
  componentDidMount() {
    this.fetchUserData();
  }

  render() {
    const { tab } = this.state;
    if (!this.state.profile) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    return (
      <div style={{ backgroundColor: "#353b50", paddingTop: "20px" }}>
        {/* render this if credit is not enabled */}
        {!this.state.profile.creditDetails && (
          <div
            style={{
              margin: "10px 0px 0px 15px",
              height: "36px",
              width: "180px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid #f95138",
              color: "#f95138",
              // borderRadius: "20px",
              cursor: "pointer"
            }}
            onClick={e => {
              this.UpgradeUser();
            }}
          >
            Make Credit Eligible
          </div>
        )}
        <UserProfile
          openUploadDocumentDialog={this.openUploadDocumentDialog}
          data={this.state.profile}
          uid={this.state.profile.id}
          fetchUserProfile={this.fetchUserData}
        />

        <div
          style={{
            padding: "10px",
            margin: "10px",
            marginBottom: "0px",
            paddingBottom: "50px",
            // border: "1px solid #ddd",
            backgroundColor: "#353b50"
          }}
        >
          <Tabs
            value={tab}
            onChange={this.handleChange}
            centered
            style={{ width: "100%", padding: "20px 18px" }}
          >
            <Tab label="Current Bookings" className="OwnerTab" />
            <Tab label="Bookings History" className="OwnerTab" />
          </Tabs>

          {tab === 1 && (
            <TabContainer>
              <CurrentBookings
                data={this.state.booking_history}
                // searchComponent={GridSearchBoxLight}
                // tableComponent={TableComponentLight}
              />
            </TabContainer>
          )}
          {tab === 0 && (
            <TabContainer>
              <CurrentBookings
                data={this.state.active_bookings}
                // searchComponent={GridSearchBoxLight}
                // tableComponent={TableComponentLight}
              />
            </TabContainer>
          )}
        </div>
        <UploadDocumentDialog
          open={this.state.showUploadDocumentDialog}
          handleClose={this.closeUploadDocumentDialog}
        />
      </div>
    );
  }
}
export default User;
