import React, { Component } from "react";
import { DocumentListing } from "Sections";
import EditButton from "Components/EditButton";
import {ReactComponent as CopyIcon} from "../../Assets/Icons/copy-icon.svg";
import { NotificationManager } from "react-notifications";
import "./ownerProfile.css"
import { timestampFormatter } from "../../Commons";

// owner profile section in owner details screen 
class OwnerProfile extends Component {
  state = { name: null, address: null, phone: null };

  // fuction copy text to clipboad
  copyText = (text) => {
    navigator.clipboard.writeText(text);
    NotificationManager.success("Owner's code copied","",2000)
  }

  render() {
    let data = this.props.data;
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <div className="OwnerProfileWrapper" style={{ flex: "1" }}>
            <div
              className="OwnerOverviewStatsTitle"
              style={{
                // display: "flex",
                // justifyContent: "space-around",
                // alignItems: "center",
                position: "relative"
              }}
            >
              <p style={{ margin: "0px" }}>Owner Profile</p>
              <EditButton
                style={{ position: "absolute", top: "0px", right: "0px" }}
                onClick={this.props.openEditDialog}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="OwnerProfileHolder OwnerProfileHolderLeft">
                <div className="OwnerProfileItemsWrapper">
                  <div className="OwnerProfileItem">
                    <p>Name: </p>
                    <p>{data.name || "Not Available"}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p id="owner-code">Owner's Code: </p>
                    <p style={{display: "flex", alignItems: "center"}}>
                      <span style={{marginRight: "16px"}}>{data.owner_code || "Not Available"}</span>
                      {data.owner_code && (
                        <button class={"owner-code_copy-btn"}>
                          <CopyIcon
                            style={{ height: "25px", width: "25px", cursor: "pointer"}}
                            onClick={() => this.copyText(data.owner_code)}
                          />
                        </button>
                      )}
                    </p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>Company: </p>
                    <p>{data.company_name || "Not Available"}</p>
                  </div>
                  {/* <div className="OwnerProfileItem">
                    <p>Company Type: </p>
                    <p>{(data.companyDetails && data.companyDetails.title) || "Not Available"}</p>
                  </div>
                  {(data.companyDetails && data.companyDetails.title === "Other") && 
                    <div className="OwnerProfileItem">
                      <p>Other: </p>
                      <p>{(data.other_text) || "Not Available"}</p>
                    </div>
                  } */}
                  <div className="OwnerProfileItem">
                    <p>Company Website: </p>
                    <p>{data.website || "Not Available"}</p>
                  </div>

                  <div className="OwnerProfileItem">
                    <p>Email: </p>
                    <p>{data.email || "Not Available"}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>Phone Number: </p>
                    <p>{data.phone}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>Number of Trucks: </p>
                    <p>{data.number_of_trucks || "Not Available"}</p>
                  </div>
                  <div className="OwnerProfileItem" style={{ height: "156px" }}>
                    <p>Address: </p>
                    <p>
                      {data.address && (
                        <pre
                          style={{ margin: "0px", fontFamily: "sans-serif" }}
                        >
                          {data.address}
                        </pre>
                      )}
                      {!data.address && (
                        <p
                          style={{
                            marginLeft: "0px",
                            alignSelf: "center",
                            padding: "0px",
                            margin: "0px"
                          }}
                        >
                          Not Available
                        </p>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="OwnerProfileHolder OwnerProfileHolderRight">
                <div className="OwnerProfileItemsWrapper">
                  <div className="OwnerProfileItem">
                    <p>Verification: </p>
                    <p>{data.verified ? "Verified" : "Not Verified"}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>Registered On: </p>
                    <p>{timestampFormatter(data.createdAt)}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>Trade License Number: </p>
                    <p>{data.trade_license_no || "Not Available"}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>Commercial Registration Number: </p>
                    <p>{data.commercial_reg_no || "Not Available"}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>VAT Registration: </p>
                    <p>{data.vat_reg_no || "Not Available"}</p>
                  </div>

                  <div className="OwnerProfileItem">
                    <p>Bank Name: </p>
                    <p>{data.bank_name || "Not Available"}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>Bank Address: </p>
                    <p>{data.bank_address || "Not Available"}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>Bank Beneficiary Name: </p>
                    <p>{data.bank_beneficiary || "Not Available"}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>IBAN Number: </p>
                    <p>{data.iban_number || "Not Available"}</p>
                  </div>
                  <div className="OwnerProfileItem">
                    <p>Swift Code: </p>
                    <p>{data.bank_swift_code || "Not Available"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="OwnerProfileWrapper" style={{ marginTop: "0px" }}>
          <div
            className="OwnerOverviewStatsTitle"
            style={{ position: "relative" }}
          >
            Documents
            <div
              style={{
                position: "absolute",
                right: "20px",
                top: "0px",
                bottom: "0px",
                height: "100%",
                cursor: "pointer"
              }}
              className="ColumnCenter"
              onClick={() => {
                // console.log("Open Upload Document Dialog");
                this.props.openUploadDocumentDialog();
              }}
            >
              <img
                src={require("Assets/Actions/upload.svg")}
                style={{ height: "25px", widht: "25px" }}
              />
            </div>
          </div>
          <DocumentListing type={"owner"} uid={this.props.uid} fetchProfile={this.props.fetchOwnerProfile} />
        </div>
      </React.Fragment>
    );
  }
}
export default OwnerProfile;
