import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  OwnerData,
  DriverData,
  TrailerData,
  UserData,
  NewOwnerDialog,
  NewDriverDialog,
  NewTrailerDialog
} from "Sections";
import { BulkDeleteOwners, BulkDeleteTrailers, BulkDeleteDrivers } from "logic";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import { confirmAlert } from "react-confirm-alert";
// import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Tabs, { ThemedTab as Tab } from "../../Components/ThemedTabs";
import { ActionButton } from "Components/ActionButtons";
import { Colors } from "Constants";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};
TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

// component to render inventory
class DataManagement extends Component {
  state = {
    tab: this.props.match.params.tab ? Number(this.props.match.params.tab) : 0,
    showNewOwnerModal: false,
    showNewDriverModal: false,
    showNewTrailerModal: false,
    ownerSelection: [],
    trailerSelection: [],
    driverSelection: []
  };

  // to render the popup to confirm delete selected items 
  confirmDeleteAction = confirmCallback => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationDialog
            title={"Are you sure?"}
            message={"This action cannot be undone"}
            onConfirm={confirmCallback}
            onClose={onClose}
          />
        );
      }
    });
  };

  // handle corresponding popup actions
  openCreateNewOwnerDialog = () => {
    this.setState({ showNewOwnerModal: true });
  };
  closeCreateNewOwnerDialog = () => {
    this.setState({ showNewOwnerModal: false });
  };
  openCreateNewDriverDialog = () => {
    this.setState({ showNewDriverModal: true });
  };
  closeCreateNewDriverDialog = () => {
    this.setState({ showNewDriverModal: false });
  };
  openCreateNewTrailerDialog = () => {
    this.setState({ showNewTrailerModal: true });
  };
  closeCreateNewTrailerDialog = () => {
    this.setState({ showNewTrailerModal: false });
  };

  // to handle tab switching
  handleChange = (event, value) => {
    this.props.history.push(`/dashboard/datamgmt/${value}`)
    this.setState({ tab: value });
  };
  setOwnerSelection = selecton => {
    this.setState({ ownerSelection: selecton });
  };
  setTrailerSelection = selecton => {
    this.setState({ trailerSelection: selecton });
  };
  setDriverSelection = selecton => {
    this.setState({ driverSelection: selecton });
  };

  render() {
    console.log(this.props.match.params.tab);
    const { tab } = this.state;
    return (
      <div style={{ backgroundColor: Colors.body, minHeight: "100vh" }}>
        <Tabs
          value={tab}
          onChange={this.handleChange}
          centered
          style={{ width: "100%", padding: "20px 18px" }}
        >
          <Tab label="Owners" className="MainTab" />
          <Tab label="Trailers" className="MainTab" />
          <Tab label="Drivers" className="MainTab" />
          <Tab label="Users" className="MainTab" />
        </Tabs>
        {/* render inventory data and action buttons according to tab value */}
        {tab === 0 && (
          <TabContainer>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "18px",
                position: "relative",
                top: "54px",
                zIndex: "2"
              }}
            >
              <ActionButton
                iconsrc={require("Assets/Icons/Icons_Plus.svg")}
                rootStyle={{ marginLeft: "8px" }}
                onClick={e => {
                  this.openCreateNewOwnerDialog();
                }}
              >
                Add New Owner
              </ActionButton>
              {this.state.ownerSelection.length > 0 && (
                <ActionButton
                  iconsrc={require("Assets/Icons/Icons_Trash.svg")}
                  rootStyle={{ marginLeft: "8px" }}
                  iconContainerStyle={{ backgroundColor: "#f95138" }}
                  onClick={() => {
                    this.confirmDeleteAction(async () => {
                      let response = await BulkDeleteOwners(
                        this.state.ownerSelection
                      );
                      if (response) window.location.reload();
                    });
                  }}
                >
                  Delete Selected
                </ActionButton>
              )}
            </div>
            <OwnerData setOwnerSelection={this.setOwnerSelection} />
          </TabContainer>
        )}
        {tab === 1 && (
          <TabContainer>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "18px",
                position: "relative",
                top: "54px",
                zIndex: "2"
              }}
            >
              <ActionButton
                iconsrc={require("Assets/Icons/Icons_Plus.svg")}
                rootStyle={{ marginLeft: "8px" }}
                onClick={e => {
                  this.openCreateNewTrailerDialog();
                }}
              >
                Add New Trailer
              </ActionButton>
              {this.state.trailerSelection.length > 0 && (
                <ActionButton
                  iconsrc={require("Assets/Icons/Icons_Trash.svg")}
                  rootStyle={{ marginLeft: "8px" }}
                  iconContainerStyle={{ backgroundColor: "#f95138" }}
                  onClick={() => {
                    this.confirmDeleteAction(async () => {
                      let response = await BulkDeleteTrailers(
                        this.state.trailerSelection
                      );
                      if (response) window.location.reload();
                    });
                  }}
                >
                  Delete Selected
                </ActionButton>
              )}
            </div>
            <TrailerData setTrailerSelection={this.setTrailerSelection} />
          </TabContainer>
        )}
        {tab === 2 && (
          <TabContainer>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "18px",
                position: "relative",
                top: "54px",
                zIndex: "2"
              }}
            >
              <ActionButton
                iconsrc={require("Assets/Icons/Icons_Plus.svg")}
                rootStyle={{ marginLeft: "8px" }}
                onClick={e => {
                  this.openCreateNewDriverDialog();
                }}
              >
                Add New Driver
              </ActionButton>
              {this.state.driverSelection.length > 0 && (
                <ActionButton
                  iconsrc={require("Assets/Icons/Icons_Trash.svg")}
                  rootStyle={{ marginLeft: "8px" }}
                  iconContainerStyle={{ backgroundColor: "#f95138" }}
                  onClick={() => {
                    this.confirmDeleteAction(async () => {
                      let response = await BulkDeleteDrivers(
                        this.state.driverSelection
                      );
                      if (response) window.location.reload();
                    });
                  }}
                >
                  Delete Selected
                </ActionButton>
              )}
            </div>
            <DriverData setDriverSelection={this.setDriverSelection} />
          </TabContainer>
        )}
        {tab === 3 && (
          <TabContainer>
            <UserData />
          </TabContainer>
        )}
        <NewOwnerDialog
          open={this.state.showNewOwnerModal}
          handleClose={this.closeCreateNewOwnerDialog}
        />
        <NewDriverDialog
          open={this.state.showNewDriverModal}
          handleClose={this.closeCreateNewDriverDialog}
        />
        <NewTrailerDialog
          open={this.state.showNewTrailerModal}
          handleClose={this.closeCreateNewTrailerDialog}
        />
      </div>
    );
  }
}
export default DataManagement;
