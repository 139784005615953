import React from "react";
// popup displayed on click confirm allot owner
// props: { cancelButtonText, confirmButtonText, onCancel, onClose}
const AllotOwnerConfirmationDialog = (props) => {
  return (
    <div
      style={{
        height: "220px",
        width: "430px",
        border: "1px solid #353b50",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          height: "50px",
          backgroundColor: "#262c40",
          textAlign: "center",
          // textTransform: "uppercase",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        {"Journey Allotment Confirmation"}
      </div>
      <div style={{ padding: "10px", paddingTop: "36px" }}>
        <p style={{textAlign: "center"}}>
        Are you sure you want to allot this journey to an owner?
        </p>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          right: "0px",
          justifyContent: "flex-end",
        }}
      >
        {" "}
        <div
          onClick={() => {
            props.onCancel && props.onCancel();
            props.onClose && props.onClose();
          }}
          style={{
            height: "45px",
            width: "100%",
            backgroundColor: "#262c40",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          {props.cancelButtonText ? props.cancelButtonText : "No"}
        </div>
        <div
          onClick={() => {
            props.onConfirm && props.onConfirm();
            props.onClose && props.onClose();
          }}
          style={{
            height: "45px",
            width: "100%",
            backgroundColor: "#f95138",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          {props.confirmButtonText ? props.confirmButtonText : "Yes"}
        </div>
      </div>
    </div>
  );
};

export default AllotOwnerConfirmationDialog;
