import React, { Component } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  TableColumnResizing
} from "@devexpress/dx-react-grid";
import GridSearchBox from "Components/GridSearchBox";
import { GetBookingsHistory } from "logic";
import { TableActionButton } from "Components/ActionButtons";
import TableComponent, { PagingContainer } from "Components/TableComponent";
import { removeLastColumn } from "../../Commons";
// booking history listing section, listing is done using @devexpress/dx-react-grid library
class BookingHistory extends Component {
  state = {
    rows: [],
    customColumnWidth: [
      { columnName: "index", width: 90 },
      { columnName: "booking_id", width: "auto" },
      { columnName: "date", width: "auto" },
      { columnName: "startPoint", width: "auto" },
      { columnName: "dropPoint", width: "auto" },
      { columnName: "status", width: "auto" },
      { columnName: "cost", width: 200 },
    ]
  };

  // to enable table row click action
  TableRowClickable = ({ tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        window.location.assign(`/dashboard/booking/${tableRow.row.id}`)
      }}
      className={"clickable"}
    />
  );
  // executes before component render
  // call the function which make api call to get booking history data
  componentDidMount() {
    GetBookingsHistory(this);
    // remove last column of the table which comes along with TableColumnResizing as a bug. 
    removeLastColumn();
  }
  render() {
    return (
      <Grid
        rows={this.state.rows.map((item, index) => {
          return {
            ...item,
            index: index + 1
          };
        })}
        columns={[
          { name: "index", title: "S.No" },
          { name: "booking_id", title: "Booking ID" },
          { name: "date", title: "Booking Timestamp" },
          { name: "startPoint", title: "Start Point" },
          { name: "dropPoint", title: "Drop Point" },
          { name: "status", title: "Delivery Status" },
          // { name: "duration", title: "Transit Duration" },
          { name: "cost", title: "Cost" },
          // { name: "action", title: "Action" }
        ]}
      >
        {/* <SelectionState /> */}
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <IntegratedPaging />
        <Table
          tableComponent={
            this.props.tableComponent
              ? this.props.tableComponent
              : TableComponent
          }
          rowComponent={this.TableRowClickable}
          messages={{ noData: "..." }}
        />
        <TableColumnResizing resizingMode="nextColumn" columnWidths={this.state.customColumnWidth} onColumnWidthsChange={(width) => this.setState({customColumnWidth: width})}/>
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel
          inputComponent={
            this.props.searchComponent
              ? this.props.searchComponent
              : GridSearchBox
          }
        />
        {/* <IntegratedSelection /> */}
        {/* <TableSelection showSelectAll={true} highlightRow={true} /> */}
        <PagingPanel containerComponent={PagingContainer} />
      </Grid>
    );
  }
}

export default BookingHistory;
